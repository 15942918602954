import { BatchRunStage, BatchStatus } from './BatchRunModels';
import { Button, Spinner } from 'react-bootstrap';
import { CloudUpload, Download, Export, Settings } from '@carbon/icons-react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserProfileContext, isGlobalAdmin } from '../../Shared/contexts/UserProfile';
import { endOfDay, startOfDay } from 'date-fns';

import ApiHelper from '../../Shared/ApiHelper';
import BatchStatusCounts from './BatchStatusCounts';
import classNames from 'classnames';
import { formatDateTimeISO } from '../../Shared/SharedHelpers';
import styles from './BatchRunsStages.module.css';
import { useMsal } from '@azure/msal-react';

interface BatchRunsStagesProps {
    selectedStage: number | null;
    onSelectedStageChange(value: number): void;
    startDate: Date;
    endDate: Date | null;
    selectedStatus: BatchStatus | null;
    onSelectedStatusChange(value: BatchStatus | null): void;
}

const BatchRunsStages: React.FC<BatchRunsStagesProps> = ({
    selectedStage,
    onSelectedStageChange,
    startDate,
    endDate,
    selectedStatus,
    onSelectedStatusChange,
}) => {
    const { instance } = useMsal();
    const api = useMemo(() => new ApiHelper(), []);
    const userProfile = useContext(UserProfileContext);

    const [isLoading, setIsLoading] = useState(false);
    const [stages, setStages] = useState<BatchRunStage[]>([]);

    const fetchBatchStages = useCallback(async (): Promise<void> => {
        try {
            setIsLoading(true);

            const payload = JSON.stringify({
                startDate: formatDateTimeISO(startOfDay(startDate)),
                endDate: endDate && formatDateTimeISO(endOfDay(endDate)),
            });
            const response: Response = await api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_NET_API_URL}/batch`,
                'POST',
                payload
            );
            const data = await response.json();
            setStages(isGlobalAdmin(userProfile) ? data : data.slice(0, 2));

            if (data.length > 0) {
                onSelectedStageChange(data[0].stage);
            }
        } finally {
            setIsLoading(false);
        }
    }, [api, endDate, instance, onSelectedStageChange, startDate, userProfile]);

    useEffect(() => {
        fetchBatchStages();
    }, [fetchBatchStages]);

    if (isLoading) {
        return (
            <div className="flex-row">
                <Spinner className={styles.loading} variant="primary" size="sm" animation="border" />
            </div>
        );
    }

    if (!stages.length) {
        return <div className={styles.emptyText}>No Stages Found</div>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.itemsContainer}>
                {stages.map((stage, index) => {
                    const Icon = stageIcons[index] || Settings;

                    const titleBreakpoint =
                        stage.name.indexOf('to') > 0 ? stage.name.indexOf('to') : stage.name.indexOf('from');
                    const titleText =
                        titleBreakpoint > 0 ? stage.name.substring(0, titleBreakpoint) + 'Files' : stage.name;
                    const titleSubtext =
                        titleBreakpoint > 0 ? stage.name.substring(titleBreakpoint, stage.name.length) : undefined;

                    return (
                        <div key={index} className={styles.item}>
                            <Button
                                variant="link"
                                className={classNames(styles.stageContainer, {
                                    [styles.selectedStageContainer]: selectedStage === stage.stage,
                                })}
                                onClick={() => {
                                    onSelectedStageChange(stage.stage);
                                    onSelectedStatusChange(null);
                                }}
                            >
                                <p className={styles.stageTitle}>{titleText}</p>
                                <p className={styles.stageTitleSubtext}>{titleSubtext}</p>

                                <div className={styles.iconContainer}>
                                    <div>
                                        <div className={classNames(styles.iconCard)}>
                                            <Icon size={24} className={styles.itemIcon} />
                                        </div>
                                    </div>
                                </div>

                                <BatchStatusCounts
                                    stage={stage.stage}
                                    selectedStage={selectedStage}
                                    onSelectedStageChange={onSelectedStageChange}
                                    selectedStatus={selectedStatus}
                                    onSelectedStatusChange={onSelectedStatusChange}
                                    processedCount={stage.processed}
                                    processingCount={stage.processing}
                                    errorCount={stage.error}
                                />
                            </Button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BatchRunsStages;

const stageIcons = [CloudUpload, Download, Export, Download];
