import { Breadcrumb, Form } from 'react-bootstrap';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import { ChevronRight } from '@carbon/icons-react';
import { MultiValue } from 'react-select';
import RecipientsSelection from '../../Shared/components/RecipientsSelection';
import SaveButton from '../../Shared/components/SaveButton';
import { SelectOption } from '../../Shared/SharedModels';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const PublicReportReviews: FunctionComponent = () => {
    const navigate = useNavigate();
    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    const [initialReviewers, setInitialReviewers] = useState<string[]>([]);
    const [reviewers, setReviewers] = useState<string[]>([]);

    const [isAddingReviewers, setIsAddingReviewers] = useState(false);
    const [isDeletingReviewers, setIsDeletingReviewers] = useState(false);

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/publicreportreviewers'
        ).then(async (result: Response) => {
            const reviewers: string[] = await result.json();

            setInitialReviewers(reviewers);
            setReviewers(reviewers);
        });
    }, [api, instance]);

    return (
        <div className="content-column-container">
            <div className="content-header">
                <Breadcrumb>
                    <Breadcrumb.Item
                        onClick={() => {
                            navigate('/admin');
                        }}
                    >
                        Configuration
                    </Breadcrumb.Item>
                    <ChevronRight size={24} className="breadcrumb-chevron" />
                    <Breadcrumb.Item active>Public Report Reviewers</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h1>Public Report Reviewers</h1>
            <p className="data-tools-description">
                Configure ClientInsight to send in app alerts to desired users when another user submits a public report
                for review before publishing
            </p>
            <div className="project-card px-3 py-2 my-2">
                <div className="flex-row space-between pt-2 pb-3">
                    <h4>Reviewers</h4>
                    <SaveButton
                        isSaving={isAddingReviewers || isDeletingReviewers}
                        onClick={() => {
                            const addedReviewers = reviewers.filter((reviewer) => !initialReviewers.includes(reviewer));
                            const removedReviewers = initialReviewers.filter(
                                (initialReviewer) => !reviewers.includes(initialReviewer)
                            );

                            if (addedReviewers.length > 0) {
                                setIsAddingReviewers(true);
                                api.callApi(
                                    instance,
                                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                    process.env.REACT_APP_NET_API_URL + '/User/publicreportreviewers',
                                    'POST',
                                    JSON.stringify(addedReviewers)
                                ).then(async (_result: Response) => {
                                    setIsAddingReviewers(false);
                                    setInitialReviewers((prev) => prev.concat(addedReviewers));
                                });
                            }

                            if (removedReviewers.length > 0) {
                                setIsDeletingReviewers(true);
                                api.callApi(
                                    instance,
                                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                    process.env.REACT_APP_NET_API_URL + '/User/publicreportreviewers',
                                    'DELETE',
                                    JSON.stringify(removedReviewers)
                                ).then(async (_result: Response) => {
                                    setIsDeletingReviewers(false);
                                    setInitialReviewers((prev) =>
                                        prev.filter((reviewer) => !removedReviewers.includes(reviewer))
                                    );
                                });
                            }
                        }}
                    />
                </div>
                <Form className="pb-2">
                    <RecipientsSelection
                        value={reviewers.map((upn) => {
                            return { label: upn, value: upn };
                        })}
                        onChange={(selectedOptions: MultiValue<SelectOption<string>>) => {
                            setReviewers(selectedOptions.map((option) => option.value));
                        }}
                    />
                </Form>
            </div>
        </div>
    );
};

export default PublicReportReviews;
