import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
    UserProfileContext,
    isGlobalAdmin,
    isOrgAdminOrHigher,
    isSourceSystemAdminOrHigher,
} from '../Shared/contexts/UserProfile';

import Admin from '../Admin/Admin';
import BatchRuns from '../Monitoring/BatchRuns/BatchRuns';
import Canonical from '../Monitoring/Canonical/Canonical';
import { ClientProfile } from '../ClientService/ClientProfile';
import { ClientSearch } from '../ClientService/ClientSearch';
import Dashboard from '../Dashboard/Dashboard';
import DataQualityAlerts from '../Admin/DataQualityAlerts/DataQualityAlerts';
import DataTools from '../DataTools/DataTools';
import Dedupe from '../DataTools/Dedupe/Dedupe';
import FileUpload from '../DataTools/FileUpload/FileUpload';
import FileUploadSuccess from '../DataTools/FileUpload/FileUploadSuccess';
import NotFound from '../Shared/components/NotFound';
import ProjectHistory from '../DataTools/History/ProjectHistory';
import ProjectList from '../Admin/Projects/ProjectList';
import PublicReportReviews from '../Admin/PublicReportReviews/PublicReportReviews';
import ReportAuthoring from '../Reports/ReportAuthoring';
import ReportCategories from '../Admin/CategoriesPage/ReportCategories';
import ReportList from '../Reports/ReportList';
import SourceSystemAdmin from '../Admin/SourceSystems/SourceSystemAdmin';
import TeamMembers from '../TeamMembers/TeamMembers';
import UniqueIndexCreation from '../DataTools/Dedupe/UniqueIndexCreation';
import ViewReport from '../Reports/ViewReport';
import ViewUsageReport from '../Monitoring/ReportUsageMetrics/ViewUsageReport';

type TitleProps = {
    title: string;
    children: JSX.Element;
};

const TitledComponentContainer: FunctionComponent<TitleProps> = ({ title, children }): JSX.Element => {
    useEffect(() => {
        document.title = title || 'ClientInsight';
    }, [title]);

    return children;
};

export const RoutesApp = (): JSX.Element => {
    const { pathname } = useLocation();
    const userProfile = useContext(UserProfileContext);

    return (
        <Routes>
            <Route
                path=""
                element={
                    <TitledComponentContainer title="Dashboard">
                        <Dashboard />
                    </TitledComponentContainer>
                }
            />

            {isSourceSystemAdminOrHigher(userProfile) && (
                <>
                    <Route
                        path="/datatools"
                        element={
                            <TitledComponentContainer title="Data Tools">
                                <DataTools />
                            </TitledComponentContainer>
                        }
                    />
                    <Route
                        path="/datatools/dedupe"
                        element={
                            <TitledComponentContainer title="Data Tools: Dedupe">
                                <Dedupe />
                            </TitledComponentContainer>
                        }
                    />
                    <Route
                        path="/datatools/uniqueclientindexcreation"
                        element={
                            <TitledComponentContainer title="Data Tools: Create Unique Client Index">
                                <UniqueIndexCreation />
                            </TitledComponentContainer>
                        }
                    />
                    <Route
                        path="/datatools/upload"
                        element={
                            <TitledComponentContainer title="Data Tools: Upload Files">
                                <FileUpload />
                            </TitledComponentContainer>
                        }
                    />
                    <Route
                        path="/datatools/upload/success"
                        element={
                            <TitledComponentContainer title="Data Tools: Upload Files">
                                <FileUploadSuccess />
                            </TitledComponentContainer>
                        }
                    />
                    <Route
                        path="/datatools/history"
                        element={
                            <TitledComponentContainer title="Project History">
                                <ProjectHistory />
                            </TitledComponentContainer>
                        }
                    />
                </>
            )}

            <Route
                path="/reports"
                element={
                    <TitledComponentContainer title="Reports">
                        <ReportList
                            endpoint={process.env.REACT_APP_NET_API_URL + '/PbiReport'}
                            header="Reports"
                            isDefault
                        />
                    </TitledComponentContainer>
                }
            />
            <Route
                path="/reports/:workspaceId/:reportId/:datasetId"
                element={
                    <TitledComponentContainer title="Reports: View Report">
                        <ViewReport />
                    </TitledComponentContainer>
                }
            />
            {isOrgAdminOrHigher(userProfile) && (
                <>
                    <Route
                        path="/reports/add/:datasetId"
                        element={
                            <TitledComponentContainer title="Reports: Author Report">
                                <ReportAuthoring />
                            </TitledComponentContainer>
                        }
                    />
                    <Route
                        path="/reports/mywork"
                        element={
                            <TitledComponentContainer title="Reports: My Work">
                                <ReportList
                                    endpoint={process.env.REACT_APP_NET_API_URL + '/PbiReport/my'}
                                    header="My Work"
                                />
                            </TitledComponentContainer>
                        }
                    />
                </>
            )}
            <Route
                path="/reports/community"
                element={
                    <TitledComponentContainer title="Reports: Community">
                        <ReportList
                            endpoint={process.env.REACT_APP_NET_API_URL + '/PbiReport/published'}
                            header="Community Reports"
                        />
                    </TitledComponentContainer>
                }
            />
            <Route
                path="/reports/public"
                element={
                    <TitledComponentContainer title="Reports: Public">
                        <ReportList
                            endpoint={process.env.REACT_APP_NET_API_URL + '/PbiReport/public'}
                            header="Public Reports"
                            isPublic
                        />
                    </TitledComponentContainer>
                }
            />
            {isGlobalAdmin(userProfile) && (
                <Route
                    path="/reports/public/add/:datasetId"
                    element={
                        <TitledComponentContainer title="Reports: Author Public Report">
                            <ReportAuthoring isPublic />
                        </TitledComponentContainer>
                    }
                />
            )}

            {isOrgAdminOrHigher(userProfile) && (
                <Route
                    path="/teammembers"
                    element={
                        <TitledComponentContainer title="Team Members">
                            <TeamMembers key={pathname} />
                        </TitledComponentContainer>
                    }
                />
            )}
            {userProfile.isEccoviaUser && (
                <Route
                    path="/teammembers/eccovia-users"
                    element={
                        <TitledComponentContainer title="Team Members: eccovia">
                            <TeamMembers key={pathname} isEccovia />
                        </TitledComponentContainer>
                    }
                />
            )}

            {isSourceSystemAdminOrHigher(userProfile) && (
                <>
                    <Route
                        path="/admin"
                        element={
                            <TitledComponentContainer title="Admin">
                                <Admin />
                            </TitledComponentContainer>
                        }
                    />

                    <Route
                        path="/admin/reportcategories"
                        element={
                            <TitledComponentContainer title="Admin: Report Categories">
                                <ReportCategories />
                            </TitledComponentContainer>
                        }
                    />
                    <Route
                        path="/admin/dataqualityalerts"
                        element={
                            <TitledComponentContainer title="Admin: Data Quality Alerts">
                                <DataQualityAlerts />
                            </TitledComponentContainer>
                        }
                    />
                    <Route
                        path="/admin/sourcesystems"
                        element={
                            <TitledComponentContainer title="Admin: Source Systems">
                                <SourceSystemAdmin />
                            </TitledComponentContainer>
                        }
                    />
                    {isGlobalAdmin(userProfile) && (
                        <Route
                            path="/admin/projects"
                            element={
                                <TitledComponentContainer title="Admin: Projects">
                                    <ProjectList />
                                </TitledComponentContainer>
                            }
                        />
                    )}
                    {isGlobalAdmin(userProfile) && (
                        <Route
                            path="/admin/publicreportreviews"
                            element={
                                <TitledComponentContainer title="Admin: Pulic Report Reviewers">
                                    <PublicReportReviews />
                                </TitledComponentContainer>
                            }
                        />
                    )}
                </>
            )}

            {isSourceSystemAdminOrHigher(userProfile) && (
                <Route
                    path="/monitoring/batch-runs"
                    element={
                        <TitledComponentContainer title="Monitoring: Batch Runs">
                            <BatchRuns />
                        </TitledComponentContainer>
                    }
                />
            )}
            {isGlobalAdmin(userProfile) && (
                <>
                    <Route
                        path="/monitoring/report-usage-metrics"
                        element={
                            <TitledComponentContainer title="Monitoring: Report Usage Metrics">
                                <ViewUsageReport />
                            </TitledComponentContainer>
                        }
                    />
                    <Route
                        path="/monitoring/canonicalid"
                        element={
                            <TitledComponentContainer title="Canonical ID Runs and Monitoring">
                                <Canonical />
                            </TitledComponentContainer>
                        }
                    />
                </>
            )}

            {isSourceSystemAdminOrHigher(userProfile) && process.env.REACT_APP_CLIENT360_ENABLED === 'true' && (
                <>
                    <Route
                        path="/clients"
                        element={
                            <TitledComponentContainer title="Client 360: Search Client">
                                <ClientSearch />
                            </TitledComponentContainer>
                        }
                    />
                    <Route
                        path="/clients/:id/profile"
                        element={
                            <TitledComponentContainer title="Client 360: Client Profile">
                                <ClientProfile />
                            </TitledComponentContainer>
                        }
                    />
                </>
            )}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
