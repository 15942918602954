import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import ApiHelper from '../../../../Shared/ApiHelper';
import { ArrowRight } from '@carbon/icons-react';
import ProjectProgressBar from '../../../Shared/ProjectProgressBar';
import { TrainingData } from '../Training/TrainingDataTypes';
import useInterval from 'react-useinterval';
import { useMsal } from '@azure/msal-react';

interface ScanningDataProps {
    setDisableContinue: (disableContinue: boolean) => void;
    setTrainingData?: (trainingData: TrainingData) => void;
    isRefining?: boolean;
    usePreviousTraining?: boolean;
}

const ScanningData: FunctionComponent<ScanningDataProps> = (props: ScanningDataProps) => {
    const { setDisableContinue, setTrainingData, isRefining, usePreviousTraining } = props;

    const [progress, setProgress] = useState(0);
    const [isWaitingForStatus, setIsWaitingForStatus] = useState(false);

    const { instance } = useMsal();
    const api = useMemo(() => new ApiHelper(), []);

    const incrementProgress = useCallback(() => {
        if (progress < 100) {
            if (progress < 99) {
                setProgress(progress + 1);
            }

            if (!isWaitingForStatus) {
                setIsWaitingForStatus(true);

                const endpoint = usePreviousTraining ? '/dedupestatic' : '/dedupenew';

                const method = usePreviousTraining ? 'GET' : 'POST';
                const payload = usePreviousTraining ? undefined : JSON.stringify(isRefining ? { user_input: 'f' } : {});

                api.callApi(
                    instance,
                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                    process.env.REACT_APP_PYTHON_API_URL + endpoint,
                    method,
                    payload
                ).then(async (result: Response) => {
                    const dedupeProject = await result.json();

                    if (dedupeProject.record_pair && setTrainingData) {
                        setProgress(100);
                        setTrainingData(dedupeProject);
                    } else if (!dedupeProject.status) {
                        setProgress(100);
                    }
                    setIsWaitingForStatus(false);
                });
            }
        }
    }, [api, instance, isRefining, isWaitingForStatus, progress, setTrainingData, usePreviousTraining]);
    useInterval(incrementProgress, 5000);

    useEffect(() => {
        if (progress === 100) {
            setDisableContinue(false);
        }
    }, [progress, setDisableContinue]);

    return (
        <ProjectProgressBar
            progress={progress}
            header={'Scanning Data for Duplicates'}
            progressDescription={
                isRefining
                    ? 'We are now scanning your data again, refining our matches using the training you provided. This process takes a few minutes. Please wait here until your data is ready.'
                    : 'It takes a few minutes to scan your data and find matches. Please wait here until your data is ready.'
            }
            finishedDescription={'Click continue to move to the next step.'}
        >
            <img height={64} src="DataLookup.svg" alt="Data Lookup" />
            <ArrowRight size={32} className="data-lookup-arrow" />
            <img height={64} src="DataMatch.svg" alt="Data Match" />
        </ProjectProgressBar>
    );
};

export default ScanningData;
