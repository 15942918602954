import { IPublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { Slide, toast } from 'react-toastify';

class ApiHelper {
    async #callApiWithToken(token: string, endpoint: string, method: string, payload?: string, suppressToast = false): Promise<Response> {
        const headers = new Headers();
        const bearer = 'Bearer ' + token;
        headers.append('Authorization', bearer);
        if (payload) {
            headers.append('Content-Type', 'application/json');
        }
        headers.append('Accept', 'application/json;application/problem+json;text/html');
        const options = {
            method: method,
            headers: headers,
            credentials: 'include' as RequestCredentials,
            ...(payload && { body: payload }),
        };

        let hasToasted = false;
        try {
            const response = await fetch(endpoint, options);
            if (!response.ok && response.json) {
                hasToasted = true;
                if (!suppressToast) {
                    response.json().then((data) => {
                        if (data.type === 'api-user-error' || data.type === 'api-system-error') {
                            toast.error(data.title, {
                                position: 'top-right',
                                autoClose: false,
                                transition: Slide,
                                draggable: false,
                                closeOnClick: false,
                                theme: 'colored',
                            });
                        } else if (data.type === 'api-warning') {
                            toast.warning(data.title, {
                                position: 'top-right',
                                autoClose: false,
                                transition: Slide,
                                draggable: false,
                                closeOnClick: false,
                                theme: 'colored',
                            });
                        }
                    });
                }

                throw response;
            } else if (!response.ok) {
                throw response;
            }

            return response;
        } catch (error) {
            if (!hasToasted) {
                if (error instanceof TypeError) {
                    toast.error(error.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        transition: Slide,
                        draggable: false,
                        closeOnClick: false,
                        theme: 'colored',
                    });
                }
            }
            throw error;
        }
    }

    async callApi(
        msalApp: IPublicClientApplication,
        scopes: string[],
        endpoint: string,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
        payload?: string,
        suppressToast = false
    ): Promise<Response> {
        const account = msalApp.getAllAccounts()[0];
        const accessTokenRequest = {
            scopes: scopes,
            account: account,
        };

        try {
            const silentAuthenticationResponse = await msalApp.acquireTokenSilent(accessTokenRequest);
            return this.#callApiWithToken(silentAuthenticationResponse.accessToken, endpoint, method, payload, suppressToast);
        } catch (silentAuthenticationError) {
            if (silentAuthenticationError instanceof InteractionRequiredAuthError) {
                try {
                    const interactiveAuthenticationResponse = await msalApp.acquireTokenPopup(accessTokenRequest);
                    return this.#callApiWithToken(interactiveAuthenticationResponse.accessToken, endpoint, method, payload, suppressToast);
                } catch (interactiveAuthenticationError) {
                    console.log(interactiveAuthenticationError);
                    throw interactiveAuthenticationError;
                }
            }

            console.log(silentAuthenticationError);
            throw silentAuthenticationError;
        }
    }
}

export default ApiHelper;
