import capitalize from 'lodash/capitalize';

const colors: { [key: string]: string[] } = {
    blue: ['#3166C4', '#538AF5', '#86AFFF', '#AECAFF', '#C7DAFF'],
    green: ['#56822B', '#76AA34', '#96CA53', '#B2E374', '#D3F9A3'],
    orange: ['#C25E25', '#EC8456', '#F5A07A', '#FFB696', '#FED0BC'],
    purple: ['#7550C3', '#9371DB', '#AD93E3', '#C6B0F3', '#DCCBFF'],
};

export const colorOptions = Object.keys(colors)
    .map((key) => colors[key].map((color, index) => ({ label: `${capitalize(key)} ${index + 1}`, value: color })))
    .reduce((total, colorOption) => [...total, ...colorOption], []);
