import { EhrEncounter, EhrHeader } from '../Models/EhrModels';
import { HealthCross, Medication, WatsonHealthNominate } from '@carbon/icons-react';
import React, { useCallback, useEffect, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import { ICONS } from '../../Shared/Client360Icons';
import { Spinner } from 'react-bootstrap';
import { dollarUS } from '../../Shared/SharedHelpers';
import { format } from 'date-fns';
import startCase from 'lodash/startCase';
import styles from './ServiceDetails.module.css';
import { useMsal } from '@azure/msal-react';

interface EhrDataProps {
    EhrData: EhrEncounter;
    isExpanded: boolean;
}

const NON_DISPLAY_HEADERS: (keyof EhrEncounter)[] = [
    'id',
    'patientKey',
    'encounterKey',
    'canonicalId',
    'conditions',
    'procedures',
    'medications',
    'isSorted',
];

export const EhrDetailsExpansion = ({ EhrData, isExpanded }: EhrDataProps): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();
    const [encounterData, setEncounterData] = useState<EhrEncounter | undefined>(undefined);

    const headers = useCallback((): EhrHeader[] => {
        const keys = Object.keys(EhrData).reduce((acc, headerName) => {
            if (!NON_DISPLAY_HEADERS.includes(headerName as keyof EhrEncounter)) {
                acc.push(headerName as EhrHeader);
            }
            return acc;
        }, [] as EhrHeader[]);
        return keys;
    }, [EhrData]);

    const getEncounterData = useCallback(
        async (encounterKey: number, expanded: boolean): Promise<void> => {
            const url = `${process.env.REACT_APP_NET_API_URL}/Ehr/Encounter/${encounterKey}`;
            if (expanded && encounterData === undefined) {
                setLoading(true);
                api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], url).then(async (result: Response) => {
                    const castEncounter: EhrEncounter = await result.json();
                    setEncounterData(castEncounter);
                    setLoading(false);
                });
            }
        },
        [api, instance, encounterData]
    );

    useEffect(() => {
        getEncounterData(EhrData.encounterKey, isExpanded);
    }, [getEncounterData, EhrData.encounterKey, isExpanded]);

    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center p-5">
                    <Spinner
                        size="sm"
                        className="button-icon-padding button-spinner"
                        variant="primary"
                        animation="border"
                    />
                </div>
            ) : (
                <div className={styles.container}>
                    <ul className={styles.list}>
                        <li className={styles.line}>
                            {encounterData &&
                                headers().map((header) => {
                                    const type = typeof encounterData[header];
                                    const date = !isNaN(Date.parse(encounterData[header] as string))
                                        ? format(new Date(encounterData[header] as string), 'MMMM dd, yyyy')
                                        : null;
                                    const Icon = ICONS[header] || WatsonHealthNominate;

                                    return (
                                        <div key={header} className={styles.listColumn}>
                                            <div className={styles.cellTitle}>
                                                <Icon size={24} />
                                                <span>{startCase(header)}</span>
                                            </div>
                                            <span className={styles.cellValue}>
                                                <>
                                                    {type === 'number'
                                                        ? dollarUS.format(Number(encounterData[header]))
                                                        : date || encounterData[header]}
                                                </>
                                            </span>
                                        </div>
                                    );
                                })}
                        </li>
                    </ul>
                    <ul className={styles.list}>
                        <li className={styles.lineServiceHeader}>
                            <header className={styles.titleCard}>
                                <WatsonHealthNominate size={24} />
                                <span>Procedure</span>
                            </header>
                        </li>

                        {encounterData?.procedures?.map((procedure, index) => {
                            return (
                                <li key={`procedure-${index}`} className={styles.line}>
                                    <div className={styles.cellOrder}>
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className={styles.cellSection}>
                                        <span>Description</span>
                                        <span>{procedure.description}</span>
                                    </div>
                                    <div className={styles.cellSection}>
                                        <span>Base Cost</span>
                                        <span>{procedure.baseCost}</span>
                                    </div>
                                    <div className={styles.cellSection}>
                                        <span>Reason</span>
                                        <span>{procedure.reason}</span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>

                    <ul className={styles.list}>
                        <li className={styles.lineServiceHeader}>
                            <header className={styles.titleCard}>
                                <Medication size={24} />
                                <span>Medication</span>
                            </header>
                        </li>

                        {encounterData?.medications?.map((medication, index) => {
                            return (
                                <li key={`procedure-${index}`} className={styles.line}>
                                    <div className={styles.cellOrder}>
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className={styles.cellSection}>
                                        <span>Description</span>
                                        <span>{medication.description}</span>
                                    </div>
                                    <div className={styles.cellSection}>
                                        <span>Base Cost</span>
                                        <span>{medication.baseCost}</span>
                                    </div>
                                    <div className={styles.cellSection}>
                                        <span>Payor Coverage</span>
                                        <span>{medication.payorCoverage}</span>
                                    </div>
                                    <div className={styles.cellSection}>
                                        <span>Payor Dispenses</span>
                                        <span>{medication.dispenses}</span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>

                    <ul className={styles.list}>
                        <li className={styles.lineServiceHeader}>
                            <header className={styles.titleCard}>
                                <HealthCross size={24} />
                                <span>Condition</span>
                            </header>
                        </li>

                        {encounterData?.conditions?.map((condition, index) => {
                            return (
                                <li key={`procedure-${index}`} className={styles.line}>
                                    <div className={styles.cellOrder}>
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className={styles.cellSection}>
                                        <span>Description</span>
                                        <span>{condition.description}</span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </>
    );
};
