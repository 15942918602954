/* eslint-disable react-hooks/rules-of-hooks */

import { CellContext, ColumnDef } from '@tanstack/react-table';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { formatDate, formatDateString } from '../Shared/SharedHelpers';
import { useCardsContext, withCardProvider } from '../Shared/contexts/CardsProvider';
import { useNavigate, useParams } from 'react-router-dom';

import ApiHelper from '../Shared/ApiHelper';
import { Breadcrumb } from 'react-bootstrap';
import { ChevronRight } from '@carbon/icons-react';
import ClientInfo from './ClientProfile/ClientInfo';
import DataTable from '../Shared/components/DataTable';
import { EhrDetailsExpansion } from './ServiceDetails/EhrDetailsExpansion';
import { EhrEncounter } from './Models/EhrModels';
import ExpandedChevronIcon from '../Shared/components/ExpandedChevronIcon';
import { HmisSummary } from './Models/HmisModels';
import HmisSummaryExpansion from './ServiceDetails/HmisSummaryExpansion';
import ServiceSummaryCard from './ClientProfile/ServiceSummaryCard';
import { UserProfileContext } from '../Shared/contexts/UserProfile';
import { formatServiceToCardInfo } from './ClientProfile/utils';
import startCase from 'lodash/startCase';
import styles from './client.module.css';
import { useMsal } from '@azure/msal-react';

export const ClientProfile = withCardProvider((): JSX.Element => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const [ehrData, setEhrData] = useState<EhrEncounter[]>([]);
    const [hmisData, setHmisData] = useState<HmisSummary[]>([]);
    const [mostRecentHmisData, setMostRecentHmisData] = useState<HmisSummary>();
    const [mostRecentEncounterData, setMostRecentEncounterData] = useState<EhrEncounter>();
    const [hmisLoading, setHmisLoading] = useState(false);
    const [ehrLoading, setEhrLoading] = useState(false);
    const [activeServiceData, setActiveServiceData] = useState<'ehr' | 'hmis'>('hmis');
    const [hmisCardLoading, setHmisCardLoading] = useState<boolean>(true);
    const [ehrCardLoading, setEhrCardLoading] = useState<boolean>(true);
    const { serviceCards } = useCardsContext();

    const getClientsData = useCallback(
        async (service: string): Promise<void> => {
            const serviceEndpoint = service === 'ehr' ? 'Ehr/Summary' : 'HmisSummary/Summary';
            const url = `${process.env.REACT_APP_NET_API_URL}/${serviceEndpoint}/${id}`;
            api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], url).then(async (result: Response) => {
                if (service === 'ehr') {
                    const castEhr: EhrEncounter[] = await result.json();
                    setEhrData(castEhr);
                    setEhrLoading(false);
                } else if (service === 'hmis') {
                    const castHmis: HmisSummary[] = await result.json();
                    setHmisData(castHmis);
                    setHmisLoading(false);
                }
            });
        },
        [api, id, instance]
    );

    useEffect(() => {
        const getMostRecentData = async (): Promise<void> => {
            const hmisUrl = `${process.env.REACT_APP_NET_API_URL}/HmisSummary/MostRecentEnrollment/${id}`;
            const ehrUrl = `${process.env.REACT_APP_NET_API_URL}/Ehr/MostRecentEncounter/${id}`;

            api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], hmisUrl).then(async (result: Response) => {
                const castHmis: HmisSummary = await result.json();
                setMostRecentHmisData(castHmis);
                setHmisCardLoading(false);
            });

            api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], ehrUrl).then(async (result: Response) => {
                const castEhr: EhrEncounter = await result.json();
                setMostRecentEncounterData(castEhr);
                setEhrCardLoading(false);
            });
        };

        getMostRecentData();
    }, [api, id, instance]);

    const handleClickNavigation = (
        event: React.MouseEvent<HTMLButtonElement> & { target: HTMLButtonElement }
    ): void => {
        setActiveServiceData(event.target?.innerText?.toLowerCase() as 'ehr' | 'hmis');
    };

    useEffect(() => {
        setEhrLoading(true);
        setHmisLoading(true);

        getClientsData('hmis');
        getClientsData('ehr');
    }, [getClientsData]);

    const ehrColumns: ColumnDef<EhrEncounter>[] = useMemo(
        () => [
            {
                header: () => null,
                id: 'expander',
                enableSorting: false,
                cell: ({ row }: CellContext<EhrEncounter, unknown>) => (
                    <ExpandedChevronIcon isOpen={row.getIsExpanded()} onClick={() => row.toggleExpanded()} />
                ),
            },
            {
                header: 'Start',
                accessorKey: 'encounterStart',
                cell: ({ row }: CellContext<EhrEncounter, unknown>) => {
                    const userProfile = useContext(UserProfileContext);
                    return formatDateString(row.original.encounterStart, userProfile.environmentConfig.timeZone);
                },
            },
            {
                header: 'End',
                accessorKey: 'encounterEnd',
                cell: ({ row }: CellContext<EhrEncounter, unknown>) => {
                    const userProfile = useContext(UserProfileContext);
                    return formatDateString(row.original.encounterEnd, userProfile.environmentConfig.timeZone);
                },
            },
            { header: 'Organization Name', accessorKey: 'organizationName' },
            { header: 'Payor', accessorKey: 'payor' },
            {
                header: 'Cost',
                accessorKey: 'baseEncounterCost',
                cell: ({ row }: CellContext<EhrEncounter, unknown>) => <span>{row.original.baseEncounterCost}</span>,
            },
            {
                header: 'Encounter Class',
                accessorKey: 'encounterClass',
            },
            { header: 'Reason', accessorKey: 'reason' },
            {
                header: 'Conditions',
                accessorKey: 'conditions',
                cell: ({ row }: CellContext<EhrEncounter, unknown>) => (
                    <div>
                        {row.original.conditions?.map((item) => (
                            <span key={`${item}`}>{startCase(item.description)}</span>
                        ))}
                    </div>
                ),
            },
        ],
        []
    );

    return (
        <div className={styles.container}>
            <Breadcrumb className="mb-3">
                <Breadcrumb.Item onClick={() => navigate('/clients')}>Client 360</Breadcrumb.Item>
                <ChevronRight size={24} className="breadcrumb-chevron" />
                <Breadcrumb.Item active>Client Name</Breadcrumb.Item>
            </Breadcrumb>

            <div className={styles.infoContainer}>
                <ClientInfo />
                <div className={styles.infoCardsContainer}>
                    {serviceCards.map((serviceCard, index) => (
                        <ServiceSummaryCard
                            key={index}
                            cardIndex={index}
                            {...formatServiceToCardInfo(
                                serviceCard,
                                serviceCard.service === 'hmis' ? mostRecentHmisData : mostRecentEncounterData
                            )}
                            isLoading={serviceCard.service === 'hmis' ? hmisCardLoading : ehrCardLoading}
                        />
                    ))}
                </div>
            </div>

            <nav className={styles.serviceNavigation}>
                <button
                    className={`${activeServiceData === 'hmis' ? styles.active : ''}`}
                    onClick={handleClickNavigation}
                >
                    HMIS
                </button>

                <button
                    className={`${activeServiceData === 'ehr' ? styles.active : ''}`}
                    onClick={handleClickNavigation}
                >
                    EHR
                </button>
            </nav>
            {activeServiceData === 'ehr' ? (
                <DataTable
                    refreshData={() => {
                        getClientsData('ehr');
                    }}
                    data={ehrData}
                    hasTopRadius={false}
                    recordsAreClickable={false}
                    columns={ehrColumns}
                    loading={ehrLoading}
                    expandedContent={(data, isExpanded) => (
                        <EhrDetailsExpansion EhrData={data} isExpanded={isExpanded} />
                    )}
                />
            ) : (
                <DataTable
                    refreshData={() => {
                        getClientsData('hmis');
                    }}
                    data={hmisData}
                    hasTopRadius={false}
                    recordsAreClickable={false}
                    columns={[
                        {
                            header: () => null,

                            id: 'expander',
                            enableSorting: false,
                            cell: ({ row }: CellContext<HmisSummary, unknown>) => (
                                <ExpandedChevronIcon
                                    isOpen={row.getIsExpanded()}
                                    onClick={() => row.toggleExpanded()}
                                />
                            ),
                        },
                        {
                            header: 'Start',
                            accessorKey: 'entryDate',
                            cell: ({ row }: CellContext<HmisSummary, unknown>) =>
                                row.original.entryDate ? formatDate(row.original.entryDate) : '',
                        },
                        {
                            header: 'End',
                            accessorKey: 'exitDate',
                            cell: ({ row }: CellContext<HmisSummary, unknown>) =>
                                row.original.exitDate ? formatDate(row.original.exitDate) : '',
                        },
                        { header: 'Age at Enrollment', accessorKey: 'ageAtEnrollment', enableSorting: false },
                        { header: 'Project Name', accessorKey: 'projectName' },
                        { header: 'Project Type', accessorKey: 'projectType' },
                        { header: 'Organization Name', accessorKey: 'organizationName' },
                        { header: 'Referral Source', accessorKey: 'referralSource' },
                        {
                            header: 'Services',
                            accessorKey: 'services',
                            cell: ({ row }: CellContext<HmisSummary, unknown>) => (
                                <div>
                                    {row.original.services.map((item, index) => (
                                        <span key={`${item.recordType}-${index}`}>{startCase(item.typeProvided)}</span>
                                    ))}
                                </div>
                            ),
                        },
                    ]}
                    loading={hmisLoading}
                    expandedContent={(data, isExpanded) => (
                        <HmisSummaryExpansion hmisData={data} isExpanded={isExpanded} />
                    )}
                />
            )}
        </div>
    );
});
