import React from 'react';

const NotFound: React.FC = () => (
    <div
        style={{
            alignItems: 'center',
            color: '#495057',
            display: 'flex',
            fontSize: '18px',
            fontWeight: 'bold',
            justifyContent: 'center',
            lineHeight: '24px',
            width: '100%',
        }}
    >
        Page Not Found
    </div>
);

export default NotFound;
