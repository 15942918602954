import React, { FunctionComponent } from 'react';

import { ProgressBar } from 'react-bootstrap';

interface ProjectProgressBarProps {
    children?: React.ReactNode;
    progress: number;
    header: string;
    progressDescription: string;
    finishedDescription: string;
}

const ProjectProgressBar: FunctionComponent<ProjectProgressBarProps> = (props) => {
    const { progress, children, header, progressDescription, finishedDescription } = props;

    return (
        <>
            <div className="data-lookup-icons">
                {progress < 100 && <>{children}</>}
                {progress === 100 && <img height={64} src="Success.svg" alt="Success" />}
            </div>

            <div className="data-lookup-body">
                <h4 className="data-project-text">{header}</h4>
                <ProgressBar striped={progress != 100} animated={progress != 100} className="mb-2" now={progress} />
                {progress < 100 && <p className="data-project-text">{progressDescription}</p>}
                {progress === 100 && <p className="data-project-text">{finishedDescription}</p>}
            </div>
        </>
    );
};

export default ProjectProgressBar;
