import 'react-step-progress-bar/styles.css';

import { Breadcrumb, Button, Spinner } from 'react-bootstrap';
import { ChevronRight, Download } from '@carbon/icons-react';
import { DedupeProject, DedupeProjectSteps, ProjectPhase } from '../DataToolsModels';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Slide, toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ApiHelper from '../../Shared/ApiHelper';
import ClusterReview from './ProjectSteps/ClusterReview/ClusterReview';
import { DedupeSourceSystem } from '../../Shared/SharedModels';
import ProgressStepper from './ProjectSteps/ProgressStepper';
import ProjectContentFooter from './ProjectSteps/ProjectContentFooter';
import ProjectContentHeader from './ProjectSteps/ProjectContentHeader';
import ProjectParametersForm from './ProjectSteps/ProjectParameters/ProjectParametersForm';
import ScanningData from './ProjectSteps/ScanningData/ScanningData';
import Training from './ProjectSteps/Training/Training';
import { TrainingData } from './ProjectSteps/Training/TrainingDataTypes';
import { useMsal } from '@azure/msal-react';

const Dedupe: FunctionComponent = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const api = useMemo(() => new ApiHelper(), []);

    const [projectName, setProjectName] = useState(searchParams.get('projectName') ?? 'New Project');
    const [isNewProject] = useState(searchParams.get('newProject') === 'true');
    const [projectId, setProjectId] = useState(
        searchParams.get('projectId') ? parseInt(searchParams.get('projectId') as string) : undefined
    );
    const [projectSourceSystem, setProjectSourceSystem] = useState<DedupeSourceSystem | undefined>(undefined);
    const [sourceSystemKey, setSourceSystemKey] = useState<number>();
    const [usePreviousTraining, setUsePreviousTraining] = useState<boolean>(false);
    const [autoApprovalThreshold, setAutoApprovalThreshold] = useState<number>();
    const [activeStep, setActiveStep] = useState(searchParams.get('newProject') === 'true' ? 1 : 0);
    const [disableContinue, setDisableContinue] = useState(true);
    const [continueCallInProgress, setContinueCallInProgress] = useState(false);
    const [initialTrainingData, setInitialTrainingData] = useState<TrainingData>({
        n_match: 0,
        n_distinct: 0,
        record_pair: [],
        show_previous: false,
    });
    const dedupeSteps = useMemo<string[]>(() => {
        return ['Select Dataset', 'Find Duplicates', 'Train System', 'Refining Matches', 'Review Results'];
    }, []);

    const continueCallback = useCallback(() => {
        setContinueCallInProgress(true);
        switch (activeStep) {
            case DedupeProjectSteps.SELECT_DATASET:
                // eslint-disable-next-line no-case-declarations
                const payload = JSON.stringify({
                    name: projectName,
                    source_system_key: projectSourceSystem?.key,
                    description: 'Dedupe Project ' + projectName,
                    full_dedupe: !usePreviousTraining,
                    approval_threshold: autoApprovalThreshold,
                });
                api.callApi(
                    instance,
                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                    process.env.REACT_APP_PYTHON_API_URL + '/projects',
                    'POST',
                    payload
                )
                    .then(async (result: Response) => {
                        const newProjectId = await result.json();

                        setProjectId(newProjectId);
                        api.callApi(
                            instance,
                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                            process.env.REACT_APP_PYTHON_API_URL + `/prep/${newProjectId}`
                        )
                            .then((_prepResponse) => {
                                setActiveStep(
                                    usePreviousTraining
                                        ? DedupeProjectSteps.CLUSTERING
                                        : DedupeProjectSteps.PREP_TRAINING
                                );
                                setDisableContinue(true);
                            })
                            .finally(() => {
                                setContinueCallInProgress(false);
                            });
                    })
                    .catch(() => {
                        setContinueCallInProgress(false);
                    });
                break;
            case DedupeProjectSteps.TRAINING:
                api.callApi(
                    instance,
                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                    process.env.REACT_APP_PYTHON_API_URL + '/dedupenew',
                    'POST',
                    JSON.stringify({ user_input: 'f' })
                )
                    .then(async (_result: Response) => {
                        setActiveStep(DedupeProjectSteps.CLUSTERING);
                        setDisableContinue(true);
                    })
                    .finally(() => {
                        setContinueCallInProgress(false);
                    });
                break;
            default:
                setActiveStep(activeStep + 1);
                setDisableContinue(true);
                setContinueCallInProgress(false);
                break;
        }
    }, [activeStep, api, autoApprovalThreshold, instance, projectName, projectSourceSystem?.key, usePreviousTraining]);

    useEffect(() => {
        if (projectSourceSystem) {
            setSourceSystemKey(projectSourceSystem.key);
        }
    }, [projectSourceSystem]);

    useEffect(() => {
        if (!isNewProject) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_PYTHON_API_URL + `/projects/${projectId}`
            ).then(async (response) => {
                const projectDetails: DedupeProject = await response.json();
                const phaseOne = projectDetails.project_phase?.find((phase: ProjectPhase) => {
                    return phase.project_phase === 1;
                });

                setProjectName(projectDetails.name);
                setSourceSystemKey(projectDetails.source_system_key);
                setUsePreviousTraining(!projectDetails.full_dedupe);

                if (projectDetails.record_pair) {
                    setInitialTrainingData((prev) => {
                        return { ...prev, record_pair: projectDetails.record_pair };
                    });
                }

                if (projectDetails.project_ui_stage) {
                    setActiveStep(projectDetails.project_ui_stage);
                } else if (phaseOne?.end_time) {
                    setActiveStep(DedupeProjectSteps.REVIEW);
                } else {
                    toast.error(
                        'This project has expired and cannot be resumed, please restart from the beginning if necessary',
                        {
                            position: 'top-right',
                            autoClose: false,
                            transition: Slide,
                            draggable: false,
                            closeOnClick: false,
                            theme: 'colored',
                        }
                    );
                    navigate('/datatools');
                }
            });
        }
    }, [api, instance, isNewProject, navigate, projectId]);

    return (
        <div className="content-column-container">
            <div className="flex-row">
                <Breadcrumb className="breadcrumb-z-index">
                    <Breadcrumb.Item
                        onClick={() => {
                            navigate('/datatools');
                        }}
                    >
                        Projects
                    </Breadcrumb.Item>
                    <ChevronRight size={24} className="breadcrumb-chevron" />
                    <Breadcrumb.Item active>{projectName}</Breadcrumb.Item>
                </Breadcrumb>
                {activeStep === DedupeProjectSteps.REVIEW && projectId && (
                    <Button
                        className="file-download-button"
                        variant="outline-primary"
                        href={process.env.REACT_APP_PYTHON_API_URL + `/download/${projectId}`}
                    >
                        <Download size={24} className="button-icon-padding" />
                        Download Results
                    </Button>
                )}
            </div>
            <ProgressStepper activeStep={activeStep} steps={dedupeSteps} />
            {activeStep !== DedupeProjectSteps.REVIEW && (
                <div className="project-card">
                    {activeStep !== DedupeProjectSteps.UNKNOWN && (
                        <ProjectContentHeader activeStep={activeStep} header={dedupeSteps[activeStep - 1]} />
                    )}
                    <div className="project-card-body">
                        {activeStep === DedupeProjectSteps.UNKNOWN && (
                            <div className="flex-column">
                                <Spinner className="centered-spinner" variant="primary" size="sm" animation="border" />
                            </div>
                        )}
                        {activeStep === DedupeProjectSteps.SELECT_DATASET && (
                            <ProjectParametersForm
                                projectName={projectName}
                                setProjectName={setProjectName}
                                projectSourceSystem={projectSourceSystem}
                                setProjectSourceSystem={setProjectSourceSystem}
                                setDisableContinue={setDisableContinue}
                                isDedupeProject
                                usePreviousTraining={usePreviousTraining}
                                setUsePreviousTraining={setUsePreviousTraining}
                                autoApprovalThreshold={autoApprovalThreshold}
                                setAutoApprovalThreshold={setAutoApprovalThreshold}
                            />
                        )}
                        {activeStep === DedupeProjectSteps.PREP_TRAINING && (
                            <ScanningData
                                setDisableContinue={setDisableContinue}
                                setTrainingData={setInitialTrainingData}
                            />
                        )}
                        {activeStep === DedupeProjectSteps.TRAINING && (
                            <Training
                                setDisableContinue={setDisableContinue}
                                initialTrainingData={initialTrainingData}
                            />
                        )}
                        {activeStep === DedupeProjectSteps.CLUSTERING && (
                            <ScanningData
                                setDisableContinue={setDisableContinue}
                                isRefining
                                usePreviousTraining={usePreviousTraining}
                            />
                        )}
                    </div>
                    {activeStep !== DedupeProjectSteps.UNKNOWN && (
                        <ProjectContentFooter
                            disableContinue={disableContinue || continueCallInProgress}
                            continueCallback={continueCallback}
                        />
                    )}
                </div>
            )}
            {activeStep === DedupeProjectSteps.REVIEW && projectId && (
                <ClusterReview projectId={projectId} sourceSystemKey={sourceSystemKey} />
            )}
        </div>
    );
};

export default Dedupe;
