import { DedupeProject, ProjectHistoryStatus } from '../DataToolsModels';
import React, {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { SelectOption, SourceSystem } from '../../Shared/SharedModels';

import DatePicker from '../../Shared/components/DatePicker';
import FilterResetButton from '../../Shared/components/FilterResetButton';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { getDate } from '../../Shared/SharedHelpers';

interface ProjectHistoryFiltersProps {
    masterProjectsList: DedupeProject[];
    setFilteredProjectsList: Dispatch<SetStateAction<DedupeProject[]>>;
}

const ProjectHistoryFilters: FunctionComponent<ProjectHistoryFiltersProps> = (props: ProjectHistoryFiltersProps) => {
    const { masterProjectsList, setFilteredProjectsList } = props;
    const userProfile = useContext(UserProfileContext);

    const [projectName, setProjectName] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const [selectedSourceSystems, setSelectedSourceSystems] = useState<SelectOption<SourceSystem>[]>([]);
    const [sourceSystemOptions, setSourceSystemOptions] = useState<SelectOption<SourceSystem>[]>([]);

    const [selectedStatusOptions, setSelectedStatusOptions] = useState<SelectOption<number>[]>([]);
    const [projectStatusOptions] = useState<SelectOption<number>[]>([
        { label: 'Incomplete', value: ProjectHistoryStatus.Incomplete },
        { label: 'Complete', value: ProjectHistoryStatus.Complete },
        { label: 'Abandoned', value: ProjectHistoryStatus.Abandoned },
    ]);

    const [areFiltersApplied, setAreFiltersApplied] = useState(false);

    const clearFilters = useCallback(() => {
        setProjectName('');
        setCreatedBy('');
        setStartDate(null);
        setEndDate(null);
        setSelectedSourceSystems([]);
        setSelectedStatusOptions([]);
    }, []);

    useEffect(() => {
        setAreFiltersApplied(
            !!projectName ||
                !!createdBy ||
                !!startDate ||
                !!endDate ||
                !!selectedSourceSystems.length ||
                !!selectedStatusOptions.length
        );
    }, [projectName, createdBy, startDate, endDate, selectedSourceSystems.length, selectedStatusOptions.length]);

    useEffect(() => {
        setSourceSystemOptions(
            userProfile.sourceSystems.map((sourceSystem) => {
                return {
                    label: sourceSystem.sourceSystemName,
                    value: sourceSystem,
                };
            })
        );
    }, [userProfile.sourceSystems]);

    useEffect(() => {
        let filteredProjects = masterProjectsList;

        if (projectName) {
            filteredProjects = filteredProjects.filter((project) => {
                return project.name?.toLowerCase().includes(projectName.toLowerCase());
            });
        }

        if (createdBy) {
            filteredProjects = filteredProjects.filter((project) => {
                return project.created_by?.toLowerCase().includes(createdBy.toLowerCase());
            });
        }

        if (selectedSourceSystems.length) {
            filteredProjects = filteredProjects.filter((project) => {
                return selectedSourceSystems.find((system) => {
                    return system.value.sourceSystemKey === project.source_system_key;
                });
            });
        }

        if (startDate) {
            filteredProjects = filteredProjects.filter((project) => {
                return getDate(project.created_time) >= startDate;
            });
        }

        if (endDate) {
            filteredProjects = filteredProjects.filter((project) => {
                return getDate(project.created_time) <= endDate;
            });
        }

        if (selectedStatusOptions.length) {
            filteredProjects = filteredProjects.filter((project) => {
                return selectedStatusOptions.find((system) => {
                    return system.value === project.status;
                });
            });
        }

        setFilteredProjectsList(filteredProjects);
    }, [
        createdBy,
        endDate,
        masterProjectsList,
        projectName,
        selectedSourceSystems,
        selectedStatusOptions,
        setFilteredProjectsList,
        startDate,
    ]);

    return (
        <>
            <FilterResetButton clickCallback={clearFilters} filtersApplied={areFiltersApplied} />

            <Form className="filterFormContainer">
                <Form.Group className="data-table-filter">
                    <Form.Label>Project Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Project Name..."
                        value={projectName}
                        onChange={(event) => {
                            setProjectName(event.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group className="data-table-filter">
                    <Form.Label>Source System</Form.Label>
                    <Select
                        isMulti
                        isClearable
                        className="filterSelect"
                        placeholder="Select Source Systems..."
                        value={selectedSourceSystems}
                        options={sourceSystemOptions}
                        onChange={(selectedOptions) => {
                            setSelectedSourceSystems(selectedOptions as SelectOption<SourceSystem>[]);
                        }}
                    />
                </Form.Group>

                <Form.Group className="data-table-filter">
                    <Form.Label>Created By</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Creator..."
                        value={createdBy}
                        onChange={(event) => {
                            setCreatedBy(event.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group className="data-table-filter">
                    <Form.Label>Start Date</Form.Label>
                    <DatePicker value={startDate} onChange={setStartDate} />
                </Form.Group>
                <Form.Group className="data-table-filter">
                    <Form.Label>End Date</Form.Label>
                    <DatePicker value={endDate} onChange={setEndDate} />
                </Form.Group>

                <Form.Group className="data-table-filter">
                    <Form.Label>Status</Form.Label>
                    <Select
                        isMulti
                        isClearable
                        className="filterSelect"
                        placeholder="Select Status..."
                        value={selectedStatusOptions}
                        options={projectStatusOptions}
                        onChange={(selectedOptions) => {
                            setSelectedStatusOptions(selectedOptions as SelectOption<number>[]);
                        }}
                    />
                </Form.Group>
            </Form>
        </>
    );
};

export default ProjectHistoryFilters;
