import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../Shared/ApiHelper';
import { FormProvider } from '../Shared/contexts/FormProvider';
import UserForm from './UserForm/UserForm';
import { UserRoles } from '../Shared/SharedModels';
import { useMsal } from '@azure/msal-react';
import useUserForm from './UserForm/useUserForm';

interface AddUserModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    setUserWasSaved: (userWasSaved: boolean) => void;
    isEccoviaUser?: boolean;
}

const AddUserModal: FunctionComponent<AddUserModalProps> = (props: AddUserModalProps) => {
    const { showModal, setShowModal, setUserWasSaved, isEccoviaUser } = props;

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    const [sendInviteInProgress, setSendInviteInProgress] = useState<boolean>(false);

    const methods = useUserForm({ isEccoviaUser });

    const handleCloseClick = (): void => {
        setShowModal(false);
        methods.resetForm();
    };

    const handleSubmit = methods.handleSubmit((user) => {
        setSendInviteInProgress(true);

        const userPayload = user;
        if (userPayload.role?.roleKey === UserRoles['Project User']) {
            userPayload.organizations = [];
        }

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + `/User${isEccoviaUser ? '/eccovia' : ''}`,
            'POST',
            JSON.stringify(userPayload)
        )
            .then(() => {
                setUserWasSaved(true);
                setShowModal(false);
                methods.resetForm();
                toast.success('Your invite has been sent', {
                    position: 'top-right',
                    autoClose: 5000,
                    transition: Slide,
                    draggable: false,
                    closeOnClick: false,
                    theme: 'colored',
                });
            })
            .finally(() => {
                setSendInviteInProgress(false);
            });
    });

    return (
        <FormProvider {...methods}>
            <Modal
                scrollable
                show={showModal}
                onHide={handleCloseClick}
                onExit={() => setSendInviteInProgress(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{`New ${isEccoviaUser ? 'Eccovia ' : ''}Member Invitation`}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <UserForm mode="Add" isEccoviaUser={!!isEccoviaUser} editEnabledForAdmin={true} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={handleCloseClick}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={sendInviteInProgress} onClick={handleSubmit}>
                        {sendInviteInProgress ? (
                            <>
                                <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
                                Sending
                            </>
                        ) : (
                            <>Send Invite</>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </FormProvider>
    );
};

export default AddUserModal;
