import { HealthCross, Medication, WatsonHealthNominate } from '@carbon/icons-react';
import { HmisHeaders, HmisSummary } from '../Models/HmisModels';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import { ICONS } from '../../Shared/Client360Icons';
import Spinner from 'react-bootstrap/esm/Spinner';
import startCase from 'lodash/startCase';
import styles from './ServiceDetails.module.css';
import { useMsal } from '@azure/msal-react';

interface HmisSummaryExpansionProps {
    hmisData: HmisSummary;
    isExpanded: boolean;
}

const NON_DISPLAY_HEADERS: (keyof HmisSummary)[] = [
    'enrollmentKey',
    'canonicalId',
    'incomeAndSources',
    'healthInsurance',
    'healthConditions',
    'bedNightDate',
    'coordinatedEntryAssessment',
    'coordinatedEntryEvent',
    'services',
];

const HmisSummaryExpansion: FunctionComponent<HmisSummaryExpansionProps> = (props) => {
    const { hmisData, isExpanded } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();
    const [summaryData, setSummaryData] = useState<HmisSummary | undefined>(undefined);

    const headers = useCallback((): HmisHeaders[] => {
        const keys = Object.keys(hmisData).reduce((acc, headerName) => {
            if (!NON_DISPLAY_HEADERS.includes(headerName as keyof HmisSummary)) {
                acc.push(headerName as HmisHeaders);
            }
            return acc;
        }, [] as HmisHeaders[]);

        return keys;
    }, [hmisData]);

    const getEnrollmentData = useCallback(
        async (enrollmentKey: number, expanded: boolean): Promise<void> => {
            const url = `${process.env.REACT_APP_NET_API_URL}/HmisSummary/EnrollmentDetails/${enrollmentKey}`;
            if (expanded && summaryData === undefined) {
                setLoading(true);
                api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], url).then(async (result: Response) => {
                    const castHmis: HmisSummary = await result.json();
                    setSummaryData(castHmis);
                    setLoading(false);
                });
            }
        },
        [api, instance, summaryData]
    );

    useEffect(() => {
        getEnrollmentData(hmisData.enrollmentKey, isExpanded);
    }, [getEnrollmentData, hmisData.enrollmentKey, isExpanded]);

    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center p-5">
                    <Spinner
                        size="sm"
                        className="button-icon-padding button-spinner"
                        variant="primary"
                        animation="border"
                    />
                </div>
            ) : (
                <div className={styles.container}>
                    <ul className={styles.list}>
                        <li className={styles.line}>
                            {headers().map((header) => {
                                const Icon = ICONS[header] || WatsonHealthNominate;
                                return (
                                    <div key={header} className={styles.listColumn}>
                                        <div className={styles.cellTitle}>
                                            <Icon size={24} />
                                            <span>{startCase(header)}</span>
                                        </div>
                                        <span className={styles.cellValue}>
                                            <>{hmisData[header]}</>
                                        </span>
                                    </div>
                                );
                            })}
                        </li>
                    </ul>
                    <ul className={styles.list}>
                        <li className={styles.lineServiceHeader}>
                            <header className={styles.titleCard}>
                                <WatsonHealthNominate size={24} />
                                <span>Income and Sources</span>
                            </header>
                        </li>

                        <li className={styles.line}>
                            {summaryData?.incomeAndSources &&
                                Object.keys(summaryData.incomeAndSources)?.map((incomeAndSourcesKey, index) => (
                                    <div key={`income-${index}`} className={styles.cellSectionWithMinWidth}>
                                        <span>{startCase(incomeAndSourcesKey)}</span>
                                        <span>
                                            <>
                                                {summaryData?.incomeAndSources &&
                                                    summaryData.incomeAndSources[
                                                        incomeAndSourcesKey as keyof typeof summaryData.incomeAndSources
                                                    ]}
                                            </>
                                        </span>
                                    </div>
                                ))}
                        </li>
                    </ul>
                    <ul className={styles.list}>
                        <li className={styles.lineServiceHeader}>
                            <header className={styles.titleCard}>
                                <HealthCross size={24} />
                                <span>Health Insurance</span>
                            </header>
                        </li>

                        <li className={styles.line}>
                            {summaryData?.healthInsurance &&
                                Object.keys(summaryData.healthInsurance).map((healthInsuranceKey, index) => (
                                    <div key={`insurance-${index}`} className={styles.cellSectionWithMinWidth}>
                                        <span>{startCase(healthInsuranceKey)}</span>
                                        <span>
                                            {summaryData?.healthInsurance &&
                                                summaryData.healthInsurance[
                                                    healthInsuranceKey as keyof typeof summaryData.healthInsurance
                                                ]}
                                        </span>
                                    </div>
                                ))}
                        </li>
                    </ul>
                    <ul className={styles.list}>
                        <li className={styles.lineServiceHeader}>
                            <header className={styles.titleCard}>
                                <Medication size={24} />
                                <span>Health Conditions</span>
                            </header>
                        </li>

                        <li className={styles.line}>
                            {summaryData?.healthConditions &&
                                Object.keys(summaryData.healthConditions).map((healthConditionKey, index) => (
                                    <div key={`health-${index}`} className={styles.cellSectionWithMinWidth}>
                                        <span>{startCase(healthConditionKey)}</span>
                                        <span>
                                            {(summaryData?.healthInsurance &&
                                                summaryData.healthInsurance[
                                                    healthConditionKey as keyof typeof summaryData.healthInsurance
                                                ]) ||
                                                'No'}
                                        </span>
                                    </div>
                                ))}
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default HmisSummaryExpansion;
