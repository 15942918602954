import { ChevronDown, Enterprise } from '@carbon/icons-react';
import { Dropdown, NavLink, Spinner } from 'react-bootstrap';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import { CustomerEnvironment } from './TopNavModels';
import DropdownNavItem from './DropdownNavItem';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const EnvironmentDropdown: FunctionComponent = () => {
    const userProfile = useContext(UserProfileContext);
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();
    const navigate = useNavigate();

    const [currentEnvironment, setCurrentEnvironment] = useState<string>('Environment');
    const [loadingCurrentEnvironment, setLoadingCurrentEnvironment] = useState(true);
    const [customerEnvironments, setCustomerEnvironments] = useState<CustomerEnvironment[]>([]);
    const [loadingEnvironments, setLoadingEnvironments] = useState(true);

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/customers'
        ).then(async (result: Response) => {
            const customers: CustomerEnvironment[] = await result.json();
            setCustomerEnvironments(customers);
            setLoadingEnvironments(false);
        });
    }, [api, instance]);

    useEffect(() => {
        if (userProfile.user?.defaultCustomerId) {
            setCurrentEnvironment((prev) => userProfile.user?.defaultCustomerId ?? prev);
            setLoadingCurrentEnvironment(false);
        }
    }, [userProfile.user?.defaultCustomerId]);

    return (
        <Dropdown>
            <Dropdown.Toggle as={DropdownNavItem}>
                <NavLink data-testid="environmentList">
                    {loadingCurrentEnvironment ? (
                        <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
                    ) : (
                        <Enterprise size={24} className="nav-item-contents top-nav-item-icon" />
                    )}
                    <div className="nav-item-contents top-nav-item-label d-md-none-inverted">{currentEnvironment}</div>
                    <ChevronDown size={24} className="nav-item-contents user-icon d-md-none-inverted" />
                </NavLink>
            </Dropdown.Toggle>
            <Dropdown.Menu className={'top-nav-dropdown-menu'} style={{ width: '200%' }}>
                {loadingEnvironments ? (
                    <Dropdown.Item disabled>Loading Customer Environments...</Dropdown.Item>
                ) : (
                    customerEnvironments.map((env: CustomerEnvironment) => (
                        <Dropdown.Item
                            key={env.customerId + '-dropdown'}
                            onClick={() => {
                                userProfile.setIsLoading(true);
                                setCurrentEnvironment(env.customerId);
                                navigate('/');

                                const netApiCall = api.callApi(
                                    instance,
                                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                    process.env.REACT_APP_NET_API_URL + `/User/me/${env.customerId}`,
                                    'PUT'
                                );

                                const dedupeApiCall = api.callApi(
                                    instance,
                                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                    process.env.REACT_APP_PYTHON_API_URL + `/switch/${env.customerId}`
                                );

                                Promise.all([netApiCall, dedupeApiCall]).finally(() => {
                                    userProfile.setIsLoading(false);
                                });
                            }}
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {env.name}
                        </Dropdown.Item>
                    ))
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default EnvironmentDropdown;
