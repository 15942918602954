import { DedupeSourceSystem, SelectOption } from '../../../../Shared/SharedModels';
import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import Select, { SingleValue, Theme } from 'react-select';

import ApiHelper from '../../../../Shared/ApiHelper';
import { Form } from 'react-bootstrap';
import { IncrementalDedupeStatistics } from '../../../DataToolsModels';
import { useMsal } from '@azure/msal-react';

interface ProjectParametersFormProps {
    projectName?: string;
    setProjectName?: (projectName: string) => void;
    projectSourceSystem?: DedupeSourceSystem;
    setProjectSourceSystem: (projectSourceSystem?: DedupeSourceSystem) => void;
    setDisableContinue: (disableContinue: boolean) => void;
    isDedupeProject?: boolean;
    usePreviousTraining?: boolean;
    setUsePreviousTraining?: Dispatch<SetStateAction<boolean>>;
    autoApprovalThreshold?: number;
    setAutoApprovalThreshold?: Dispatch<SetStateAction<number | undefined>>;
}

const ProjectParametersForm: FunctionComponent<ProjectParametersFormProps> = (props: ProjectParametersFormProps) => {
    const {
        projectName,
        setProjectName,
        projectSourceSystem,
        setProjectSourceSystem,
        setDisableContinue,
        isDedupeProject,
        usePreviousTraining,
        setUsePreviousTraining,
        autoApprovalThreshold,
        setAutoApprovalThreshold,
    } = props;

    const [sourceSystemOptions, setSourceSystemOptions] = useState<SelectOption<DedupeSourceSystem>[]>([]);
    const [selectedSourceSystemOption, setSelectedSourceSystemOption] = useState<SelectOption<DedupeSourceSystem>>();
    const [incrementalDedupeDetails, setIncrementalDedupeDetails] = useState<IncrementalDedupeStatistics>();
    const [shouldAutoApproveClusters, setShouldAutoApproveClusters] = useState<boolean>(true);
    const [isAutoApprovalValid, setIsAutoApprovalValid] = useState<boolean>(true);

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    const formSelectTheme = useCallback(
        (theme: Theme) => ({
            ...theme,
            borderRadius: 8,
        }),
        []
    );

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_PYTHON_API_URL + '/sourcesystems'
        ).then(async (result: Response) => {
            const sources = await result.json();
            const mappedSources = sources
                .filter((source: DedupeSourceSystem) => source.isActive)
                .map((source: DedupeSourceSystem) => {
                    return {
                        label: source.shortName,
                        value: source,
                    };
                });
            setSourceSystemOptions(mappedSources);
        });

        if (isDedupeProject) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_PYTHON_API_URL + '/uci/full-dedupe-required'
            ).then(async (result: Response) => {
                const stats = await result.json();

                setIncrementalDedupeDetails(stats);

                if (setUsePreviousTraining) {
                    setUsePreviousTraining(!stats.fullDedupeRequired);
                }
            });
        }
    }, [api, instance, isDedupeProject, setUsePreviousTraining]);

    useEffect(() => {
        if (setProjectName) {
            // Project is being used for dedupe
            setDisableContinue(projectName === '' || !projectSourceSystem || !isAutoApprovalValid);
        } else {
            // Project is being used for non-dedupe
            setDisableContinue(!projectSourceSystem);
        }
    }, [isAutoApprovalValid, projectName, projectSourceSystem, setDisableContinue, setProjectName]);

    useEffect(() => {
        let isValid = false;

        if (
            shouldAutoApproveClusters &&
            autoApprovalThreshold != undefined &&
            autoApprovalThreshold >= 50 &&
            autoApprovalThreshold <= 100
        ) {
            isValid = true;
        } else if (!shouldAutoApproveClusters && autoApprovalThreshold === undefined) {
            isValid = true;
        }

        setIsAutoApprovalValid(isValid);
    }, [autoApprovalThreshold, shouldAutoApproveClusters]);

    useEffect(() => {
        if (setAutoApprovalThreshold) {
            if (shouldAutoApproveClusters) {
                setAutoApprovalThreshold(90);
            } else {
                setAutoApprovalThreshold(undefined);
            }
        }
    }, [setAutoApprovalThreshold, shouldAutoApproveClusters]);

    useEffect(() => {
        if (setUsePreviousTraining && selectedSourceSystemOption?.value.key !== 0) {
            setUsePreviousTraining(false);
        }
    }, [selectedSourceSystemOption?.value.key, setUsePreviousTraining]);

    return (
        <Form className="new-project-form">
            {setProjectName && (
                <Form.Group className="project-form-group">
                    <Form.Label>Project Name*</Form.Label>
                    <Form.Control
                        placeholder="Enter Project Name"
                        value={projectName}
                        isInvalid={projectName === ''}
                        onChange={(event) => {
                            setProjectName(event.target.value);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">Project Name is required</Form.Control.Feedback>
                </Form.Group>
            )}
            <Form.Group className="project-form-group">
                <Form.Label>Data Source</Form.Label>

                <Select
                    options={sourceSystemOptions}
                    value={selectedSourceSystemOption}
                    theme={formSelectTheme}
                    placeholder="Select Data Source..."
                    isLoading={sourceSystemOptions.length <= 0}
                    onChange={(selectedOption: SingleValue<SelectOption<DedupeSourceSystem>>) => {
                        setSelectedSourceSystemOption(selectedOption ?? undefined);
                        setProjectSourceSystem(selectedOption?.value);
                    }}
                    menuPlacement="auto"
                />
                <Form.Control.Feedback type="invalid">Data Source is required</Form.Control.Feedback>
            </Form.Group>

            {isDedupeProject && setAutoApprovalThreshold && (
                <Form.Group className="project-form-group">
                    <Form.Check
                        className="form-control-lg ml-2"
                        type="switch"
                        label={'Auto Review Clusters'}
                        checked={shouldAutoApproveClusters}
                        onChange={() => {
                            setShouldAutoApproveClusters((prev) => !prev);
                        }}
                        title="Clusters with confidence above the specified threshold will be auto approved if enabled, and clusters below will be auto rejected"
                    />

                    <Form.Label>Approval Threshold</Form.Label>
                    <Form.Control
                        as="input"
                        type="number"
                        placeholder="Enter Threshold..."
                        onChange={(event) => {
                            const newThreshold = parseInt(event.target.value);
                            setAutoApprovalThreshold(newThreshold);
                        }}
                        value={autoApprovalThreshold}
                        disabled={!shouldAutoApproveClusters}
                        isInvalid={!isAutoApprovalValid}
                        defaultValue={90}
                    />
                    <Form.Control.Feedback type="invalid">
                        Threshold must be a number between 50 and 100
                    </Form.Control.Feedback>
                </Form.Group>
            )}

            {isDedupeProject && setUsePreviousTraining && projectSourceSystem?.key === 0 && (
                <Form.Group className="project-form-group">
                    <Form.Check
                        className="form-control-lg ml-2"
                        type="switch"
                        label={'Use Previous Training'}
                        checked={usePreviousTraining}
                        disabled={incrementalDedupeDetails?.fullDedupeRequired}
                        onChange={() => {
                            setUsePreviousTraining((prev) => !prev);
                        }}
                        title="New training may be required if too many new clients have been added to the system"
                    />
                </Form.Group>
            )}
        </Form>
    );
};

export default ProjectParametersForm;
