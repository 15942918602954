import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';
import './index.css';

import App from './App';
import { HashRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import React from 'react';
import { render } from 'react-dom';
import reportWebVitals from './reportWebVitals';

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENTID ?? '',
        authority: `https://${process.env.REACT_APP_AUTHORITY_DOMAIN}/${process.env.REACT_APP_B2C_TENANT}.onmicrosoft.com/B2C_1A_SIGNIN_ONLY`,
        knownAuthorities: [`https://${process.env.REACT_APP_AUTHORITY_DOMAIN}`],
        redirectUri: process.env.REACT_APP_BASE_URL,
        postLogoutRedirectUri: process.env.REACT_APP_BASE_URL,
    },

    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};
const msalContext = await PublicClientApplication.createPublicClientApplication(msalConfig);

render(
    <React.StrictMode>
        <MsalProvider instance={msalContext}>
            <HashRouter>
                <App />
            </HashRouter>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('page-top')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
