/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Select, { MultiValue } from 'react-select';

import ApiHelper from '../ApiHelper';
import { Form } from 'react-bootstrap';
import { SelectOption } from '../SharedModels';
import { User } from '../../TeamMembers/TeamMemberModels';
import { useMsal } from '@azure/msal-react';

interface RecipientsSelectionProps {
    label?: string;
    value?: SelectOption<string>[];
    onChange: (selectedRecipients: MultiValue<SelectOption<string>>) => void;
}

const RecipientsSelection: FunctionComponent<RecipientsSelectionProps> = (props: RecipientsSelectionProps) => {
    const { label, value, onChange } = props;

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    const [recipientOptions, setRecipientOptions] = useState<SelectOption<string>[]>([]);
    const [areRecipientsLoading, setAreRecipientsLoading] = useState<boolean>(true);

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User'
        ).then(async (result: Response) => {
            const users = await result.json();

            const mappedUsers: SelectOption<string>[] = users.map((user: User) => {
                return {
                    label: user.upn,
                    value: user.upn,
                };
            });
            mappedUsers.sort((a: SelectOption<string>, b: SelectOption<string>) => {
                return a.value.localeCompare(b.value);
            });
            setRecipientOptions(mappedUsers);
            setAreRecipientsLoading(false);
        });
    }, [api, instance]);

    return (
        <Form.Group className="pr-3 pb-2">
            {label && <Form.Label>{label}</Form.Label>}

            <Select
                isLoading={areRecipientsLoading}
                isMulti
                className="filterSelect recipients-input"
                placeholder="Select Recipients"
                closeMenuOnSelect={false}
                value={value}
                options={recipientOptions}
                onChange={(selectedOptions) => {
                    onChange(selectedOptions);
                }}
                menuPlacement="auto"
            />
        </Form.Group>
    );
};

export default RecipientsSelection;
