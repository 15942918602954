import './EmbededReport.css';

import {
    CategoryKey,
    ActivityLogEntry as ReportActivityLogEntry,
    ReportCreationPayload,
    ReportEmbedConfigBuilder,
    ReportMetadata,
    SaveEventDetails,
} from './ReportModels';
import { Embed, IEmbedConfiguration, factories, service } from 'powerbi-client';
import React, {
    FunctionComponent,
    RefObject,
    createRef,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import ApiHelper from '../Shared/ApiHelper';
import CreateReportEmbedConfig from '../Utils/ReportEmbedConfigFactory';
import { UserProfile } from '../Shared/SharedModels';
import { UserProfileContext } from '../Shared/contexts/UserProfile';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

export interface EmbededReportProps {
    accessToken?: string;
    embedUrl?: string;
    datasetId?: string;
    workspaceId?: string;
    reportId?: string;
    type: string;
    editMode?: boolean;
    createdByUser?: boolean;
    description?: string;
    selectedCategoryOptionKeys?: CategoryKey[];
    setDisplaySaveReportModal: (show: boolean) => void;
    report: Embed | undefined;
    setReport: React.Dispatch<React.SetStateAction<Embed | undefined>>;
    setIsSaveAs: React.Dispatch<React.SetStateAction<boolean>>;
    isPublic: boolean;
    reportName?: string;
}

const EmbededReport: FunctionComponent<EmbededReportProps> = (props: EmbededReportProps) => {
    const {
        accessToken,
        embedUrl,
        datasetId,
        workspaceId,
        reportId,
        type,
        editMode,
        createdByUser,
        description,
        selectedCategoryOptionKeys,
        setDisplaySaveReportModal,
        report,
        setReport,
        setIsSaveAs,
        isPublic,
        reportName,
    } = props;

    const [reportContainerReference, setReportContainerReference] = useState<HTMLElement>();
    const [reportRef] = useState<React.Ref<HTMLDivElement>>(createRef());
    const [reportContainerDiv] = useState<JSX.Element>(
        <div id="reportContainer" ref={reportRef}>
            Loading...
        </div>
    );
    const [powerbi] = useState(
        new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory)
    );

    const userProfile: UserProfile = useContext(UserProfileContext);
    const navigate = useNavigate();
    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();
    const initialized = useRef(false);

    const logReportActivity = useCallback(
        (
            correlationId: string,
            activity: string,
            dataset: string,
            datasetId: string,
            report: string,
            reportId: string,
            originalReportID: string,
            loadDuration: number,
            renderDuration: number
        ): void => {
            const logEntry: ReportActivityLogEntry = {
                Activity: activity,
                Dataset: dataset,
                DatasetId: datasetId,
                Report: report,
                ReportId: reportId,
                ReportType: 'PowerBIReport',
                PageName: '',
                OriginalReportId: originalReportID,
                LoadDuration: loadDuration,
                RenderDuration: renderDuration,
                CorrelationId: correlationId,
            };

            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/UsageLogging/report',
                'POST',
                JSON.stringify(logEntry)
            ).catch((e) => console.log(e)); // dont let any logging exception here block the code flow
        },
        [api, instance]
    );

    useEffect(() => {
        if (reportRef !== null && (reportRef as RefObject<HTMLDivElement>).current) {
            setReportContainerReference((reportRef as RefObject<HTMLDivElement>).current as HTMLDivElement);
        }

        return () => {
            if (reportContainerReference) {
                powerbi.reset(reportContainerReference);
            }
        };
    }, [powerbi, reportContainerReference, reportRef]);

    useEffect(() => {
        if (reportContainerReference) {
            powerbi.reset(reportContainerReference);
        }
    }, [powerbi, reportContainerReference, reportId, workspaceId]);

    useEffect(() => {
        if (accessToken && embedUrl && reportContainerReference) {
            // set up variables to collect performance data for report loading
            const timerStart: number = Date.now();
            let loadDuration: number;
            let renderDuration: number;

            const configBuilder: ReportEmbedConfigBuilder = {
                accessToken,
                type,
                embedUrl,
                datasetId,
                editMode,
                createdByUser,
                reportId,
            };
            const embedConfig: IEmbedConfiguration = CreateReportEmbedConfig(configBuilder);
            let pbiReport: Embed | undefined;
            if (type === 'create' && datasetId) {
                pbiReport = powerbi.createReport(reportContainerReference, embedConfig);
                // setReport(powerbi.createReport(reportContainerReference, embedConfig));
            } else if (reportId) {
                pbiReport = powerbi.embed(reportContainerReference, embedConfig);
                // setReport(powerbi.embed(reportContainerReference, embedConfig));
            }

            if (pbiReport) {
                setReport(pbiReport);

                if (!initialized.current) {
                    initialized.current = true;

                    pbiReport.off('loaded');
                    pbiReport.on('loaded', async (_event) => {
                        loadDuration = Date.now() - timerStart;
                    });

                    pbiReport.off('rendered');
                    pbiReport.on('rendered', async (_event) => {
                        renderDuration = Date.now() - timerStart;
                        const correlationId = await pbiReport?.getCorrelationId();
                        const datasetId: string = configBuilder.datasetId ?? '';

                        logReportActivity(
                            correlationId ?? '',
                            'ViewReport',
                            datasetId,
                            datasetId,
                            reportName ?? '',
                            configBuilder.reportId ?? '',
                            '',
                            loadDuration,
                            renderDuration
                        );
                    });
                }
            }
        }
    }, [accessToken, createdByUser, datasetId, editMode, embedUrl, powerbi, reportContainerReference, reportId, setReport, type, logReportActivity, reportName]);

    useEffect(() => {
        if (report) {
            report.off('saved');
            report.on('saved', async (event: service.ICustomEvent<SaveEventDetails>) => {
                const correlationId: string = await report.getCorrelationId();

                if (event.detail.saveAs) {
                    const datasetName = userProfile.environmentConfig.pbiConfig?.datasets.find(
                        (x) => x.datasetGuid === datasetId
                    )?.typeName;
                    const payload: ReportCreationPayload = {
                        Name: event.detail.reportName,
                        Description: description ?? '',
                        ReportId: event.detail.reportObjectId,
                        Categories: selectedCategoryOptionKeys ?? [],
                        Type: isPublic ? 'Public' : 'Custom',
                        Dataset: datasetName,
                    };

                    logReportActivity(
                        correlationId,
                        'SaveAsReport',
                        datasetName ?? '',
                        datasetId ?? '',
                        payload.Name,
                        payload.ReportId,
                        event.detail.originalReportObjectId ?? '',
                        0,
                        0
                    );

                    api.callApi(
                        instance,
                        [process.env.REACT_APP_B2C_SCOPE ?? ''],
                        process.env.REACT_APP_NET_API_URL + '/PbiReport',
                        'POST',
                        JSON.stringify(payload)
                    ).then(async (result: Response) => {
                        const reportMetadata: ReportMetadata = await result.json();

                        navigate(
                            `/reports/${reportMetadata.workSpaceId}/${reportMetadata.reportId}/${reportMetadata.datasetGuid}`
                        );
                    });
                } else {
                    setDisplaySaveReportModal(true);
                    setIsSaveAs(false);

                    logReportActivity(
                        correlationId,
                        'SaveReport',
                        '',
                        '',
                        event.detail.reportName ?? '',
                        event.detail.reportObjectId ?? '',
                        event.detail.originalReportObjectId ?? '',
                        0,
                        0
                    );
                }
            });

            report.off('saveAsTriggered');
            report.on('saveAsTriggered', (_event) => {
                setIsSaveAs(true);
                setDisplaySaveReportModal(true);
            });
        }
    }, [
        api,
        datasetId,
        description,
        instance,
        isPublic,
        navigate,
        report,
        selectedCategoryOptionKeys,
        setDisplaySaveReportModal,
        setIsSaveAs,
        userProfile.environmentConfig.pbiConfig?.datasets,
        logReportActivity,
    ]);

    return reportContainerDiv;
};

export default EmbededReport;
