import { useEffect, useState } from 'react';

import { WindowDimensions } from './SharedModels';

function getWindowDimensions(): WindowDimensions {
    const width = window.innerWidth;
    const height = window.innerHeight;
    return {
        width,
        height,
    };
}

export default function useWindowDimensions(): WindowDimensions {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize(): void {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
