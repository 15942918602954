import { Button, Form, Modal } from 'react-bootstrap';
import { CategoryKey, CategoryOption, ReportCategory, ReportCreationPayload, ReportMetadata } from './ReportModels';
import { Embed, models } from 'powerbi-client';
import React, { Dispatch, FunctionComponent, SetStateAction, useContext, useState } from 'react';

import ApiHelper from '../Shared/ApiHelper';
import Select from 'react-select';
import { UserProfileContext } from '../Shared/contexts/UserProfile';
import { useMsal } from '@azure/msal-react';

interface SaveReportModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    categoryOptions: CategoryOption[];
    selectedCategoryOptionKeys: CategoryKey[];
    setSelectedCategoryOptionKeys: Dispatch<SetStateAction<CategoryKey[]>>;
    setDescription: Dispatch<SetStateAction<string>>;
    description: string;
    report: Embed | undefined;
    isSaveAs: boolean;
    isPublic?: boolean;
    reportName?: string;
    reportMetadata?: ReportMetadata;
    setReportMetadata?: Dispatch<SetStateAction<ReportMetadata | undefined>>;
}

const SaveReportModal: FunctionComponent<SaveReportModalProps> = (props: SaveReportModalProps) => {
    const {
        showModal,
        setShowModal,
        categoryOptions,
        selectedCategoryOptionKeys,
        setSelectedCategoryOptionKeys,
        setDescription,
        description,
        report,
        isSaveAs,
        isPublic,
        reportName,
        reportMetadata,
        setReportMetadata,
    } = props;

    const [reportTitle, setReportTitle] = useState<string>('');
    const userProfile = useContext(UserProfileContext);

    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    return (
        <Modal scrollable show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isSaveAs ? 'Save Report As' : 'Save Report'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="p-3">
                    <Form.Group controlId="Title" className="mb-4">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Add Title..."
                            onChange={(event) => {
                                setReportTitle(event.target.value);
                            }}
                            disabled={!isSaveAs}
                            value={!isSaveAs ? reportName : reportTitle}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Category</Form.Label>
                        <Select
                            isMulti
                            className="filterSelect"
                            closeMenuOnSelect={false}
                            options={categoryOptions}
                            placeholder="Select Categories..."
                            onChange={(selectedOptions) => {
                                setSelectedCategoryOptionKeys(
                                    selectedOptions.map((option) => ({ CategoryKey: option.value }))
                                );
                            }}
                            defaultValue={reportMetadata?.categories?.map((option: ReportCategory) => ({
                                label: option.categoryName,
                                value: option.categoryKey,
                            }))}
                            menuPlacement="auto"
                            maxMenuHeight={100}
                        />
                    </Form.Group>
                    <Form.Group controlId="Description" className="mb-4">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            placeholder="Add Description..."
                            maxLength={255}
                            onChange={(event) => {
                                setDescription(event.target.value);
                            }}
                            defaultValue={reportMetadata?.description}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="link"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        if (isSaveAs) {
                            const saveParameters: models.ISaveAsParameters = {
                                name: reportTitle,
                                targetWorkspaceId: isPublic
                                    ? userProfile.environmentConfig.pbiConfig?.authorPublicWorkspaceId
                                    : userProfile.environmentConfig.pbiConfig?.authorWorkspaceId,
                            };

                            report?.saveAs(saveParameters);
                        } else {
                            const payload: ReportCreationPayload = {
                                Name: reportName ?? '',
                                Description: description,
                                ReportId: reportMetadata?.reportId ?? '',
                                Categories: selectedCategoryOptionKeys,
                                Type: isPublic ? 'Public' : 'Custom',
                            };

                            api.callApi(
                                instance,
                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                process.env.REACT_APP_NET_API_URL + '/PbiReport',
                                'PUT',
                                JSON.stringify(payload)
                            ).then(async (_result: Response) => {
                                api.callApi(
                                    instance,
                                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                    process.env.REACT_APP_NET_API_URL +
                                        `/PbiReport/${reportMetadata?.workSpaceId}/${reportMetadata?.reportId}`,
                                    'GET'
                                ).then(async (result: Response) => {
                                    const newReportMetadata: ReportMetadata = await result.json();
                                    setReportMetadata && setReportMetadata(newReportMetadata);
                                });
                            });
                        }

                        setShowModal(false);
                    }}
                >
                    Save Report
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SaveReportModal;
