import { DQAlertConfigData, DQAlertConfigViewModel, DQAlertThresholdPair } from './DataQualityModels';
import { Form, InputGroup } from 'react-bootstrap';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { Percentage } from '@carbon/icons-react';

interface ThresholdPairProps {
    elementConfig: DQAlertConfigData;
    thresholdPair: DQAlertThresholdPair;
    setActiveConfigData: React.Dispatch<React.SetStateAction<DQAlertConfigViewModel | undefined>>;
}
const MAX_THRESHOLD = 99;
const MIN_THRESHOLD = 0;

const ThresholdPair: FunctionComponent<ThresholdPairProps> = (props: ThresholdPairProps) => {
    const { elementConfig, thresholdPair, setActiveConfigData } = props;

    const [missingThreshold, setMissingThreshold] = useState(thresholdPair.missingThreshold);
    const [unknownThreshold, setUnknownThreshold] = useState(thresholdPair.unknownThreshold);

    useEffect(() => {
        setMissingThreshold(thresholdPair.missingThreshold);
        setUnknownThreshold(thresholdPair.unknownThreshold);
    }, [thresholdPair.missingThreshold, thresholdPair.unknownThreshold]);

    const updateActiveConfig = useCallback(
        (thresholdName: string, thresholdValue: string) => {
            setActiveConfigData((activeConfigData) => {
                return activeConfigData
                    ? {
                          ...activeConfigData,
                          configData: activeConfigData.configData.map((oldRecord) => {
                              try {
                                  if (oldRecord.element === elementConfig.element) {
                                      return {
                                          ...oldRecord,
                                          thresholds: {
                                              ...oldRecord.thresholds,
                                              [thresholdName]: parseInt(thresholdValue),
                                          },
                                      };
                                  }
                              } catch (e) {
                                  console.log(e);
                              }

                              return oldRecord;
                          }),
                      }
                    : undefined;
            });
        },
        [elementConfig.element, setActiveConfigData]
    );

    return (
        <div className="flex-row pr-4">
            <Form.Group className="pr-1 threshold-input">
                <Form.Label>Missing</Form.Label>
                <InputGroup className="inputIcon-reverse">
                    <Form.Control
                        type="text"
                        value={missingThreshold}
                        onChange={(event) => {
                            const value = parseInt(event.target.value);
                            const isNumber = !isNaN(value);

                            if (isNumber) {
                                if (value < MIN_THRESHOLD) {
                                    setMissingThreshold(0);
                                } else if (value > MAX_THRESHOLD) {
                                    setMissingThreshold(99);
                                } else {
                                    setMissingThreshold(value);
                                }
                            } else if (!event.target.value) {
                                setMissingThreshold(0);
                            }
                        }}
                        onBlur={(event) => {
                            updateActiveConfig(thresholdPair.missingThresholdName, event.target.value);
                        }}
                    />
                    <InputGroup.Text>
                        <Percentage size={24} />
                    </InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group className="px-1 threshold-input">
                <Form.Label>Unknown</Form.Label>
                <InputGroup className="inputIcon-reverse">
                    <Form.Control
                        type="text"
                        value={unknownThreshold}
                        onChange={(event) => {
                            const value = parseInt(event.target.value);
                            const isNumber = !isNaN(value);

                            if (isNumber) {
                                if (value < MIN_THRESHOLD) {
                                    setUnknownThreshold(0);
                                } else if (value > MAX_THRESHOLD) {
                                    setUnknownThreshold(99);
                                } else {
                                    setUnknownThreshold(value);
                                }
                            } else if (!event.target.value) {
                                setUnknownThreshold(0);
                            }
                        }}
                        onBlur={(event) => {
                            updateActiveConfig(thresholdPair.unknownThresholdName, event.target.value);
                        }}
                    />
                    <InputGroup.Text>
                        <Percentage size={24} />
                    </InputGroup.Text>
                </InputGroup>
            </Form.Group>
        </div>
    );
};

export default ThresholdPair;
