import './SplashScreen.css';

import { Button, Container, Spinner } from 'react-bootstrap';
import { Logout, Reset } from '@carbon/icons-react';
import React, { FunctionComponent } from 'react';

import { useMsal } from '@azure/msal-react';

export interface SplashScreenProps {
    failedToLoad: boolean;
}

const SplashScreen: FunctionComponent<SplashScreenProps> = (props) => {
    const { failedToLoad } = props;
    const { instance } = useMsal();

    return (
        <Container fluid className="splash-screen-container">
            <div className="content-column-container splash-screen-contents">
                <div className="spinner-container">
                    {failedToLoad ? (
                        <img className="bug-image" height={80} src="bug.svg" alt="Data Lookup" />
                    ) : (
                        <Spinner className="centered-spinner" variant="primary" size="sm" animation="border" />
                    )}
                </div>
                <h1 className="splash-screen-text">
                    {failedToLoad
                        ? 'Something went wrong, please reload the page to try again'
                        : 'Loading User Profile...'}
                </h1>
                {failedToLoad && (
                    <div className="splash-screen-contents">
                        <Button variant="primary" href="/" className="reload-button">
                            <Reset size={24} className="button-icon-padding" />
                            {'Reload Page'}
                        </Button>
                        <Button
                            variant="primary"
                            className="reload-button"
                            onClick={() => {
                                instance.logout();
                            }}
                        >
                            <Logout size={24} className="button-icon-padding" />
                            Logout
                        </Button>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default SplashScreen;
