import { Button, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../../Shared/ApiHelper';
import { Email } from '@carbon/icons-react';
import { useMsal } from '@azure/msal-react';

interface ResendInviteProps {
    email: string;
    isEccoviaUser?: boolean;
}

const ResendInvite: React.FC<ResendInviteProps> = ({ email, isEccoviaUser = false }) => {
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (): Promise<void> => {
        try {
            setIsLoading(true);

            const eccoviaUrl = isEccoviaUser ? '/eccovia' : '';

            await api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_NET_API_URL}/User${eccoviaUrl}/resend-email/${email}`,
                'PUT'
            );

            toast.success('Invite has been resent', {
                position: 'top-right',
                autoClose: 3000,
                transition: Slide,
                draggable: false,
                closeOnClick: false,
                theme: 'colored',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Button className="mx-2" variant="outline-primary" disabled={isLoading} onClick={handleClick}>
            <div>
                {isLoading ? (
                    <Spinner
                        variant="primary"
                        animation="border"
                        style={{ width: '20px', height: '20px', margin: '0px 2px' }}
                    />
                ) : (
                    <Email size={24} />
                )}
            </div>
            <span className="ml-2">Re-Invite</span>
        </Button>
    );
};

export default ResendInvite;
