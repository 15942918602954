import { Button, Form } from 'react-bootstrap';
import { Filter, FilterRemove } from '@carbon/icons-react';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Select, { Theme } from 'react-select';
import { SelectOption, UserRoles } from '../Shared/SharedModels';

import { DisplayUser } from './TeamMemberModels';

interface UserFilterProps {
    masterUserList: DisplayUser[];
    setFilteredUsers: (users: DisplayUser[]) => void;
}

const UserFilters: FunctionComponent<UserFilterProps> = (props: UserFilterProps) => {
    const { masterUserList: masterUserList, setFilteredUsers: setUsers } = props;
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState<SelectOption<number>[]>([]);
    const [userJobTitle, setUserJobTitle] = useState('');
    const [areFiltersApplied, setAreFiltersApplied] = useState(false);

    const [roleOptions] = useState<SelectOption<number>[]>([
        { label: 'Global Admin', value: UserRoles['Global Admin'] },
        { label: 'Source System Admin', value: UserRoles['Source System Admin'] },
        { label: 'Org Admin', value: UserRoles['Org Admin'] },
        { label: 'Member', value: UserRoles.Member },
    ]);
    const [statusOptions] = useState<SelectOption<string>[]>([
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
        { label: 'Pending', value: 'Pending' },
    ]);

    const formSelectTheme = useCallback(
        (theme: Theme) => ({
            ...theme,
            borderRadius: 8,
        }),
        []
    );
    const [userStatus, setUserStatus] = useState<SelectOption<string>[]>([statusOptions[0], statusOptions[1]]);

    const clearFilters = useCallback(() => {
        setUserName('');
        setUserRole([]);
        setUserJobTitle('');
        setUserStatus([]);
    }, []);

    useEffect(() => {
        setAreFiltersApplied(!!userName || !!userRole.length || !!userJobTitle || !!userStatus.length);
    }, [userName, userRole.length, userJobTitle, userStatus.length]);

    useEffect(() => {
        let filteredUsers = masterUserList;

        if (userName) {
            filteredUsers = filteredUsers.filter((user) => {
                return user.name?.toLowerCase().includes(userName.toLowerCase());
            });
        }

        if (userJobTitle) {
            filteredUsers = filteredUsers.filter((user) => {
                return user.jobTitle?.toLowerCase().includes(userJobTitle.toLowerCase());
            });
        }

        if (userRole.length) {
            filteredUsers = filteredUsers.filter((user) => {
                return userRole.find((role) => {
                    return role.value === user.fullUser.roleKey;
                });
            });
        }

        if (userStatus.length) {
            filteredUsers = filteredUsers.filter((user) => {
                return userStatus.find((status) => {
                    return status.value === user.status;
                });
            });
        }

        setUsers(filteredUsers);
    }, [masterUserList, setUsers, userName, userRole, userJobTitle, userStatus]);

    return (
        <>
            <div className="filtersAppliedContainer">
                <Button variant="link" disabled={!areFiltersApplied} onClick={clearFilters}>
                    {areFiltersApplied ? (
                        <>
                            <FilterRemove size={24} className="button-icon-padding" />
                            Reset All Filters
                        </>
                    ) : (
                        <>
                            <Filter size={24} className="button-icon-padding" />
                            No Filters Applied
                        </>
                    )}
                </Button>
            </div>
            <Form className="filterFormContainer">
                <Form.Group className="data-table-filter">
                    <Form.Label>Team Member Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name..."
                        value={userName}
                        onChange={(event) => {
                            setUserName(event.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Group className="data-table-filter">
                    <Form.Label>Role</Form.Label>
                    <Select
                        isMulti
                        className="filteredSelect"
                        placeholder="Select role..."
                        value={userRole}
                        theme={formSelectTheme}
                        options={roleOptions}
                        onChange={(selectedOptions) => {
                            setUserRole(selectedOptions as SelectOption<number>[]);
                        }}
                        menuPlacement="auto"
                    />
                </Form.Group>
                <Form.Group className="data-table-filter">
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Job title..."
                        value={userJobTitle}
                        onChange={(event) => {
                            setUserJobTitle(event.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Group className="data-table-filter">
                    <Form.Label>Status</Form.Label>
                    <Select
                        isMulti
                        className="filteredSelect"
                        placeholder="Select status..."
                        value={userStatus}
                        theme={formSelectTheme}
                        options={statusOptions}
                        onChange={(selectedOptions) => {
                            setUserStatus(selectedOptions as SelectOption<string>[]);
                        }}
                        menuPlacement="auto"
                    />
                </Form.Group>
            </Form>
        </>
    );
};

export default UserFilters;
