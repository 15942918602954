import {
    CheckmarkFilled,
    CheckmarkOutline,
    ChevronDown,
    ChevronRight,
    ErrorFilled,
    ErrorOutline,
} from '@carbon/icons-react';
import { Cluster, ClusterRecord, ClusterStatus } from '../../../DataToolsModels';
import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';

import ApiHelper from '../../../../Shared/ApiHelper';
import { Spinner } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';

interface ClusterReviewActionsProps {
    data: Cluster[];
    updateClusterStatus: (row: number, status: ClusterStatus) => void;
    projectId: number;
    isAllRowsExpanded: boolean;
    toggleAllRowsExpanded: (expanded: boolean | undefined) => void;
    setShowMassApprovalModal: Dispatch<SetStateAction<boolean>>;
}

const ClusterReviewActions: FunctionComponent<ClusterReviewActionsProps> = (props: ClusterReviewActionsProps) => {
    const { data, updateClusterStatus, projectId, isAllRowsExpanded, toggleAllRowsExpanded, setShowMassApprovalModal } =
        props;

    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const [apiCallingApproveEndpoint, setApiCallingApproveEndpoint] = useState<boolean>();
    const [apiCallingRejectEndpoint, setApiCallingRejectEndpoint] = useState<boolean>();
    const [allMatchesApproved, setAllMatchesApproved] = useState<boolean>();
    const [allMatchesRejected, setAllMatchesRejected] = useState<boolean>();

    const ApproveOrRejectBatch = (status: ClusterStatus): void => {
        let recordsToSend: ClusterRecord[] = [];

        // Create CluesterRecord[] to send to API
        data.forEach((cluster) => {
            if (cluster.clusterStatus !== status) {
                recordsToSend = recordsToSend.concat(cluster.clusterRecords.filter((r) => !r.canon));
            }
        });

        if (recordsToSend.length != 0) {
            // Disable button before the api call.
            status == ClusterStatus.APPROVED ? setApiCallingApproveEndpoint(true) : setApiCallingRejectEndpoint(true);
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_PYTHON_API_URL +
                    `/clusters/${status == ClusterStatus.APPROVED ? 'confirm' : 'remove'}/${projectId}`,
                'POST',
                JSON.stringify(recordsToSend)
            ).then(async (_result: Response) => {
                // Re-enable button after successful api call.
                status == ClusterStatus.APPROVED
                    ? setApiCallingApproveEndpoint(false)
                    : setApiCallingRejectEndpoint(false);

                // Update Front-End if successful
                data.forEach((_cluster, index) => {
                    updateClusterStatus(index, status);
                });
            });
        }
    };

    useEffect(() => {
        // Prevent filled icons from showing on page load.
        if (data.length > 0) {
            let allMatchesApproved = true;
            let allMatchesRejected = true;

            // Loops through the data. If all matches are APPROVED, for example, allMatchesApproved will stay true.
            // But if one of the matches is not APPROVED then allMatchesApproved is switched to false.
            data.forEach((cluster) => {
                allMatchesApproved = allMatchesApproved && cluster.clusterStatus == ClusterStatus.APPROVED;
                allMatchesRejected = allMatchesRejected && cluster.clusterStatus == ClusterStatus.REJECTED;
            });

            setAllMatchesApproved(allMatchesApproved);
            setAllMatchesRejected(allMatchesRejected);
        }
    }, [data]);

    return (
        <th
            colSpan={8}
            style={{
                backgroundColor: 'rgb(239,252,255)',
                borderBottomRightRadius: '0',
                borderBottomLeftRadius: '0',
            }}
        >
            <div className="flex-row space-between">
                <div>
                    <button
                        className="btn pl-0 table-header-button"
                        onClick={() => {
                            toggleAllRowsExpanded(!isAllRowsExpanded);
                        }}
                    >
                        {isAllRowsExpanded ? (
                            <span>
                                <ChevronDown size={20} className="expand-icon mr-2" />
                                Collapse All
                            </span>
                        ) : (
                            <span>
                                <ChevronRight size={20} className="expand-icon mr-2" />
                                Expand All
                            </span>
                        )}
                    </button>

                    <button
                        disabled={apiCallingApproveEndpoint}
                        className="btn table-header-button ml-4 d-inline-block"
                        onClick={() => {
                            ApproveOrRejectBatch(ClusterStatus.APPROVED);
                        }}
                    >
                        {apiCallingApproveEndpoint ? (
                            <>
                                <Spinner
                                    size="sm"
                                    className="button-icon-padding button-spinner"
                                    animation="border"
                                    style={{
                                        width: '16.97px',
                                        height: '16.97px',
                                        marginLeft: '4px',
                                        marginRight: '11px',
                                    }}
                                />
                                <span className="ml-0">Approve All</span>
                            </>
                        ) : allMatchesApproved ? (
                            <>
                                <CheckmarkFilled
                                    size={24}
                                    className="cluster-action-inactive mr-2"
                                    style={{ color: '#76aa34' }}
                                />
                                Approve Batch
                            </>
                        ) : (
                            <>
                                <CheckmarkOutline size={24} className="cluster-action-inactive mr-2" />
                                Approve Batch
                            </>
                        )}
                    </button>

                    <button
                        disabled={apiCallingRejectEndpoint}
                        className="btn table-header-button d-inline-block"
                        onClick={() => {
                            ApproveOrRejectBatch(ClusterStatus.REJECTED);
                        }}
                    >
                        {apiCallingRejectEndpoint ? (
                            <>
                                <Spinner
                                    size="sm"
                                    className="button-icon-padding button-spinner"
                                    animation="border"
                                    style={{
                                        width: '16.97px',
                                        height: '16.97px',
                                        marginLeft: '4px',
                                        marginRight: '6.9px',
                                    }}
                                />{' '}
                                <span className="ml-0">Reject Batch</span>
                            </>
                        ) : allMatchesRejected ? (
                            <>
                                <ErrorFilled
                                    size={24}
                                    className="cluster-action-inactive mr-2"
                                    style={{ color: '#c74443' }}
                                />
                                Reject Batch
                            </>
                        ) : (
                            <>
                                <ErrorOutline size={24} className="cluster-action-inactive mr-2" />
                                Reject Batch
                            </>
                        )}
                    </button>
                </div>

                <button
                    className="btn table-header-button d-inline-block"
                    onClick={() => {
                        setShowMassApprovalModal(true);
                    }}
                >
                    Auto Review
                </button>
            </div>
        </th>
    );
};

export default ClusterReviewActions;
