export interface UserNotification {
    userNotificationKey: number;
    userKey: number;
    message: string;
    navigationUrl?: string;
    notificationType?: NotificationType;
    isRead: boolean;
    isDismissed: boolean;
    dateCreated: string;
    dateUpdated: string;
}

export enum NotificationType {
    Info = 0,
    Warning = 1,
    Success = 2,
    Danger = 3,
}

export interface CustomerEnvironment {
    customerKey: number;
    customerId: string;
    name: string;
    accountManagerEmail: string;
    settings: string;
    active: boolean;
}
