/* eslint-disable react-hooks/rules-of-hooks */

import './TeamMembers.css';

import { Button, Form } from 'react-bootstrap';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { DisplayUser, User, UserStatus } from './TeamMemberModels';
import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UserProfileContext, isGlobalAdmin } from '../Shared/contexts/UserProfile';

import { AddFilled } from '@carbon/icons-react';
import AddUserModal from './AddUserModal';
import ApiHelper from '../Shared/ApiHelper';
import DataTable from '../Shared/components/DataTable';
import EditUserPanel from './EditUserPanel';
import UserFilters from './TeamMembersFilter';
import { sendEvent } from '../Utils/googleAnalytics4Wrapper';
import { useMsal } from '@azure/msal-react';

interface TeamMembersProps {
    isEccovia?: boolean;
}

const TeamMembers: FunctionComponent<TeamMembersProps> = ({ isEccovia }) => {
    const userProfile = useContext(UserProfileContext);
    const hasEditPermissions = userProfile.environmentConfig.editByGlobalAdminOnly ? isGlobalAdmin(userProfile) : true;
    const [selectedUser, setSelectedUser] = useState<DisplayUser | undefined>(undefined);
    const [filteredUsers, setFilteredUsers] = useState<DisplayUser[]>([]);
    const [showEditPanel, setShowEditPanel] = useState(false);
    const [userList, setUserList] = useState<DisplayUser[]>([]);
    const [userListLoading, setUserListLoading] = useState(false);
    const [userWasSaved, setUserWasSaved] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const fetchIdRef = useRef(0);
    const userListColumns: ColumnDef<DisplayUser>[] = useMemo(
        () => [
            { header: 'Name', accessorKey: 'name' },
            { header: 'Role', accessorKey: 'role' },
            { header: 'Job Title', accessorKey: 'jobTitle' },
            {
                header: 'Status',
                accessorKey: 'status',
                cell: ({ row, column, table }: CellContext<DisplayUser, unknown>) => {
                    const api = useMemo(() => new ApiHelper(), []);
                    const { instance, accounts } = useMsal();

                    const value = row.original.status;
                    const isUserPending = value === 'Pending';
                    const isCurrentUser =
                        row.original.fullUser.firstName + ' ' + row.original.fullUser.lastName === accounts[0].name;
                    const disableSwitch = !hasEditPermissions || isUserPending || isCurrentUser;

                    return (
                        <Form>
                            <Form.Group>
                                <Form.Check
                                    className="form.control"
                                    type="switch"
                                    label={value ?? 'invalid status'}
                                    disabled={disableSwitch}
                                    checked={value === 'Active'}
                                    onChange={() => {
                                        const upn = row.original.fullUser.upn;
                                        const newStatus = row.original.fullUser.status === UserStatus.Inactive;
                                        const toggleUrl = isEccovia
                                            ? `${process.env.REACT_APP_NET_API_URL}/User/eccovia/${upn}/${newStatus}`
                                            : `${process.env.REACT_APP_NET_API_URL}/User/${upn}/${newStatus}`;
                                        api.callApi(
                                            instance,
                                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                            toggleUrl,
                                            'PUT'
                                        );

                                        if (table.options.meta?.updateData) {
                                            table.options.meta.updateData(
                                                row.index,
                                                column.id,
                                                newStatus === true ? 'Active' : 'Inactive'
                                            );
                                        }
                                        row.original.fullUser.status =
                                            newStatus === true ? UserStatus.Active : UserStatus.Inactive;
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                ></Form.Check>
                            </Form.Group>
                        </Form>
                    );
                },
            },
        ],
        [hasEditPermissions, isEccovia]
    );
    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    const getTeamMembersData = useCallback(() => {
        const fetchId = ++fetchIdRef.current;

        setUserListLoading(true);

        const eccoviaUserUrl = isEccovia ? '/eccovia' : '';

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_NET_API_URL}/User${eccoviaUserUrl}`
        ).then(async (result: Response) => {
            if (fetchId === fetchIdRef.current) {
                const users = await result.json();

                const mappedUsers: DisplayUser[] = users.map((user: User) => {
                    return {
                        name: user.firstName + ' ' + user.lastName,
                        role: user.role?.name,
                        jobTitle: user.jobTitle,
                        status: user.status !== undefined ? UserStatus[user.status] : undefined,
                        fullUser: user,
                    };
                });
                setUserList(mappedUsers);
                setFilteredUsers(mappedUsers);
            }

            setUserListLoading(false);
        });
    }, [api, instance, isEccovia]);

    useEffect(() => {
        getTeamMembersData();
    }, [getTeamMembersData]);

    const setUser = useCallback(
        (user: DisplayUser) => {
            setSelectedUser(user);
            setShowEditPanel(true);
        },
        [setSelectedUser]
    );

    useEffect(() => {
        if (userWasSaved) {
            setUserWasSaved(false);
        }
    }, [userWasSaved]);

    return (
        <div className="team-members-container">
            <div className="team-members-main-content">
                <div className="content-header">
                    <h1 className="content-title">{isEccovia ? 'Eccovia Users' : 'Team Members'}</h1>
                    <div className="flex-row flex-wrap" style={{ gap: '12px' }}>
                        {hasEditPermissions &&
                            (isEccovia ? (
                                <Button
                                    variant="outline-primary"
                                    onClick={() => {
                                        setShowAddUserModal(true);
                                        sendEvent('Team Member', 'Add Eccovia Member', 'Button');
                                    }}
                                >
                                    <AddFilled size={24} className="button-icon-padding" /> Add Eccovia Member
                                </Button>
                            ) : (
                                <Button
                                    variant="outline-primary"
                                    onClick={() => {
                                        setShowAddUserModal(true);
                                        sendEvent('Team Member', 'Add Member', 'Button');
                                    }}
                                >
                                    <AddFilled size={24} className="button-icon-padding" /> Add Member
                                </Button>
                            ))}
                    </div>
                </div>

                {!userWasSaved && (
                    <div>
                        <UserFilters masterUserList={userList} setFilteredUsers={setFilteredUsers} />
                        <DataTable
                            label="team members"
                            data={filteredUsers}
                            refreshData={getTeamMembersData}
                            setData={setFilteredUsers}
                            columns={userListColumns}
                            loading={userListLoading}
                            recordsAreClickable
                            onRecordClick={setUser}
                        />
                    </div>
                )}
            </div>

            {selectedUser && (
                <EditUserPanel
                    key={selectedUser.fullUser.userKey}
                    showPanel={showEditPanel}
                    setShowPanel={setShowEditPanel}
                    user={selectedUser.fullUser}
                    setUserWasSaved={setUserWasSaved}
                    isEccoviaUser={isEccovia}
                    hasEditPermissions={hasEditPermissions}
                    setUserList={setUserList}
                    userList={userList}
                />
            )}

            <AddUserModal
                showModal={showAddUserModal}
                setShowModal={setShowAddUserModal}
                setUserWasSaved={setUserWasSaved}
                isEccoviaUser={isEccovia}
            />
        </div>
    );
};

export default TeamMembers;
