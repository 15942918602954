import { Button, Spinner } from 'react-bootstrap';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { View, ViewOff } from '@carbon/icons-react';

import ApiHelper from '../../Shared/ApiHelper';
import { ClientProfile } from '../Models/EhrModels';
import { Gender } from '../../Shared/SharedModels';
import { formatDate } from '../../Shared/SharedHelpers';
import styles from './ClientInfo.module.css';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';

const ClientInfo: FunctionComponent = () => {
    const { id } = useParams();

    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const [client, setClient] = useState<ClientProfile>();
    const [isLoading, setIsLoading] = useState(true);
    const [shouldBlur, setShouldBlur] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_PYTHON_API_URL + `/get-client/${id}`
        )
            .then(async (result: Response) => {
                const clientDetails: ClientProfile = await result.json();

                setClient(clientDetails);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [api, id, instance]);

    return (
        <div className={`${styles.container}`}>
            {isLoading ? (
                <div className="flex-column">
                    <Spinner className="centered-spinner" variant="primary" size="sm" animation="border" />
                </div>
            ) : (
                <>
                    <div className={styles.header}>
                        <h5 className={styles.title}>{`${client?.firstname} ${client?.lastname}`}</h5>

                        <Button
                            title="Show/hide client information"
                            variant="link"
                            onClick={() => {
                                setShouldBlur((prev) => !prev);
                            }}
                        >
                            {shouldBlur ? <View size={24} /> : <ViewOff size={24} />}
                        </Button>
                    </div>

                    <div className={`${styles.content}${shouldBlur ? ' blur-filter' : ''}`}>
                        <div>
                            <div className={styles.label}>Gender</div>
                            <div className={`${styles.text}`}>{client?.gender ? Gender[client.gender] : '-'}</div>
                        </div>

                        <div>
                            <div className={styles.label}>DOB</div>
                            <div className={`${styles.text}`}>{client?.dob ? formatDate(client.dob) : '-'}</div>
                        </div>

                        <div>
                            <div className={styles.label}>SSN</div>
                            <div className={`${styles.text}`}>{client?.ssn ? client.ssn : '-'}</div>
                        </div>

                        <div className={styles.contentRow}>
                            <div style={{ flex: 1 }}>
                                <div className={styles.label}>Veteran Status</div>
                                <div className={`${styles.veteranBadge}`}>
                                    {client?.veteranstatus ? client.veteranstatus : '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ClientInfo;
