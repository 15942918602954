/* eslint-disable react-hooks/rules-of-hooks */

import { Breadcrumb, Button, Form } from 'react-bootstrap';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UserProfileContext, isGlobalAdmin } from '../../Shared/contexts/UserProfile';

import AddSourceSystem from './AddSourceSystem';
import ApiHelper from '../../Shared/ApiHelper';
import { ChevronRight } from '@carbon/icons-react';
import DataTable from '../../Shared/components/DataTable';
import { SourceSystem } from '../../Shared/SharedModels';
import SourceSystemFilters from './SourceSystemFilters';
import { User } from '../../TeamMembers/TeamMemberModels';
import { useMsal } from '@azure/msal-react';

const SourceSystemAdmin: FunctionComponent = () => {
    const navigate: NavigateFunction = useNavigate();
    const userProfile = useContext(UserProfileContext);

    const [isListLoading, setIsListLoading] = useState(false);
    const [sourceSystemWasAdded, setSourceSystemWasAdded] = useState<boolean>(false);
    const [sourceSystems, setSourceSystems] = useState<SourceSystem[]>([]);
    const [filteredSourceSystems, setFilteredSourceSystems] = useState<SourceSystem[]>([]);

    const fetchIdRef = useRef(0);
    const sourceSystemColumns: ColumnDef<SourceSystem>[] = useMemo(
        () => [
            {
                header: 'Full Name',
                accessorKey: 'sourceSystemName',
                cell: ({ row }: CellContext<SourceSystem, unknown>) => {
                    const navigate = useNavigate();

                    return (
                        <Button
                            variant="link"
                            onClick={() => {
                                navigate(
                                    `/admin/dataqualityalerts?startingSourceSystem=${row.original.sourceSystemKey}`
                                );
                            }}
                        >
                            {row.original.sourceSystemName}
                        </Button>
                    );
                },
            },
            { header: 'Short Name', accessorKey: 'sourceSystemShortName' },
            { header: 'Ext Short Name', accessorKey: 'externalShortName' },
            {
                header: 'Admin(s)',
                accessorKey: 'sourceSystemAdmins',
                cell: ({ row }: CellContext<SourceSystem, unknown>) => {
                    return row.original.sourceSystemAdmins.map((admin: User) => admin.upn).join(', ');
                },
            },
            {
                header: 'Status',
                accessorKey: 'isActive',
                cell: ({ row, column, table }: CellContext<SourceSystem, unknown>) => {
                    const api = useMemo(() => new ApiHelper(), []);
                    const { instance } = useMsal();
                    const userProfile = useContext(UserProfileContext);

                    return (
                        <Form>
                            <Form.Group>
                                <Form.Check
                                    className="form.control"
                                    type="switch"
                                    disabled={!isGlobalAdmin(userProfile)}
                                    label={row.original.isActive ? 'Active' : 'Inactive'}
                                    checked={row.original.isActive}
                                    onChange={() => {
                                        const newStatus = !row.original.isActive;

                                        api.callApi(
                                            instance,
                                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                            `${process.env.REACT_APP_NET_API_URL}/User/sourcesystems/${row.original.sourceSystemKey}/${newStatus}`,
                                            'PUT'
                                        );

                                        if (table.options.meta?.updateData) {
                                            table.options.meta.updateData(row.index, column.id, newStatus);
                                        }
                                        //updateData(row.index, column.id, newStatus);
                                        userProfile.setSourceSystems((prev) => {
                                            return prev.map((system) => {
                                                if (system.sourceSystemKey == row.original.sourceSystemKey) {
                                                    const newSystem = system;
                                                    newSystem.isActive = newStatus;
                                                    return newSystem;
                                                }
                                                return system;
                                            });
                                        });
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                ></Form.Check>
                            </Form.Group>
                        </Form>
                    );
                },
            },
            {
                header: 'Manual Uploads',
                accessorKey: 'manualUploadsEnabled',
                cell: ({ row, column, table }: CellContext<SourceSystem, unknown>) => {
                    const api = useMemo(() => new ApiHelper(), []);
                    const { instance } = useMsal();
                    const userProfile = useContext(UserProfileContext);

                    return (
                        <Form>
                            <Form.Group>
                                <Form.Check
                                    className="form.control"
                                    type="switch"
                                    disabled={!isGlobalAdmin(userProfile)}
                                    label={row.original.manualUploadsEnabled ? 'Enabled' : 'Disabled'}
                                    checked={row.original.manualUploadsEnabled}
                                    onChange={() => {
                                        const newStatus = !row.original.manualUploadsEnabled;

                                        api.callApi(
                                            instance,
                                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                            `${process.env.REACT_APP_NET_API_URL}/User/sourcesystems/${row.original.sourceSystemKey}/manualUploads/${newStatus}`,
                                            'PUT'
                                        );

                                        if (table.options.meta?.updateData) {
                                            table.options.meta.updateData(row.index, column.id, newStatus);
                                        }

                                        userProfile.setSourceSystems((prev) => {
                                            return prev.map((system) => {
                                                if (system.sourceSystemKey == row.original.sourceSystemKey) {
                                                    const newSystem = system;
                                                    newSystem.manualUploadsEnabled = newStatus;
                                                    return newSystem;
                                                }
                                                return system;
                                            });
                                        });
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                ></Form.Check>
                            </Form.Group>
                        </Form>
                    );
                },
            },
        ],
        []
    );

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    useEffect(() => {
        if (sourceSystemWasAdded) {
            const fetchId = ++fetchIdRef.current;

            setIsListLoading(true);
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/User/sourcesystems/true'
            )
                .then(async (result: Response) => {
                    if (result)
                        if (fetchId === fetchIdRef.current) {
                            const sources: SourceSystem[] = await result.json();
                            userProfile.setSourceSystems(sources);
                        }
                })
                .finally(() => {
                    setIsListLoading(false);
                });
        }
    }, [api, instance, sourceSystemWasAdded, userProfile]);

    useEffect(() => {
        if (sourceSystemWasAdded) {
            setSourceSystemWasAdded(false);
        }
    }, [sourceSystemWasAdded]);

    useEffect(() => {
        setSourceSystems(userProfile.sourceSystems.filter((system) => system.sourceSystemKey !== 0));
    }, [userProfile.sourceSystems]);

    return (
        <div className="content-column-container">
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        navigate('/admin');
                    }}
                >
                    Configuration
                </Breadcrumb.Item>
                <ChevronRight size={24} className="breadcrumb-chevron" />
                <Breadcrumb.Item active>Source Systems</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content-header">
                <h1 className="content-title">Source Systems</h1>
                {isGlobalAdmin(userProfile) && <AddSourceSystem />}
            </div>
            <SourceSystemFilters
                masterSourceSystemList={sourceSystems}
                setFilteredSourceSystems={setFilteredSourceSystems}
            />

            {!sourceSystemWasAdded && (
                <DataTable
                    label="Source Systems"
                    data={filteredSourceSystems}
                    columns={sourceSystemColumns}
                    loading={isListLoading}
                    refreshData={() => {
                        setSourceSystemWasAdded(true);
                    }}
                />
            )}
        </div>
    );
};

export default SourceSystemAdmin;
