import { ChevronDown, Star } from '@carbon/icons-react';
import { Dropdown, NavLink } from 'react-bootstrap';
import React, { FunctionComponent, useContext } from 'react';

import DropdownNavItem from './DropdownNavItem';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { useNavigate } from 'react-router-dom';

const FavoritesDropdown: FunctionComponent = () => {
    const userProfile = useContext(UserProfileContext);
    const navigate = useNavigate();

    return (
        <Dropdown>
            <Dropdown.Toggle as={DropdownNavItem}>
                <NavLink id="favoritesDropdown" data-testid="favoritesDropdown">
                    <Star size={24} className="nav-item-contents top-nav-item-icon" />
                    <div className="nav-item-contents top-nav-item-label d-md-none-inverted">Favorites</div>
                    <ChevronDown size={24} className="nav-item-contents user-icon d-md-none-inverted" />
                </NavLink>
            </Dropdown.Toggle>
            <Dropdown.Menu className={'top-nav-dropdown-menu'} style={{ width: '200%' }}>
                {userProfile.favoriteReports.length > 0 ? (
                    userProfile.favoriteReports.map((fav) => (
                        <Dropdown.Item
                            key={fav.reportKey}
                            onClick={() => {
                                navigate(`/reports/${fav.workSpaceId}/${fav.reportId}/${fav.datasetGuid}`);
                            }}
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {fav.name}
                        </Dropdown.Item>
                    ))
                ) : (
                    <Dropdown.Item disabled style={{ textAlign: 'center' }}>
                        You have no favorited reports
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default FavoritesDropdown;
