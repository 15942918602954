/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useNavigationBlocker } from './useNavigationBlocker';

export function useNavigationPrompt(when: boolean): (boolean | (() => void))[] {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPrompt, setShowPrompt] = useState(false);
    const [lastLocation, setLastLocation] = useState<any>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const cancelNavigation = useCallback(() => {
        setShowPrompt(false);
        setLastLocation(null);
    }, []);

    // handle blocking when user click on another route prompt will be shown
    const handleBlockedNavigation = useCallback(
        (nextLocation: any) => {
            // in if condition we are checking next location and current location are equals or not
            if (
                !confirmedNavigation &&
                (nextLocation.location.pathname !== location.pathname ||
                    nextLocation.location.search !== location.search)
            ) {
                setShowPrompt(true);
                setLastLocation(nextLocation);
                return false;
            }
            return true;
        },
        [confirmedNavigation, location]
    );

    const confirmNavigation = useCallback(() => {
        setShowPrompt(false);
        setConfirmedNavigation(true);
    }, []);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(`${lastLocation.location?.pathname}${lastLocation.location?.search}`);

            // Clean-up state on confirmed navigation
            setConfirmedNavigation(false);
        }
    }, [confirmedNavigation, lastLocation]);

    useNavigationBlocker(handleBlockedNavigation, when);

    return [showPrompt, confirmNavigation, cancelNavigation];
}
