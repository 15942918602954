/* eslint-disable @typescript-eslint/no-explicit-any */

import { ChangeEvent, FormEvent, MouseEvent, useCallback, useState } from 'react';
import { ObjectSchema, ValidationError } from 'yup';

import get from 'lodash/get';
import set from 'lodash/set';

interface UseFormProps<T> {
    defaultValues?: T;
    schema?: ObjectSchema<any>;
}

export interface UseFormReturn<T> {
    errors?: UseFormErrors;
    formState: T;
    handleSubmit: UseFormSubmit<T>;
    resetForm(newValues?: T | any): void;
    register: UseFormRegister;
    setValue(field: string, value: any): void;
    setValues(state: React.SetStateAction<T>): void;
    validateForm(): void;
    children?: React.ReactNode;
}

type UseFormRegister = (field: string) => {
    id: string;
    name: string;
    value: any;
    isInvalid: boolean;
    onBlur(): void;
    onChange(event: ChangeEvent<HTMLInputElement>): void;
};

type UseFormErrors = Record<string, any>;

type UseFormSubmit<T> = (
    submit: (formState: T) => void | Promise<void>
) => (event: FormEvent<HTMLFormElement> | MouseEvent) => void;

export default function useForm<T = Record<string, unknown>>({
    defaultValues,
    schema,
}: UseFormProps<T> = {}): UseFormReturn<T> {
    const [defaultFormState] = useState(defaultValues);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [errors, setErrors] = useState<UseFormErrors>();
    const [formState, setFormState] = useState<T | any>(defaultFormState || {});

    const getNewObject = (object: object): object => JSON.parse(JSON.stringify(object));

    const setValue = useCallback((field: string, value: any): void => {
        setFormState((previousState: T | any) => {
            return { ...previousState, [field]: value };
        });
    }, []);

    const setError = (field: string, message?: string): void => {
        const newErrors = set<UseFormErrors>(getNewObject(errors || {}), field, message);
        setErrors(newErrors);
    };

    const resetForm = useCallback(
        (newValues?: T | any): void => {
            setFormState(getNewObject(newValues || defaultFormState || {}));
            setErrors(undefined);
            setIsSubmitting(false);
        },
        [defaultFormState]
    );

    const formatErrors = (error: ValidationError): UseFormErrors =>
        Object.values(error.inner).reduce(
            (total: UseFormErrors, innerError) =>
                innerError.path ? set(total, innerError.path, innerError.message) : total,
            {}
        );

    const validateForm = async (): Promise<void> => {
        if (!isSubmitting) {
            return;
        }

        try {
            await schema?.validate(formState, { abortEarly: false });
            setErrors(undefined);
        } catch (error) {
            setErrors(formatErrors(error as ValidationError));
        }
    };

    const handleChange = (field: string) => (event: ChangeEvent<HTMLInputElement>) =>
        setValue(field, event.target.value);

    const handleBlur = (field: string) => async () => {
        if (!isSubmitting) {
            return;
        }

        try {
            await schema?.validateAt(field, formState, { strict: true });
            setError(field);
        } catch (error) {
            setError(field, (error as ValidationError).message);
        }
    };

    const register: UseFormRegister = (field) => ({
        id: field,
        name: field,
        value: get(formState, field),
        isInvalid: isSubmitting && !!get(errors, field),
        onBlur: handleBlur(field),
        onChange: handleChange(field),
    });

    const handleSubmit: UseFormSubmit<T> = (submit) => async (event) => {
        try {
            setIsSubmitting(true);
            event?.preventDefault();

            await schema?.validate(formState, { abortEarly: false });

            submit(formState);
        } catch (error) {
            setErrors(formatErrors(error as ValidationError));
        }
    };

    return {
        errors,
        formState,
        handleSubmit,
        register,
        resetForm,
        setValue,
        setValues: setFormState,
        validateForm,
    };
}
