import React, { SVGProps } from 'react';

const LoadingSpinnerIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M3.92285 15.2785L4.75618 14.5931C8.19369 17.2264 11.0499 17.5681 13.8187 15.7077C15.0097 14.9118 15.9224 13.7638 16.4292 12.4241C16.9361 11.0843 17.0118 9.61965 16.6458 8.23476C15.8333 5.14518 13.3437 3.33476 9.41661 3.07643L9.34994 2.03476C12.5104 1.37018 16.2583 3.71393 17.5729 7.1181C18.2708 8.93219 18.2926 10.9369 17.6343 12.7657C16.976 14.5946 15.6815 16.1255 13.9874 17.0785C10.6562 18.9598 6.28744 18.216 3.92285 15.2785Z"
            fill="currentColor"
        />
        <path
            d="M3.55623 5.04688L4.50415 5.75521L3.96873 6.70521L2.8479 6.15312L3.55623 5.04688Z"
            fill="currentColor"
        />
        <path
            d="M7.23121 3.66172L6.72705 2.59089L7.97705 2.13672L8.26038 3.31589L7.23121 3.66172Z"
            fill="currentColor"
        />
        <path
            d="M6.21237 4.23047L5.32487 4.91588L4.50195 4.04297L5.48946 3.23047L6.21237 4.23047Z"
            fill="currentColor"
        />
    </svg>
);

export default LoadingSpinnerIcon;
