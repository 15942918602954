import { Dropdown, NavLink } from 'react-bootstrap';
import { Education, Email, Headset, Help } from '@carbon/icons-react';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { UserProfileContext, isGlobalAdmin } from '../../Shared/contexts/UserProfile';

import DropdownNavItem from './DropdownNavItem';
import { useLocation } from 'react-router-dom';

const HelpDropdown: FunctionComponent = () => {
    const userProfile = useContext(UserProfileContext);
    const location = useLocation();
    const [_pathName, setPathName] = useState(location.pathname);

    // This code forces a re-render of the component when the url changes which is
    // necessary because changes to document.title will not trigger a render on its own
    useEffect(() => {
        setPathName(location.pathname);
    }, [location.pathname]);

    const contextAnchor = document?.title?.replaceAll
        ? '#' + document?.title?.replaceAll(': ', '_').replaceAll(' ', '_')
        : '';

    return (
        <Dropdown>
            <Dropdown.Toggle as={DropdownNavItem}>
                <NavLink id="helpDropdown" data-testid="helpDropdown">
                    <Help size={24} className="nav-item-contents top-nav-item-icon" />
                    <div className="nav-item-contents top-nav-item-label d-md-none-inverted">Help</div>
                </NavLink>
            </Dropdown.Toggle>

            <Dropdown.Menu className="top-nav-dropdown-menu" style={{ width: '200%' }}>
                <Dropdown.Item
                    href={process.env.REACT_APP_HELP_URL + '?' + userProfile.cdnToken + contextAnchor}
                    target="_blank"
                    className="flex-row"
                >
                    <Help size={20} className="nav-item-contents top-nav-item-icon" />
                    <div className="nav-item-contents">Read Help Articles</div>
                </Dropdown.Item>
                <Dropdown.Item href={process.env.REACT_APP_UNIVERSITY_URL} target="_blank" className="flex-row">
                    <Education size={20} className="nav-item-contents top-nav-item-icon" />
                    <div className="nav-item-contents">Eccovia University</div>
                </Dropdown.Item>
                {userProfile.environmentConfig.supportEmail && (
                    <Dropdown.Item
                        href={`mailto:${userProfile.environmentConfig.supportEmail}?subject=ClientInsight%20Feedback
                        &body=NOTE%3A%20Email%20is%20not%20a%20secure%20method%20for%20sharing%20confidential%20
                        information.%20DO%20NOT%20attach%20files%20containing%20PII%20or%20include%20PII%20in%20
                        your%20message.`}
                        target="_blank"
                        className="flex-row"
                    >
                        <Email size={20} className="nav-item-contents top-nav-item-icon" />
                        <div className="nav-item-contents">Give Feedback</div>
                    </Dropdown.Item>
                )}
                {(!userProfile.environmentConfig.supportEmail || isGlobalAdmin(userProfile)) && (
                    <Dropdown.Item
                        href={`${process.env.REACT_APP_FEEDBACK_URL}${
                            userProfile.user?.defaultCustomerId ?? userProfile.user?.customerId
                        }`}
                        target="_blank"
                        className="flex-row"
                    >
                        <Headset size={20} className="nav-item-contents top-nav-item-icon" />
                        <div className="nav-item-contents">Escalate Ticket</div>
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default HelpDropdown;
