import { CoC, HmisProject, HmisProjectType, Organization, SelectOption, SourceSystem } from '../Shared/SharedModels';

export interface DashboardVisual {
    name: string;
    title: string;
    checked: boolean;
}

// To give consistent margin to each visual in the custom showcase
export const LAYOUT_SHOWCASE = {
    MARGIN: 16,
    VISUAL_ASPECT_RATIO: 9 / 16,
};

// For the decision of two custom layout with spanning
export enum SPAN_TYPE {
    NONE = 0,
    ROWSPAN = 1,
    COLSPAN = 2,
}

// For the decision of the layout
export enum COLUMNS {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

export interface DashboardLayout {
    columns: COLUMNS;
    span: SPAN_TYPE;
    icon: string;
}

export interface DashboardFilterPreferences {
    sourceSystem?: SelectOption<SourceSystem>;
    organization?: SelectOption<Organization>;
    coc?: SelectOption<CoC>;
    hmisProjectType?: SelectOption<HmisProjectType>;
    hmisProject?: SelectOption<HmisProject>;
}

export interface DashboardPreferences {
    selectedVisuals?: DashboardVisual[];
    layout?: DashboardLayout;
    filters?: DashboardFilterPreferences;
}
