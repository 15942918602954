import { clarity } from 'clarity-js';
import { setCookie } from '../SharedHelpers';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

export default function useClarity(): void {
    const {
        accounts: [user],
    } = useMsal();
    const location = useLocation();

    useEffect(() => {
        clarity.consent();
        const projectId = process.env.REACT_APP_CLARITY_PROJECT_ID;

        if (user && projectId) {
            const username = user.name || '';

            setCookie('username', username);
            clarity.set('username', username);

            clarity.start({
                content: true,
                projectId,
                upload: 'https://m.clarity.ms/collect',
                track: true,
                cookies: ['username'],
            });
        }

        return () => {
            clarity.stop();
        };
    }, [user, location.pathname]);
}
