import { IEmbedConfiguration, models } from 'powerbi-client';

import { ReportEmbedConfigBuilder } from '../Reports/ReportModels';

const CreateReportEmbedConfig = (builder: ReportEmbedConfigBuilder): IEmbedConfiguration => {
    const baseObject = {
        type: builder.type,
        accessToken: builder.accessToken,
        tokenType: models.TokenType.Embed,
        embedUrl: builder.embedUrl,
        ...(builder.datasetId && { datasetBinding: { datasetId: builder.datasetId } }),
        settings: {
            layoutType: models.LayoutType.Custom,
            background: models.BackgroundType.Transparent,
            customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
            },
            navContentPaneEnabled: false,
            useCustomSaveAsDialog: true,
        },
    };

    if (baseObject.type === 'create' && builder.datasetId) {
        return {
            ...baseObject,
            datasetId: builder.datasetId,
            settings: {
                ...baseObject.settings,
                panes: {
                    bookmarks: {
                        visible: true,
                    },
                    filters: {
                        visible: true,
                        expanded: true,
                    },
                },
            },
        };
    } else if (builder.reportId) {
        return {
            ...baseObject,
            permissions: builder.createdByUser ? models.Permissions.All : models.Permissions.Copy,
            viewMode: builder.editMode ? models.ViewMode.Edit : models.ViewMode.View,
            datasetBinding: { datasetId: builder.datasetId ?? '' },
            id: builder.reportId,
            settings: {
                ...baseObject.settings,
                filterPaneEnabled: builder.editMode,
                bars: {
                    actionBar: {
                        visible: builder.editMode,
                    },
                },
                panes: {
                    bookmarks: {
                        visible: builder.editMode,
                    },
                    filters: {
                        visible: builder.editMode,
                    },
                    pageNavigation: {
                        visible: false,
                    },
                },
            },
        };
    }
    return baseObject;
};

export default CreateReportEmbedConfig;
