import { Alert, Button, Form, Modal } from 'react-bootstrap';
import React, { FunctionComponent, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../../../../Shared/ApiHelper';
import { InformationFilled } from '@carbon/icons-react';
import { useMsal } from '@azure/msal-react';

interface MassApprovalModalProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    projectKey: number;
    onSuccess: () => void;
}

const MassApprovalModal: FunctionComponent<MassApprovalModalProps> = (props: MassApprovalModalProps) => {
    const { showModal, setShowModal, projectKey, onSuccess } = props;

    const [approvalThreshold, setApprovalThreshold] = useState(90);

    const [api] = useState(() => new ApiHelper());
    const { instance } = useMsal();

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Auto Review Clusters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="p-3">
                    <Alert variant="info">
                        <InformationFilled size={20} className="mr-2" />
                        Clusters with a confidence level above the specified value will be automatically approved, while
                        those with a confidence level lower than the specified value will be rejected
                    </Alert>
                    <Form.Group controlId="CategoryName" className="mb-4">
                        <Form.Label>Approval Threshold</Form.Label>
                        <Form.Control
                            as="input"
                            type="number"
                            placeholder="Enter Threshold..."
                            onChange={(event) => {
                                const newThreshold = parseInt(event.target.value);
                                setApprovalThreshold(newThreshold);
                            }}
                            value={approvalThreshold}
                            isInvalid={approvalThreshold > 100 || approvalThreshold < 50}
                            defaultValue={90}
                        />
                        <Form.Control.Feedback type="invalid">
                            Threshold must be a number between 50 and 100
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="link"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    disabled={!approvalThreshold || approvalThreshold > 100 || approvalThreshold < 50}
                    onClick={() => {
                        api.callApi(
                            instance,
                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                            process.env.REACT_APP_PYTHON_API_URL + `/clusters/auto/${projectKey}/${approvalThreshold}`,
                            'POST'
                        ).then(() => {
                            toast.success('Clusters have been auto reviewed succesfully', {
                                position: 'top-right',
                                autoClose: 5000,
                                transition: Slide,
                                draggable: false,
                                closeOnClick: false,
                                theme: 'colored',
                            });
                            onSuccess();
                        });

                        setShowModal(false);
                    }}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MassApprovalModal;
