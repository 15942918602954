import { Button, Col, Form, Row } from 'react-bootstrap';
import { ClientProfile, ClientProfilePayload } from './Models/EhrModels';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Slide, toast } from 'react-toastify';
import { View, ViewOff } from '@carbon/icons-react';
import { formatDate, formatDateTimeISO } from '../Shared/SharedHelpers';

import ApiHelper from '../Shared/ApiHelper';
import { CellContext } from '@tanstack/react-table';
import DataTable from '../Shared/components/DataTable';
import DatePicker from '../Shared/components/DatePicker';
import { Field } from '../Shared/components/Field/Field';
import Select from 'react-select';
import { SelectOption } from '../Shared/SharedModels';
import { createSchema } from '../Shared/Validations';
import styles from './client.module.css';
import useForm from '../Shared/hooks/useForm';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const validationSchema = createSchema(({ string }) => ({
    firstname: string().max(50),
    lastname: string().max(50),
    ssn: string().max(9),
}));

export const ClientSearch = (): ReactElement => {
    const api = useMemo(() => new ApiHelper(), []);
    const [shouldBlur, setShouldBlur] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOptions, setIsLoadingOptions] = useState(false);
    const navigate = useNavigate();
    const { instance } = useMsal();

    const { errors, register, handleSubmit, setValue, formState } = useForm({
        defaultValues: {} as ClientProfilePayload,
        schema: validationSchema,
    });
    const [filteredData, setFilteredData] = useState<ClientProfile[]>([]);

    const [statusOptions, setStatusOptions] = useState<{ [key: string]: Array<SelectOption<number>> }>({});

    const canFilter =
        Object.keys(formState).reduce((acc: number, curr: string) => {
            const validFields = ['firstname', 'lastname', 'dob', 'ssn'];
            if (validFields.includes(curr) && formState[curr as keyof ClientProfilePayload]) {
                acc += 1;
            }
            return acc;
        }, 0) > 1;

    const getOptionName = (property: string, value: number): string => {
        return statusOptions[property].find((item: SelectOption<number>) => item.value === (value ?? 99))?.label || '';
    };

    const transformOption = (options: Array<{ text: string; value: number }>): Array<SelectOption<number>> => {
        return options.map(({ text, value }) => ({ label: text, value }));
    };

    const getClientsData = async (): Promise<void> => {
        const clientsResponse = await api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_PYTHON_API_URL + `/search-client`,
            'POST',
            JSON.stringify({
                ...formState,
                dob: formState?.dob ? formatDateTimeISO(formState.dob) : null,
                gender: formState.gender?.value ?? null,
                race: formState.race?.value ?? null,
                veteranstatus: formState.veteranstatus?.value ?? null,
            })
        );

        const filteredClients: ClientProfile[] = await clientsResponse.json();

        if (filteredClients.length === 1) {
            if (filteredClients[0].canon_id) {
                return navigate(`/clients/${filteredClients[0].canon_id}/profile`);
            }
        }

        setFilteredData(filteredClients);
    };

    const getSelectData = useCallback(async (): Promise<void> => {
        setIsLoadingOptions(true);
        try {
            const gender = api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + `/reference/gender`,
                'GET'
            );

            const race = api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + `/reference/race`,
                'GET'
            );

            const veteranStatus = api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + `/reference/veteranstatus`,
                'GET'
            );

            const response = await Promise.all([gender, race, veteranStatus]);

            const options = await Promise.all(response.map((item) => item.json()));
            setStatusOptions({
                gender: transformOption(options[0]),
                race: transformOption(options[1]),
                veteranStatus: transformOption(options[2]),
            });
        } finally {
            setIsLoadingOptions(false);
        }
    }, [api, instance]);

    const onSubmit = handleSubmit(async (): Promise<void> => {
        if (!canFilter) {
            return;
        }
        try {
            setIsLoading(true);
            await getClientsData();
        } finally {
            setIsLoading(false);
        }
    });

    const handleAccessClientProfile = (client: ClientProfile): void => {
        if (client.canon_id) {
            navigate(`/clients/${client.canon_id}/profile`);
        } else {
            toast.warn('Client has not been linked to the Unique Client Index, unable to display profile', {
                position: 'top-right',
                autoClose: 5000,
                transition: Slide,
                draggable: false,
                closeOnClick: false,
                theme: 'colored',
            });
        }
    };

    useEffect(() => {
        getSelectData();
    }, [getSelectData]);

    return (
        <div className="content-column-container">
            <div className="content-header">
                <h1>Search for clients</h1>
            </div>
            <Form onSubmit={onSubmit}>
                <Row>
                    <Col>
                        <Field
                            {...register('firstname')}
                            label="First Name"
                            type="text"
                            errorMessage={errors?.firstname}
                        />
                    </Col>
                    <Col>
                        <Field
                            {...register('lastname')}
                            label="Last Name"
                            type="text"
                            errorMessage={errors?.lastname}
                        />
                    </Col>
                    <Col>
                        <span>DOB</span>
                        <DatePicker {...register('dob')} onChange={(date) => setValue('dob', date)} className="mt-2" />
                    </Col>
                    <Col>
                        <Field {...register('ssn')} label="SSN" type="text" errorMessage={errors?.ssn} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="data-table-filter">
                            <Form.Label>Gender</Form.Label>
                            <Select
                                isClearable
                                className="filterSelect"
                                closeMenuOnSelect={true}
                                isLoading={isLoadingOptions}
                                placeholder="Enter Status..."
                                {...register('gender')}
                                options={statusOptions.gender}
                                onChange={(selectedOptions) => {
                                    setValue('gender', selectedOptions);
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="data-table-filter">
                            <Form.Label>Race</Form.Label>
                            <Select
                                isClearable
                                className="filterSelect"
                                closeMenuOnSelect={true}
                                isLoading={isLoadingOptions}
                                {...register('race')}
                                options={statusOptions.race}
                                onChange={(selectedOptions) => {
                                    setValue('race', selectedOptions);
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="data-table-filter">
                            <Form.Label>Veteran Status</Form.Label>
                            <Select
                                isClearable
                                className="filterSelect"
                                closeMenuOnSelect={true}
                                {...register('veteranstatus')}
                                isLoading={isLoadingOptions}
                                options={statusOptions.veteranStatus}
                                onChange={(selectedOptions) => {
                                    setValue('veteranstatus', selectedOptions);
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={styles.searchRow}>
                    <span>
                        {canFilter
                            ? ''
                            : '* You must fill out at least two fields from First Name, Last Name, DOB and SSN to search clients.'}
                    </span>
                    <div>
                        <Button disabled={!canFilter} className="w-100" variant="primary" type="submit">
                            Search
                        </Button>
                    </div>
                </Row>
            </Form>
            <DataTable
                refreshData={getClientsData}
                data={filteredData}
                recordsAreClickable={true}
                columns={[
                    {
                        header: () => (
                            <Button
                                title="Show/hide client information"
                                variant="link"
                                onClick={() => {
                                    setShouldBlur((prev) => !prev);
                                }}
                            >
                                {shouldBlur ? <View size={24} /> : <ViewOff size={24} />}
                            </Button>
                        ),
                        id: 'blur',
                        enableSorting: false,
                    },
                    {
                        header: 'Name',
                        cell: ({ row }: CellContext<ClientProfile, unknown>) =>
                            `${row.original.firstname} ${row.original.lastname}`,
                    },
                    {
                        header: 'SSN',
                        accessorKey: 'ssn',
                        cell: ({ row }: CellContext<ClientProfile, unknown>) => {
                            return <span className={shouldBlur ? 'blur-filter' : ''}>{row.original.ssn}</span>;
                        },
                    },
                    {
                        header: 'DOB',
                        accessorKey: 'dob',
                        cell: ({ row }: CellContext<ClientProfile, unknown>) => {
                            return (
                                <span className={shouldBlur ? 'blur-filter' : ''}>
                                    {formatDate(new Date(row.original.dob))}
                                </span>
                            );
                        },
                    },
                    {
                        header: 'Gender',
                        accessorKey: 'gender',
                        cell: ({ row }: CellContext<ClientProfile, unknown>) =>
                            getOptionName('gender', row.original.gender),
                    },
                    {
                        header: 'Race',
                        accessorKey: 'race',
                        cell: ({ row }: CellContext<ClientProfile, unknown>) =>
                            getOptionName('race', row.original.race),
                    },
                    {
                        header: 'Veteran Status',
                        accessorKey: 'veteranstatus',
                        cell: ({ row }: CellContext<ClientProfile, unknown>) =>
                            getOptionName('veteranStatus', row.original.veteranstatus),
                    },
                ]}
                loading={isLoading}
                onRecordClick={handleAccessClientProfile}
            />
        </div>
    );
};
