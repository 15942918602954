import { ChevronDown, ChevronSort, ChevronUp } from '@carbon/icons-react';
import { Cluster, ClusterStatus } from '../../../DataToolsModels';
import { Header, flexRender } from '@tanstack/react-table';
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';

import ClusterReviewActions from './ClusterReviewActions';

interface ClusterReviewTableHeaderProps {
    data: Cluster[];
    updateClusterStatus: (row: number, status: ClusterStatus) => void;
    projectId: number;
    isAllRowsExpanded: boolean;
    toggleAllRowsExpanded: (expanded: boolean | undefined) => void;
    headers: Header<Cluster, unknown>[];
    setShowMassApprovalModal: Dispatch<SetStateAction<boolean>>;
    getTableCellClass: (value: string) => string;
}

const ClusterReviewTableHeader: FunctionComponent<ClusterReviewTableHeaderProps> = (
    props: ClusterReviewTableHeaderProps
) => {
    const {
        data,
        updateClusterStatus,
        projectId,
        isAllRowsExpanded,
        toggleAllRowsExpanded,
        headers,
        setShowMassApprovalModal,
        getTableCellClass,
    } = props;

    return (
        <thead>
            <ClusterReviewActions
                data={data}
                updateClusterStatus={updateClusterStatus}
                projectId={projectId}
                isAllRowsExpanded={isAllRowsExpanded}
                toggleAllRowsExpanded={toggleAllRowsExpanded}
                setShowMassApprovalModal={setShowMassApprovalModal}
            />
            <tr>
                {headers.map((header) => (
                    <th
                        key={'header-' + header.id}
                        className={getTableCellClass(header.id ?? '')}
                        onClick={() => header.column.getToggleSortingHandler()}
                    >
                        {header.id !== 'expander' && (
                            <>
                                {{
                                    asc: <ChevronUp size={24} className="sort-icon" />,
                                    desc: <ChevronDown size={24} className="sort-icon" />,
                                }[header.column.getIsSorted() as string] ?? <ChevronSort className="sort-icon" />}
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </>
                        )}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default ClusterReviewTableHeader;
