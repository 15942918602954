import { useEffect, useRef, useState } from 'react';

interface UseMenuReturn {
    isVisible: boolean;
    menuRef: React.RefObject<HTMLDivElement>;
    showMenu(): void;
    hideMenu(): void;
    toggleMenu(): void;
}

export default function useMenu(): UseMenuReturn {
    const menuRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    const showMenu = (): void => setIsVisible(true);
    const hideMenu = (): void => setIsVisible(false);
    const toggleMenu = (): void => setIsVisible(!isVisible);

    useEffect(() => {
        const handle = ({ target }: MouseEvent): void => {
            const { current } = menuRef;
            if (current !== target && !current?.contains(target as Node)) {
                setIsVisible(false);
            }
        };
        document.addEventListener('mousedown', handle);

        return () => document.removeEventListener('mousedown', handle);
    }, []);

    return {
        isVisible,
        menuRef,
        showMenu,
        hideMenu,
        toggleMenu,
    };
}
