import * as React from 'react';

import { Category, CategoryKey, CategoryOption, EmbededReportResponse } from './ReportModels';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import ApiHelper from '../Shared/ApiHelper';
import { Breadcrumb } from 'react-bootstrap';
import { ChevronRight } from '@carbon/icons-react';
import { Embed } from 'powerbi-client';
import EmbededReport from './EmbededReport';
import NavigationWarningModal from '../Shared/components/NavigationWarningModal';
import SaveReportModal from './SaveReportModal';
import { UserProfile } from '../Shared/SharedModels';
import { UserProfileContext } from '../Shared/contexts/UserProfile';
import { useMsal } from '@azure/msal-react';
import { useNavigationPrompt } from '../Shared/hooks/useNavigationPrompt';

export interface ReportAuthoringProps {
    isPublic?: boolean;
}

const ReportAuthoring: FunctionComponent<ReportAuthoringProps> = (props: ReportAuthoringProps) => {
    const { isPublic } = props;

    const navigate: NavigateFunction = useNavigate();
    const api = React.useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();
    const [accessToken, setAccessToken] = useState('');
    const [embedUrl, setEmbedUrl] = useState('');
    const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
    const [selectedCategoryOptionKeys, setSelectedCategoryOptionKeys] = useState<CategoryKey[]>([]);
    const [description, setDescription] = useState<string>('');
    const userProfile: UserProfile = useContext(UserProfileContext);
    const { datasetId } = useParams();

    const [report, setReport] = useState<Embed>();

    const [displaySaveReportModal, setDisplaySaveReportModal] = useState<boolean>(false); // Changed wording from show/setShow to display/setDisplay to attempt to reduce confusion.
    const [isSaveAs, setIsSaveAs] = useState<boolean>(false); // Perform differnet logic in SaveReportModal depending on this state
    const hmisPublicDatasetId = userProfile.environmentConfig.pbiConfig?.datasets.find(
        (x) => x.typeName === 'HMIS_PUBLIC'
    )?.datasetGuid;

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useNavigationPrompt(showDialog);

    useEffect(() => {
        setShowDialog(!isSaveAs);
    }, [isSaveAs]);

    useEffect(() => {
        const urlSuffix = isPublic ? `/${hmisPublicDatasetId}` : `/${datasetId}`;
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + `/PbiEmbed/author${isPublic ? '-public' : ''}${urlSuffix}`,
            'GET'
        ).then(async (result: Response) => {
            const embedResponse: EmbededReportResponse = await result.json();
            setAccessToken(embedResponse.embedToken.token);
            setEmbedUrl(embedResponse.embedReport[0].embedUrl);
        });

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/PbiReport/categories'
        ).then(async (result: Response) => {
            const cats: Category[] = await result.json();
            const mappedCats = cats.map((cats: Category) => {
                return {
                    label: cats.categoryName,
                    value: cats.categoryKey,
                };
            });
            setCategoryOptions(mappedCats);
        });
    }, [api, datasetId, hmisPublicDatasetId, instance, isPublic]);

    return (
        <div className="content-column-container">
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        navigate('/reports');
                    }}
                >
                    Reports
                </Breadcrumb.Item>
                <ChevronRight size={24} className="breadcrumb-chevron" />
                <Breadcrumb.Item active>Add Report</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Add Report</h4>

            <EmbededReport
                type="create"
                accessToken={accessToken}
                embedUrl={embedUrl}
                datasetId={isPublic ? hmisPublicDatasetId : datasetId}
                description={description}
                selectedCategoryOptionKeys={selectedCategoryOptionKeys}
                setDisplaySaveReportModal={setDisplaySaveReportModal}
                report={report}
                setReport={setReport}
                setIsSaveAs={setIsSaveAs}
                isPublic={!!isPublic}
            />

            <SaveReportModal
                showModal={displaySaveReportModal}
                setShowModal={setDisplaySaveReportModal}
                categoryOptions={categoryOptions}
                selectedCategoryOptionKeys={selectedCategoryOptionKeys}
                setSelectedCategoryOptionKeys={setSelectedCategoryOptionKeys}
                setDescription={setDescription}
                description={description}
                report={report}
                isSaveAs={isSaveAs}
                isPublic={isPublic}
            />

            <NavigationWarningModal
                showDialog={showPrompt as boolean}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />
        </div>
    );
};

export default ReportAuthoring;
