import './DataQualityAlerts.css';

import { Breadcrumb, Button, Form, Spinner } from 'react-bootstrap';
import { ChevronRight, Settings } from '@carbon/icons-react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import { DQAlertConfigViewModel } from './DataQualityModels';
import DataQualityAlertForm from './DataQualityAlertForm';
import Select from 'react-select';
import { SelectOption } from '../../Shared/SharedModels';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { useMsal } from '@azure/msal-react';

const DataQualityAlerts: FunctionComponent = () => {
    const [searchParams] = useSearchParams();
    const userProfile = useContext(UserProfileContext);
    const navigate: NavigateFunction = useNavigate();
    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    const [alertConfigurations, setAlertConfigurations] = useState<DQAlertConfigViewModel[]>([]);
    const [activeConfigData, setActiveConfigData] = useState<DQAlertConfigViewModel | undefined>(undefined);

    const [sourceSystemOptions, setSourceSystemOptions] = useState<SelectOption<number>[]>([]);
    const [selectedSourceSystem, setSelectedSourceSystem] = useState<SelectOption<number>>();
    const [dataElementOptions, setDataElementOptions] = useState<SelectOption<string>[]>([]);
    const [selectedDataElement, setSelectedDataElement] = useState<SelectOption<string>>();
    const [isCoC, setIsCoC] = useState<boolean>(false);
    const [startingSourceSystem] = useState(searchParams.get('startingSourceSystem'));

    const [isLoading, setIsLoading] = useState(false);

    const getConfigLabel = useCallback(
        (config: DQAlertConfigViewModel): string => {
            const configSourceSystem = userProfile.sourceSystems.find(
                (sourceSystem) => sourceSystem.sourceSystemKey === config.sourceSystemKey
            );
            return `${configSourceSystem?.sourceSystemShortName} (${config.isCoC ? 'CoC' : 'Non-CoC'}) - ${
                config.alertConfigName
            }`;
        },
        [userProfile.sourceSystems]
    );

    const getConfigs = useCallback(() => {
        setIsLoading(true);
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/DQAlertConfig'
        )
            .then(async (result: Response) => {
                const responseConfigs: DQAlertConfigViewModel[] = await result.json();

                setAlertConfigurations(responseConfigs);

                if (responseConfigs.length > 0) {
                    setSelectedDataElement({
                        label: responseConfigs[0].alertConfigName,
                        value: responseConfigs[0].alertConfigName,
                    });
                    setIsCoC(responseConfigs[0].isCoC);

                    const uniqueDataElements = [
                        ...new Set(
                            responseConfigs.map((config) => {
                                return config.alertConfigName;
                            })
                        ),
                    ];
                    const dataElements = uniqueDataElements.map((element) => {
                        return {
                            label: element,
                            value: element,
                        };
                    });
                    setDataElementOptions(dataElements);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [api, instance]);

    useEffect(() => {
        const sourceSystems = userProfile.sourceSystems
            .filter((system) => system.sourceSystemKey !== 0)
            .map((system) => {
                return {
                    label: system.sourceSystemName,
                    value: system.sourceSystemKey,
                };
            });
        setSourceSystemOptions(sourceSystems);

        const defaultSourceSystem: SelectOption<number> | undefined = sourceSystems.find(
            (system) => startingSourceSystem && system.value == parseInt(startingSourceSystem)
        );

        setSelectedSourceSystem(defaultSourceSystem ? defaultSourceSystem : sourceSystems[0]);

        getConfigs();
    }, [getConfigs, startingSourceSystem, userProfile.sourceSystems]);

    useEffect(() => {
        const newConfig = alertConfigurations.find((config) => {
            return (
                config.isCoC === isCoC &&
                config.alertConfigName === selectedDataElement?.value &&
                config.sourceSystemKey === selectedSourceSystem?.value
            );
        });

        setActiveConfigData(newConfig);
    }, [alertConfigurations, isCoC, selectedDataElement?.value, selectedSourceSystem?.value]);

    return (
        <div className="content-column-container">
            <div className="content-header">
                <Breadcrumb>
                    <Breadcrumb.Item
                        onClick={() => {
                            navigate('/admin');
                        }}
                    >
                        Configuration
                    </Breadcrumb.Item>
                    <ChevronRight size={24} className="breadcrumb-chevron" />
                    <Breadcrumb.Item active>Data Quality Alerts</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h1>Data Quality Alerts</h1>
            <p className="data-tools-description">
                Configure ClientInsight to send email alerts to desired users if any of the below data elements surpass
                your desired data quality thresholds
            </p>
            <Form>
                <div className="flex-row flex-wrap pt-3">
                    <Form.Group className="pr-3 pb-2">
                        <Form.Label>Source System</Form.Label>

                        <Select
                            isLoading={sourceSystemOptions.length === 0}
                            className="filterSelect recipients-input"
                            placeholder="Loading..."
                            value={selectedSourceSystem}
                            options={sourceSystemOptions}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setSelectedSourceSystem(selectedOption);
                                }
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="pr-3 pb-2">
                        <Form.Label>Data Element</Form.Label>

                        <Select
                            isLoading={dataElementOptions.length === 0}
                            className="filterSelect recipients-input"
                            placeholder="Loading..."
                            value={selectedDataElement}
                            options={dataElementOptions}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setSelectedDataElement(selectedOption);
                                }
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="pr-3 pb-2">
                        <Form.Label>CoC Funded</Form.Label>
                        <Form.Check
                            className="form-control-lg-centered"
                            type="switch"
                            label={isCoC ? 'CoC' : 'Non-CoC'}
                            checked={isCoC}
                            disabled={!activeConfigData}
                            onChange={(_event) => {
                                setIsCoC((currentValue) => !currentValue);
                            }}
                        />
                    </Form.Group>
                </div>
            </Form>
            {isLoading ? (
                <div className="flex-column">
                    <Spinner className="centered-spinner" variant="primary" size="sm" animation="border" />
                </div>
            ) : activeConfigData ? (
                <DataQualityAlertForm
                    activeConfigData={activeConfigData}
                    setActiveConfigData={setActiveConfigData}
                    setAlertConfigurations={setAlertConfigurations}
                    label={getConfigLabel(activeConfigData)}
                />
            ) : (
                <div className="missing-config-div">
                    <p className="data-tools-description">
                        Data Quality Alerts have not been configured for the selected source system, click to configure
                        alerts for this system
                    </p>
                    <Button
                        className="config-create-button"
                        variant="primary"
                        disabled={isLoading}
                        onClick={() => {
                            setIsLoading(true);
                            api.callApi(
                                instance,
                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                `${process.env.REACT_APP_NET_API_URL}/DQAlertConfig/${selectedSourceSystem?.value}`,
                                'POST'
                            ).then(() => {
                                getConfigs();
                            });
                        }}
                    >
                        <Settings size={24} className="button-icon-padding" /> Configure Alerts
                    </Button>
                </div>
            )}
        </div>
    );
};

export default DataQualityAlerts;
