import * as React from 'react';

import { Button, Form, ProgressBar } from 'react-bootstrap';

import { ClusterReviewCounts } from '../../../DataToolsModels';

interface ClusterReviewTableFooterProps {
    setPageSize: (pageSize: number) => void;
    pageSize: number;
    fetchData: (pageSize: number) => void;
    clusterCounts: ClusterReviewCounts | undefined;
}

const ClusterReviewTableFooter: React.FunctionComponent<ClusterReviewTableFooterProps> = (
    props: ClusterReviewTableFooterProps
) => {
    const { setPageSize, pageSize, fetchData, clusterCounts } = props;

    return (
        <div className="data-table-footer">
            <div className="footer-section">
                <ProgressBar
                    className="cluster-progress-bar mx-2"
                    min={0}
                    max={clusterCounts?.p_total ?? 100}
                    now={clusterCounts?.p_confirmed ?? 0}
                />
                <div className="footer-label">
                    {((clusterCounts?.p_confirmed ?? 0) / (clusterCounts?.p_total ?? 100)).toLocaleString(undefined, {
                        style: 'percent',
                        minimumFractionDigits: 0,
                    }) + ' '}
                    Complete
                </div>
            </div>
            <div className="footer-section">
                <div className="footer-label">Batch Size</div>
                <Form.Select
                    className="page-size"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setPageSize(Number(e.target.value));
                        fetchData(Number(e.target.value));
                    }}
                    defaultValue={10}
                >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                </Form.Select>
                <Button
                    variant="primary"
                    className="ml-3"
                    onClick={() => {
                        fetchData(pageSize);
                    }}
                >
                    Next Batch
                </Button>
            </div>
        </div>
    );
};

export default ClusterReviewTableFooter;
