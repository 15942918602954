import { Button, Spinner } from 'react-bootstrap';
import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../../Shared/ApiHelper';
import { TrashCan } from '@carbon/icons-react';
import { User } from '../TeamMemberModels';
import { UserRoles } from '../../Shared/SharedModels';
import { useMsal } from '@azure/msal-react';

interface DeleteUserButtonProps {
    user: User;
    setFormIsDirty: Dispatch<SetStateAction<boolean>>;
    setShowPanel: Dispatch<SetStateAction<boolean>>;
    setUserWasSaved: Dispatch<SetStateAction<boolean>>;
}

const DeleteUserButton: FunctionComponent<DeleteUserButtonProps> = ({
    user,
    setFormIsDirty,
    setShowPanel,
    setUserWasSaved,
}) => {
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (): Promise<void> => {
        try {
            setIsLoading(true);

            const userPayload = user;
            if (userPayload.role?.roleKey === UserRoles['Project User']) {
                userPayload.organizations = [];
            }

            await api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_NET_API_URL}/User`,
                'DELETE',
                JSON.stringify(userPayload)
            );

            toast.success('User has been deleted', {
                position: 'top-right',
                autoClose: 3000,
                transition: Slide,
                draggable: false,
                closeOnClick: false,
                theme: 'colored',
            });
        } finally {
            setIsLoading(false);
            setFormIsDirty(false);
            setShowPanel(false);
            setUserWasSaved(true);
        }
    };

    return (
        <Button className="mx-2" variant="outline-danger" disabled={isLoading} onClick={handleClick}>
            <div>
                {isLoading ? (
                    <Spinner
                        variant="primary"
                        animation="border"
                        style={{ width: '20px', height: '20px', margin: '0px 2px' }}
                    />
                ) : (
                    <TrashCan size={24} />
                )}
            </div>
            <span className="ml-2">Delete</span>
        </Button>
    );
};

export default DeleteUserButton;
