import React, { FunctionComponent, useContext } from 'react';

import { Button } from 'react-bootstrap';
import { HelpFilled } from '@carbon/icons-react';
import { UserProfileContext } from '../../../Shared/contexts/UserProfile';

interface ProjectContentHeaderProps {
    activeStep?: number;
    header: string;
}

const ProjectContentHeader: FunctionComponent<ProjectContentHeaderProps> = (props: ProjectContentHeaderProps) => {
    const { activeStep, header } = props;

    const userProfile = useContext(UserProfileContext);

    const contextAnchor = document?.title?.replaceAll
        ? '#' + document?.title?.replaceAll(': ', '_').replaceAll(' ', '_')
        : '';

    return (
        <div className="project-card-header">
            <h3>{`${activeStep ? activeStep + '. ' : ''}${header}`}</h3>
            <Button
                variant="link"
                className="project-help-button"
                href={process.env.REACT_APP_HELP_URL + '?' + userProfile.cdnToken + contextAnchor}
                target="_blank"
            >
                <HelpFilled size={24} className="button-icon-padding" />
                Learn how this works
            </Button>
        </div>
    );
};

export default ProjectContentHeader;
