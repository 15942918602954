/* eslint-disable @typescript-eslint/ban-types */

import './DataTable.css';

import { Button, Form, Pagination, Spinner, Table } from 'react-bootstrap';
import { ChevronDown, ChevronLeft, ChevronRight, ChevronSort, ChevronUp, Renew } from '@carbon/icons-react';
import {
    ColumnDef,
    RowData,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import React, { Dispatch, ReactElement, ReactNode, SetStateAction, useEffect, useState } from 'react';

import Collapse from './Collapse';

export type ColumnBreakpoints = { [column: string]: 'sm' | 'md' | 'lg' | 'xl' | '2xl' };

interface DataTableProps<T extends Object> {
    data: T[];
    setData?: (data: T[] | ((oldData: T[]) => T[])) => void;
    columns: ColumnDef<T>[];
    expandedContent?(data: T, isExpanded: boolean): React.ReactNode;
    loading: boolean;
    fetchData?: (pageSize: number, pageIndex: number) => void;
    refreshData?: () => void;
    onRecordClick?: (record: T) => void;
    recordsAreClickable?: boolean;
    label?: string;
    hasTopRadius?: boolean;
    columnBreakpoints?: ColumnBreakpoints;
    recordKey?: keyof T;
    selectedRecords?: T[];
    setSelectedRecords?: Dispatch<SetStateAction<T[]>>;
}

declare module '@tanstack/table-core' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface TableMeta<TData extends RowData> {
        updateData?: (rowIndex: number, columnId: string, value: unknown) => void;
    }
}

function DataTable<T extends Object>(props: DataTableProps<T>): ReactElement {
    const {
        data,
        setData,
        columns,
        expandedContent,
        loading,
        fetchData,
        refreshData,
        onRecordClick,
        recordsAreClickable,
        label = 'Records',
        hasTopRadius = true,
        columnBreakpoints,
        recordKey,
        selectedRecords,
        setSelectedRecords,
    } = props;
    const updateData = (rowIndex: number, columnId: string, value: unknown): void => {
        setData &&
            setData((old: T[]) =>
                old.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            [columnId]: value,
                        };
                    }
                    return row;
                })
            );
    };

    const table = useReactTable({
        columns: columns,
        data: data,
        autoResetPageIndex: false,
        enableMultiSort: false,
        autoResetAll: false,
        enableExpanding: true,
        enableRowSelection: !!setSelectedRecords,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        meta: {
            updateData,
        },
    });

    const [paginationButtons, setPaginationButtons] = useState<ReactNode[]>([]);

    const getTableCellClass = (columnName: string): string =>
        columnBreakpoints?.[columnName] ? `hidden ${columnBreakpoints[columnName]}:table-cell` : '';

    useEffect(() => {
        fetchData && fetchData(table.getState().pagination.pageSize, table.getState().pagination.pageIndex);
    }, [fetchData, table]);

    // Extracting pagination state into variable to be used in hook dependency array
    const paginationState = table.getState().pagination;
    useEffect(() => {
        const pageButtons: ReactNode[] = [];

        if (table.getState().pagination.pageIndex > 2) {
            pageButtons.push(
                <Pagination.Item
                    onClick={() => {
                        table.setPageIndex(0);
                    }}
                    active={table.getState().pagination.pageIndex === 0}
                    key="first-page-button"
                >
                    {1}
                </Pagination.Item>
            );
        }
        if (table.getState().pagination.pageIndex > 3) {
            pageButtons.push(<Pagination.Ellipsis key="pagination-elipsis-beginning" disabled />);
        }

        for (let i = 0; i < table.getPageCount(); i++) {
            if (Math.abs(i - table.getState().pagination.pageIndex) <= 2) {
                pageButtons.push(
                    <Pagination.Item
                        onClick={() => {
                            table.setPageIndex(i);
                        }}
                        active={table.getState().pagination.pageIndex === i}
                        key={'page-button-' + i}
                    >
                        {i + 1}
                    </Pagination.Item>
                );
            }
        }

        if (table.getPageCount() - 1 - table.getState().pagination.pageIndex > 3) {
            pageButtons.push(<Pagination.Ellipsis key="pagination-elipsis-end" disabled />);
        }
        if (table.getPageCount() - 1 - table.getState().pagination.pageIndex > 2) {
            pageButtons.push(
                <Pagination.Item
                    onClick={() => {
                        table.setPageIndex(table.getPageCount() - 1);
                    }}
                    active={table.getState().pagination.pageIndex === table.getPageCount() - 1}
                    key="last-page-button"
                >
                    {table.getPageCount()}
                </Pagination.Item>
            );
        }

        setPaginationButtons(pageButtons);
    }, [table, paginationState, data.length]);

    // Maintains state of selected records when filtering
    useEffect(() => {
        if (recordKey && selectedRecords) {
            selectedRecords.forEach((selectedRecord) => {
                const foundRow = table
                    .getRowModel()
                    .rows.find((row) => row.original[recordKey] === selectedRecord[recordKey]);

                if (foundRow) {
                    foundRow.toggleSelected(true);
                }
            });
        }
    }, [recordKey, selectedRecords, data, table]);

    return (
        <div className={hasTopRadius ? 'table-container' : 'table-container-without-top-radius'}>
            <Table>
                <thead>
                    <tr>
                        {refreshData && (
                            <th>
                                <Button variant="link" title="Refresh table data" onClick={refreshData}>
                                    <Renew size={24} />
                                </Button>
                            </th>
                        )}
                        {!refreshData && selectedRecords && <th />}
                        {table.getFlatHeaders().map((header) => (
                            <th
                                key={'header-' + header.id}
                                className={`${getTableCellClass(header.id)}${
                                    header.column.getCanSort() ? ' clickable' : ' unset-width'
                                }`}
                                onClick={header.column.getToggleSortingHandler()}
                            >
                                {header.column.getCanSort() && (
                                    <>
                                        {{
                                            asc: <ChevronUp size={24} className="sort-icon" />,
                                            desc: <ChevronDown size={24} className="sort-icon" />,
                                        }[header.column.getIsSorted() as string] ?? (
                                            <ChevronSort size={24} className="sort-icon" />
                                        )}
                                    </>
                                )}
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                        ))}
                    </tr>
                </thead>
                {!loading && data.length ? (
                    <tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <React.Fragment key={'row-' + row.index}>
                                    <tr
                                        onClick={(_event: React.MouseEvent) => {
                                            if (onRecordClick) {
                                                onRecordClick(row.original);
                                            }
                                        }}
                                        className={`${recordsAreClickable ? 'navigatable-row' : ''} ${
                                            expandedContent ? 'expanded-row' : ''
                                        }`}
                                    >
                                        {refreshData && !selectedRecords && <td />}
                                        {selectedRecords && setSelectedRecords && recordKey && (
                                            <td>
                                                <input
                                                    className="row-selection"
                                                    type="checkbox"
                                                    checked={row.getIsSelected()}
                                                    onChange={() => {
                                                        if (!row.getIsSelected()) {
                                                            setSelectedRecords((prev) => {
                                                                return [...prev, row.original];
                                                            });
                                                        } else {
                                                            setSelectedRecords((prev) => {
                                                                return prev.filter(
                                                                    (record) =>
                                                                        record[recordKey] !== row.original[recordKey]
                                                                );
                                                            });
                                                        }

                                                        row.getToggleSelectedHandler();
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </td>
                                        )}
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td
                                                    key={'cell-' + cell.column.columnDef.header + '-' + row.index}
                                                    className={getTableCellClass(cell.column.id)}
                                                >
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    {expandedContent ? (
                                        <tr
                                            className="expanded-row"
                                            style={{ borderTop: !row.getIsExpanded() ? 'none' : '' }}
                                        >
                                            <td
                                                colSpan={table.getVisibleFlatColumns().length}
                                                style={{ padding: '0px' }}
                                            >
                                                <Collapse isOpen={row.getIsExpanded()}>
                                                    {expandedContent(row.original, row.getIsExpanded())}
                                                </Collapse>
                                            </td>
                                        </tr>
                                    ) : null}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="table__empty-row">
                            {loading ? (
                                <td
                                    colSpan={
                                        refreshData || setSelectedRecords
                                            ? table.getFlatHeaders().length + 1
                                            : table.getFlatHeaders().length
                                    }
                                    className="spinner-container"
                                >
                                    <Spinner className="table-spinner" variant="primary" size="sm" animation="border" />
                                </td>
                            ) : (
                                <td
                                    colSpan={
                                        refreshData || setSelectedRecords
                                            ? table.getFlatHeaders().length + 1
                                            : table.getFlatHeaders().length
                                    }
                                    className="table__empty-text"
                                >
                                    No {label} Found
                                </td>
                            )}
                        </tr>
                    </tbody>
                )}
            </Table>
            {!!data.length && (
                <div className="data-table-footer">
                    <div className="footer-section">
                        <div className="footer-label">Results Per Page</div>
                        <Form.Select
                            className="page-size"
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                table.setPageSize(Number(e.target.value));
                            }}
                            defaultValue={10}
                        >
                            <option>5</option>
                            <option>10</option>
                            <option>25</option>
                        </Form.Select>
                    </div>
                    <div className="footer-section">
                        <div className="footer-label">
                            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                        </div>
                        <Pagination>
                            <Pagination.Prev
                                onClick={() => {
                                    table.previousPage();
                                }}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <ChevronLeft size={24} />
                            </Pagination.Prev>
                            {paginationButtons}
                            <Pagination.Next
                                onClick={() => {
                                    table.nextPage();
                                }}
                                disabled={!table.getCanNextPage()}
                            >
                                <ChevronRight size={24} />
                            </Pagination.Next>
                        </Pagination>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DataTable;
