export interface Batch {
    batchKey: number;
    batchName: string;
    sourceExportId: string;
    customExportId?: string | null;
    sourceSystemKey: number;
    startTime: string;
    endTime: string;
    resultStatus: boolean | null;
    errorDetail?: string | null;
    batchTables: BatchTable[];
    zipFileName: string | null;
    processedFileName: string | null;
}

export interface SourceSystem {
    sourceSystemKey: number;
    sourceSystemShortName: string;
    sourceSystemName: string;
    isClientTrack: boolean;
    schemaName: string;
}

export interface BatchTable {
    batchTableKey: number;
    batchKey: number;
    tableName: string;
    startTime: string;
    endTime: string;
    updatedRows: number;
    insertedRows: number;
    errorDetail?: string | null;
    resultStatus: boolean;
    sourceCsvRowCount: number;
}

export interface BatchRunStage {
    error: number;
    name: string;
    processed: number;
    processing: number;
    stage: number;
}

export enum BatchStatus {
    ERROR = 'error',
    PROCESSED = 'processed',
    PROCESSING = 'processing',
}

export interface SourceSystemRegister {
    batches: Batch[];
    sourceSystem: SourceSystem;
}
