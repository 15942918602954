import { Button, Spinner } from 'react-bootstrap';
import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { ReportMetadata, ReportStage } from './ReportModels';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../Shared/ApiHelper';
import { DocumentTasks } from '@carbon/icons-react';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';

interface ReportPublishButtonProps {
    reportMetadata: ReportMetadata;
    setReportMetadata: Dispatch<SetStateAction<ReportMetadata | undefined>>;
    createdByUser: boolean;
}

const ReportPublishButton: FunctionComponent<ReportPublishButtonProps> = (props: ReportPublishButtonProps) => {
    const { reportMetadata, setReportMetadata, createdByUser } = props;

    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());
    const { reportId } = useParams();

    const [isPublishing, setIsPublishing] = useState(false);

    return (
        <Button
            variant="outline-primary"
            disabled={isPublishing}
            onClick={() => {
                if (reportMetadata.type !== 'Public' || (reportMetadata.type === 'Public' && !createdByUser)) {
                    setIsPublishing(true);
                    api.callApi(
                        instance,
                        [process.env.REACT_APP_B2C_SCOPE ?? ''],
                        process.env.REACT_APP_NET_API_URL + `/PbiReport/${reportId}/publish`,
                        'PUT'
                    ).then(async (_result: Response) => {
                        setReportMetadata((prevMetadata: ReportMetadata | undefined) => {
                            if (prevMetadata) {
                                return { ...prevMetadata, stage: ReportStage.Published };
                            }
                        });
                        setIsPublishing(false);

                        toast.success('Report has been published to the community workspace', {
                            position: 'top-right',
                            autoClose: 5000,
                            transition: Slide,
                            draggable: false,
                            closeOnClick: false,
                            theme: 'colored',
                        });
                    });
                } else {
                    setIsPublishing(true);
                    api.callApi(
                        instance,
                        [process.env.REACT_APP_B2C_SCOPE ?? ''],
                        process.env.REACT_APP_NET_API_URL + `/PbiReport/${reportId}/submit`,
                        'PUT'
                    ).then(async (_result: Response) => {
                        setReportMetadata((prevMetadata: ReportMetadata | undefined) => {
                            if (prevMetadata) {
                                return { ...prevMetadata, stage: ReportStage['In Review'] };
                            }
                        });
                        setIsPublishing(false);

                        toast.success('Report has been submitted for review, you may resubmit again later if needed', {
                            position: 'top-right',
                            autoClose: 5000,
                            transition: Slide,
                            draggable: false,
                            closeOnClick: false,
                            theme: 'colored',
                        });
                    });
                }
            }}
        >
            {isPublishing ? (
                <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
            ) : (
                <DocumentTasks size={24} className="button-icon-padding" />
            )}
            {createdByUser && reportMetadata.type !== 'Public' && 'Publish Report'}
            {!createdByUser && reportMetadata.stage === ReportStage['In Review'] && 'Approve Report'}
            {createdByUser && reportMetadata.type === 'Public' && 'Submit for Approval'}
        </Button>
    );
};

export default ReportPublishButton;
