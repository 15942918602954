import { DateProp, formatDate, isValidDate } from '../../Shared/SharedHelpers';

import { EhrEncounter } from '../Models/EhrModels';
import { HmisSummary } from '../Models/HmisModels';
import { ServiceCard } from '../../Shared/contexts/CardsProvider';
import { ServiceSummaryCardItem } from './ServiceSummaryCard';
import { matchIconToColumn } from '../../Shared/Client360Icons';
import startCase from 'lodash/startCase';

interface Option {
    label: string;
    value: string;
}

interface FormatServiceToCardInfoReturn {
    items: ServiceSummaryCardItem[];
    title: string;
}

export function formatServiceToCardInfo(
    serviceCard: Partial<ServiceCard>,
    recentService?: EhrEncounter | HmisSummary
): FormatServiceToCardInfoReturn {
    const getItems = (): ServiceSummaryCardItem[] => {
        if (!serviceCard.serviceColumns || !recentService) {
            return [];
        }

        return serviceCard.serviceColumns.map((column) => {
            const options = formatFieldToOption(column, recentService[column as keyof typeof recentService]);

            return {
                title: `Most recent ${startCase(column)}`,
                Icon: matchIconToColumn(column),
                options: Array.isArray(options) ? options : [options],
            };
        });
    };

    const getTitle = (): string => {
        if (!serviceCard?.service) {
            return '';
        }
        return serviceCard?.service === 'hmis'
            ? 'Homeless Management Information System (HMIS)'
            : 'Electronic Health Record (EHR)';
    };

    return {
        items: getItems(),
        title: getTitle(),
    };
}

function formatOptionValue(value: DateProp): string {
    return isValidDate(value) ? formatDate(value) : (value as string);
}

function formatFieldToOption(label: string, value?: unknown): Option | Option[] | Option[][] {
    if (!value) {
        return { label: startCase(label), value: '' };
    }

    if (Array.isArray(value)) {
        return value.map((data) => formatFieldToOption(label, data)) as Option[][];
    }

    if (typeof value === 'object') {
        return Object.keys(value).map((key) => formatFieldToOption(key, value[key as keyof typeof value])) as Option[];
    }

    return { label: startCase(label), value: formatOptionValue(value as DateProp) };
}
