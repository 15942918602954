import { Container, Row } from 'react-bootstrap';
import React, { FunctionComponent, useContext } from 'react';
import { UserProfileContext, isGlobalAdmin } from '../Shared/contexts/UserProfile';

import ProjectCard from '../Shared/components/ProjectCard';
import { Settings } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';

const Admin: FunctionComponent = () => {
    const navigate = useNavigate();
    const userProfile = useContext(UserProfileContext);

    return (
        <div className="content-column-container">
            <div className="content-header">
                <h1>Configuration</h1>
            </div>

            <p className="data-tools-description">Configure ClientInsight to meet the needs of your organization</p>
            <Container fluid className="mt-4">
                <Row>
                    <ProjectCard
                        title="Report Categories"
                        body="Add, edit, or delete custom categories that can be applied to your reports for easier sorting and organization"
                        projectType="Category"
                        onClick={() => {
                            navigate('reportcategories');
                        }}
                    >
                        <Settings size={24} className="button-icon-padding" /> Configure
                    </ProjectCard>
                    <ProjectCard
                        title="Data Quality Alerts"
                        body="Configure data quality thresholds for sending email alerts to desired recipients when your data does not meet your standards"
                        projectType="Alert"
                        onClick={() => {
                            navigate('dataqualityalerts');
                        }}
                    >
                        <Settings size={24} className="button-icon-padding" /> Configure
                    </ProjectCard>
                    <ProjectCard
                        title="Source Systems"
                        body="Add, activate, or inactivate data sources to manage which providers are able to upload to the system"
                        projectType="SourceSystems"
                        onClick={() => {
                            navigate('sourcesystems');
                        }}
                    >
                        <Settings size={24} className="button-icon-padding" /> Configure
                    </ProjectCard>
                    {isGlobalAdmin(userProfile) && (
                        <ProjectCard
                            title="Projects"
                            body="View project data, global admins may also delete projects in the case that invalid data has been uploaded"
                            projectType="Project"
                            onClick={() => {
                                navigate('projects');
                            }}
                        >
                            <Settings size={24} className="button-icon-padding" /> Configure
                        </ProjectCard>
                    )}
                    {isGlobalAdmin(userProfile) && (
                        <ProjectCard
                            title="Public Report Reviewers"
                            body="Configure users that will recieve notifications to review reports authored for public use before publishing"
                            projectType="Link"
                            onClick={() => {
                                navigate('publicreportreviews');
                            }}
                        >
                            <Settings size={24} className="button-icon-padding" /> Configure
                        </ProjectCard>
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default Admin;
