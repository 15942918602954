import React, { FunctionComponent, useEffect, useState } from 'react';
import Select, { ThemeConfig } from 'react-select';
import { SelectOption, SourceSystem, UserRoles } from '../../Shared/SharedModels';

import ApiHelper from '../../Shared/ApiHelper';
import { Form } from 'react-bootstrap';
import { User } from '../TeamMemberModels';
import { sortSelectOptions } from '../../Shared/SharedHelpers';
import { useFormContext } from '../../Shared/contexts/FormProvider';
import { useMsal } from '@azure/msal-react';

interface SourceSystemSelectProps {
    formTheme: ThemeConfig;
    editEnabledForAdmin: boolean;
}

const SourceSystemSelect: FunctionComponent<SourceSystemSelectProps> = (props) => {
    const { formTheme, editEnabledForAdmin } = props;

    const {
        errors,
        formState: { role, sourceSystems },
        register,
        setValue,
    } = useFormContext<User>();

    const areSourceSystemsInvalid = !!errors?.sourceSystems;

    const [sourceSystemOptions, setSourceSystemOptions] = useState<SelectOption<SourceSystem>[]>([]);

    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/sourcesystems/false'
        ).then(async (result: Response) => {
            const sources = await result.json();
            const mappedSources = sources
                .filter((source: SourceSystem) => source.isActive)
                .map((source: SourceSystem) => {
                    return {
                        label: source.sourceSystemName,
                        value: source,
                    } as SelectOption<SourceSystem>;
                })
                .sort(sortSelectOptions);
            setSourceSystemOptions(mappedSources);
        });
    }, [api, instance]);

    return (
        <Form.Group className="mb-4">
            <Form.Label>Source Systems</Form.Label>

            <Select
                {...register('sourceSystems')}
                isDisabled={
                    !editEnabledForAdmin ||
                    !role ||
                    role?.roleKey === UserRoles['Global Admin'] ||
                    role?.roleKey === UserRoles['Org Admin'] ||
                    role?.roleKey === UserRoles['Member'] ||
                    role?.roleKey === UserRoles['Project User']
                }
                isMulti
                closeMenuOnSelect={false}
                options={sourceSystemOptions}
                placeholder="Select Source Systems..."
                value={sourceSystems?.map((source: SourceSystem) => {
                    return {
                        label: source.sourceSystemName,
                        value: source,
                    };
                })}
                theme={formTheme}
                onChange={(selectedOptions) =>
                    setValue(
                        'sourceSystems',
                        selectedOptions.map((option) => option.value)
                    )
                }
                className={areSourceSystemsInvalid ? 'is-invalid' : ''}
                classNamePrefix="base-select"
                menuPlacement="auto"
            />
            <Form.Control.Feedback type="invalid">
                {typeof errors?.sourceSystems === 'string' && errors?.sourceSystems}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default SourceSystemSelect;
