/* eslint-disable react-hooks/rules-of-hooks */

import './BatchDetails.css';

import { Batch, BatchTable } from './BatchRunModels';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import React, { Dispatch, SetStateAction, useContext } from 'react';

import { Alert } from 'react-bootstrap';
import BatchBadge from './BatchBadge';
import BatchRunBreadCrumb from './BatchRunBreadCrumb';
import DataTable from '../../Shared/components/DataTable';
import ExpandedChevronIcon from '../../Shared/components/ExpandedChevronIcon';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { formatDateString } from '../../Shared/SharedHelpers';
import styles from './BatchDetails.module.css';

interface BatchDetails {
    batch: Batch;
    setSelectedBatchRun: Dispatch<SetStateAction<Batch | undefined>>;
    setSelectedSourceSystemKey: Dispatch<SetStateAction<number | null>>;
    sourceSystemName: string;
}

const BatchDetails: React.FC<BatchDetails> = ({
    batch,
    setSelectedBatchRun,
    setSelectedSourceSystemKey,
    sourceSystemName,
}) => {
    const { batchTables } = batch;

    return (
        <>
            <div className={styles.container}>
                <BatchRunBreadCrumb
                    setSelectedSourceSystemKey={setSelectedSourceSystemKey}
                    setSelectedBatchRun={setSelectedBatchRun}
                    sourceSystemName={sourceSystemName}
                    batchName={batch.batchName}
                />

                <div className={styles.content}>
                    <h4 className={styles.title}>{batch.batchName}</h4>

                    <div>
                        <span className={styles.label}>Batch Status: </span>
                        <BatchBadge status={batch.resultStatus} />
                    </div>

                    {!!batch.zipFileName && (
                        <div>
                            <span className={styles.label}>Zip File Name: </span>
                            <span className={styles.text}>{batch.zipFileName}</span>
                        </div>
                    )}
                    {!!batch.processedFileName && (
                        <div>
                            <span className={styles.label}>Processed File Name: </span>
                            <span className={styles.text}>{batch.processedFileName}</span>
                        </div>
                    )}
                    {!!batch.errorDetail && (
                        <Alert variant="danger" className="batch-details__alert mt-3">
                            <b>Error: </b> {batch.errorDetail}
                        </Alert>
                    )}
                </div>
            </div>

            <DataTable
                label="batch runs"
                hasTopRadius={false}
                data={batchTables}
                columns={tableColumns}
                loading={false}
                expandedContent={(data) =>
                    !!data.errorDetail && (
                        <Alert variant="danger" className="batch-details__alert m-4">
                            <b>Error: </b> {data.errorDetail}
                        </Alert>
                    )
                }
            />
        </>
    );
};

export default BatchDetails;

const tableColumns: ColumnDef<BatchTable>[] = [
    {
        header: () => null,
        id: 'expander',
        enableSorting: false,
        cell: ({ row }: CellContext<BatchTable, unknown>) =>
            row.original.errorDetail ? (
                <ExpandedChevronIcon isOpen={row.getIsExpanded()} onClick={() => row.toggleExpanded()} />
            ) : (
                ''
            ),
    },
    { header: 'Table Name', accessorKey: 'tableName' },
    { header: 'Record Count', accessorKey: 'sourceCsvRowCount' },
    {
        header: 'Start',
        accessorKey: 'startTime',
        cell: ({ row }: CellContext<BatchTable, unknown>) => {
            const userProfile = useContext(UserProfileContext);
            return formatDateString(row.original.startTime, userProfile.environmentConfig.timeZone);
        },
    },
    {
        header: 'End',
        accessorKey: 'endTime',
        cell: ({ row }: CellContext<BatchTable, unknown>) => {
            const userProfile = useContext(UserProfileContext);
            return formatDateString(row.original.endTime, userProfile.environmentConfig.timeZone);
        },
    },
    {
        header: 'Status',
        accessorKey: 'resultStatus',
        cell: ({ row }: CellContext<BatchTable, unknown>) => <BatchBadge status={row.original.resultStatus} />,
        sortingFn: (rowA: Row<BatchTable>, rowB: Row<BatchTable>) => {
            const rowAIsGreater = rowA.original.resultStatus && !rowB.original.resultStatus;

            return rowAIsGreater ? -1 : 1;
        },
    },
];
