import { format, formatISO, isValid, parseISO } from 'date-fns';

import { SelectOption } from './SharedModels';
import moment from 'moment-timezone';

export type DateProp = Date | string;

export function GetDisplaySsn(ssn?: string): string {
    let displaySsn = '-';

    if (ssn && ssn.length >= 9 && !ssn.includes('-')) {
        displaySsn = ssn.substring(0, 3) + '-' + ssn.substring(3, 5) + '-' + ssn.substring(5);
    }

    return displaySsn;
}

export function GetDisplayDate(date?: string): string {
    let displayDate = '-';

    if (date) {
        displayDate = new Date(date).toLocaleDateString(undefined, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });
    }

    return displayDate;
}

export const getDate = (date: DateProp): Date => (typeof date === 'string' ? parseISO(date) : date);

export function formatDateString(inputDateString: string, timeZone: string | undefined): string {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timeZone,
    };

    if (!inputDateString.includes('+')) {
        inputDateString += '+0000';
    }

    const displayString = new Intl.DateTimeFormat('en-US', options).format(getDate(inputDateString));
    let formattedTimezone = '';
    if (timeZone != undefined) formattedTimezone = moment().tz(timeZone).format('z');

    return displayString + ' ' + formattedTimezone;
}

export function formatDate(date: DateProp): string {
    const dateFormat = 'MM/dd/yyyy';

    return format(getDate(date), dateFormat);
}

export function sortSelectOptions<T>(a: SelectOption<T>, b: SelectOption<T>): number {
    return a.label > b.label ? 1 : -1;
}

export function formatDateISO(date: DateProp): string {
    return formatISO(getDate(date), { representation: 'date' });
}

export function formatDateTimeISO(date: DateProp): string {
    return formatISO(getDate(date), { representation: 'complete' });
}

export function isValidDate(date: DateProp): boolean {
    return isValid(getDate(date));
}

export function setCookie(name: string, value: string, expires = 1): void {
    const date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * expires);

    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
}

export function downloadUrl(url: string, filename: string): void {
    const element = document.createElement('a');

    element.href = url;
    element.download = filename;

    document.body.appendChild(element);
    element.click();
    element.remove();
}

export const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function getContrastColor(hexColor: string, darkColor = '#000000', lightColor = '#ffffff'): string | undefined {
    if (!hexColor.trim()) {
        return undefined;
    }

    if (hexColor[0] === '#') {
        hexColor = hexColor.slice(1);
    }

    if (hexColor.length === 3) {
        hexColor = hexColor
            .split('')
            .map((hex) => hex + hex)
            .join('');
    }

    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    return (red * 299 + green * 587 + blue * 114) / 1000 >= 128 ? darkColor : lightColor;
}
