import React, { FunctionComponent, useEffect, useState } from 'react';
import Select, { ThemeConfig } from 'react-select';
import { SelectOption, UserRoles } from '../../Shared/SharedModels';
import { User, UserRole } from '../TeamMemberModels';

import ApiHelper from '../../Shared/ApiHelper';
import { Form } from 'react-bootstrap';
import { useFormContext } from '../../Shared/contexts/FormProvider';
import { useMsal } from '@azure/msal-react';

interface RoleSelectProps {
    formTheme: ThemeConfig;
    editEnabledForAdmin: boolean;
}

const RoleSelect: FunctionComponent<RoleSelectProps> = ({ formTheme, editEnabledForAdmin }) => {
    const {
        errors,
        formState: { role },
        register,
        setValues,
        validateForm,
    } = useFormContext<User>();

    const hasError = !!errors?.role;

    const [roleOptions, setRoleOptions] = useState<SelectOption<UserRole>[]>([
        { label: 'Member', value: { description: 'Member', name: 'Member', roleKey: 40 } },
    ]);

    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/roles'
        ).then(async (result: Response) => {
            const roles: UserRole[] = await result.json();
            const mappedRoles = roles
                .sort((roleA: UserRole, roleB: UserRole) => roleA.roleKey - roleB.roleKey)
                .map((role: UserRole) => {
                    return {
                        label: role.name,
                        value: role,
                    } as SelectOption<UserRole>;
                });
            setRoleOptions(mappedRoles);
        });
    }, [api, instance]);

    return (
        <Form.Group className="mb-4">
            <Form.Label>Role</Form.Label>

            <Select
                {...register('role')}
                options={roleOptions}
                value={role ? [{ label: role?.name, value: role }] : undefined}
                theme={formTheme}
                aria-invalid={!role}
                placeholder="Select Role..."
                isDisabled={!editEnabledForAdmin}
                onChange={(selectedOption) => {
                    if (selectedOption) {
                        const newRoleKey = selectedOption.value?.roleKey;

                        setValues((prev) => {
                            return {
                                ...prev,
                                role: selectedOption.value,
                                roleKey: selectedOption.value.roleKey,
                                sourceSystems:
                                    newRoleKey === UserRoles['Global Admin'] ||
                                    newRoleKey === UserRoles['Org Admin'] ||
                                    newRoleKey === UserRoles['Member']
                                        ? []
                                        : prev.sourceSystems,
                                organizations:
                                    newRoleKey === UserRoles['Global Admin'] ||
                                    newRoleKey === UserRoles['Source System Admin']
                                        ? []
                                        : prev.organizations,
                                projects: newRoleKey === UserRoles['Project User'] ? prev.projects : [],
                            };
                        });
                    }
                }}
                onBlur={validateForm}
                className={hasError ? 'is-invalid' : ''}
                classNamePrefix="base-select"
                menuPlacement="auto"
            />
            <Form.Control.Feedback type="invalid">{errors?.role}</Form.Control.Feedback>
        </Form.Group>
    );
};

export default RoleSelect;
