import { ChevronDown, UserAvatarFilled } from '@carbon/icons-react';
import { Dropdown, NavLink } from 'react-bootstrap';
import React, { FunctionComponent, useContext, useState } from 'react';
import { UserProfileContext, isSourceSystemAdminOrHigher } from '../../Shared/contexts/UserProfile';

import ApiHelper from '../../Shared/ApiHelper';
import DropdownNavItem from './DropdownNavItem';
import { useMsal } from '@azure/msal-react';

const UserDropdown: FunctionComponent = () => {
    const { instance, accounts } = useMsal();
    const [api] = useState(new ApiHelper());
    const userProfile = useContext(UserProfileContext);

    return (
        <Dropdown>
            <Dropdown.Toggle as={DropdownNavItem}>
                <NavLink id="userDropdown" data-testid="userDropdown">
                    <UserAvatarFilled size={32} className="nav-item-contents user-icon" />
                    <div className="nav-item-contents top-nav-item-label d-md-none-inverted">
                        {accounts[0]?.name ?? 'Username'}
                    </div>
                    <ChevronDown size={24} className="nav-item-contents user-icon d-md-none-inverted"/>
                </NavLink>
            </Dropdown.Toggle>

            <Dropdown.Menu className="top-nav-dropdown-menu"  data-testid="logout">
                {userProfile.environmentConfig.hasParallelEnvironment && (
                    <>
                        <Dropdown.Item href={process.env.REACT_APP_PARALLEL_URL}>
                            Switch to {process.env.REACT_APP_PARALLEL_ENV_LABEL}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                    </>
                )}
                <Dropdown.Item
                    onClick={async () => {
                        const netApiLogout = api.callApi(
                            instance,
                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                            process.env.REACT_APP_NET_API_URL + '/User/logout',
                            'DELETE'
                        );

                        let pythonApiLogout = Promise.resolve<Response>(new Response());
                        if (isSourceSystemAdminOrHigher(userProfile)) {
                            pythonApiLogout = api.callApi(
                                instance,
                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                process.env.REACT_APP_PYTHON_API_URL + '/logout',
                                'DELETE'
                            );
                        }

                        Promise.all([netApiLogout, pythonApiLogout]).finally(() => {
                            instance.logout();
                        });
                    }}
                >
                    Logout
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserDropdown;
