import './UserForm.css';

import React, { FunctionComponent, useCallback } from 'react';
import { User, UserStatus } from '../TeamMemberModels';

import DefaultCustomerSelect from './DefaultCustomerSelect';
import { Form } from 'react-bootstrap';
import OrganizationsSelect from './OrganizationsSelect';
import ProjectsSelect from './ProjectsSelect';
import RoleSelect from './RoleSelect';
import SourceSystemSelect from './SourceSystemSelect';
import { Theme } from 'react-select';
import { useFormContext } from '../../Shared/contexts/FormProvider';
import { useMsal } from '@azure/msal-react';

interface UserFormProps {
    mode: 'Edit' | 'Add';
    isEccoviaUser: boolean;
    editEnabledForAdmin: boolean;
}

const UserForm: FunctionComponent<UserFormProps> = (props: UserFormProps) => {
    const { mode, isEccoviaUser, editEnabledForAdmin } = props;
    const { accounts } = useMsal();
    const { errors, formState, register, setValue } = useFormContext<User>();
    const isCurrentUser = formState?.firstName + ' ' + formState?.lastName === accounts[0].name;
    const disableSwitch = !editEnabledForAdmin || formState.status === UserStatus.Pending || isCurrentUser;

    const formSelectTheme = useCallback(
        (theme: Theme) => ({
            ...theme,
            borderRadius: 8,
        }),
        []
    );

    return (
        <div className={mode === 'Edit' ? 'edit-panel-form' : 'p-3'}>
            {errors?.customerId && <div className="user-form__error-message">{errors.customerId}</div>}

            <Form.Group className="mb-4">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    {...register('upn')}
                    plaintext={mode === 'Edit'}
                    readOnly={mode === 'Edit'}
                />
                <Form.Control.Feedback type="invalid">{errors?.upn}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" placeholder="Enter First Name" {...register('firstName')} />
                <Form.Control.Feedback type="invalid">{errors?.firstName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Last Name" {...register('lastName')} />
                <Form.Control.Feedback type="invalid">{errors?.lastName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4">
                <Form.Label>Job Title</Form.Label>
                <Form.Control type="text" placeholder="Enter Job Title" {...register('jobTitle')} />
                <Form.Control.Feedback type="invalid">{errors?.jobTitle}</Form.Control.Feedback>
            </Form.Group>

            {!isEccoviaUser && (
                <>
                    <RoleSelect formTheme={formSelectTheme} editEnabledForAdmin={editEnabledForAdmin} />

                    <SourceSystemSelect formTheme={formSelectTheme} editEnabledForAdmin={editEnabledForAdmin} />

                    <OrganizationsSelect formTheme={formSelectTheme} editEnabledForAdmin={editEnabledForAdmin} />

                    <ProjectsSelect
                        formTheme={formSelectTheme}
                        shouldAddBottomMargin={mode === 'Edit'}
                        editEnabledForAdmin={editEnabledForAdmin}
                    />
                </>
            )}

            {isEccoviaUser && <DefaultCustomerSelect formTheme={formSelectTheme} editMode={mode === 'Edit'} />}

            {mode === 'Edit' && (
                <Form.Group>
                    <Form.Label>Active Status</Form.Label>
                    <Form.Check
                        className="form-control-lg"
                        type="switch"
                        label={formState.status !== undefined ? UserStatus[formState.status] : 'Invalid Status'}
                        checked={formState.status === UserStatus.Active || formState.status === UserStatus.Pending}
                        disabled={disableSwitch}
                        onChange={() => {
                            setValue(
                                'status',
                                formState.status === UserStatus.Active ? UserStatus.Inactive : UserStatus.Active
                            );
                        }}
                    />
                </Form.Group>
            )}
        </div>
    );
};

export default UserForm;
