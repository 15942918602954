import { TrainingDataRecord } from './Dedupe/ProjectSteps/Training/TrainingDataTypes';

export interface DedupeProject {
    project_key: number;
    created_by: string;
    created_time: string;
    description: string;
    full_dedupe: boolean;
    name: string;
    source_system_key: number;
    project_phase?: ProjectPhase[];
    project_user: ProjectUser[];
    exists_in_cache: boolean;
    project_ui_stage?: DedupeProjectSteps;
    record_pair: TrainingDataRecord[];
    used_for_mci: boolean;
    status: number;
}

export interface ProjectPhase {
    end_time?: Date;
    project_key: number;
    project_phase: number;
    start_time?: Date;
}

export interface ProjectUser {
    project_key: number;
    start_time: Date;
    worked_by: string;
}

export interface ClusterReviewCounts {
    p_confirmed: number;
    p_reviewed: number;
    p_total: number;
}

export interface ClusterRecord {
    added: boolean;
    canon_id: number;
    client_key: number;
    cluster_score?: number;
    link_score?: number;
    [key: string]: unknown;
    reviewed_by?: string;
    reviewed_date?: string;
    row_version: string;
    confirmed_by?: string;
    confirmed_date?: string;
    canon?: boolean;
}

export interface Cluster {
    fullName: string;
    confidence: number;
    [key: string]: unknown;
    reviewedBy?: string;
    reviewedDate?: string;
    clusterStatus?: ClusterStatus;
    clusterId: number;
    clusterRecords: ClusterRecord[];
}

export interface IncrementalDedupeStatistics {
    UCI_DEDUPE_THRESHOLD_NUMBER: number;
    UCI_DEDUPE_THRESHOLD_PERCENT: number;
    clientCount: number;
    fullDedupeRequired: boolean;
    lastFullDedupeCount: number;
}

export enum ClusterStatus {
    UNCONFIRMED = 0,
    APPROVED = 1,
    REJECTED = 2,
}

export enum DedupeProjectSteps {
    UNKNOWN = 0,
    SELECT_DATASET = 1,
    PREP_TRAINING = 2,
    TRAINING = 3,
    CLUSTERING = 4,
    REVIEW = 5,
}

export enum ProjectHistoryStatus {
    Incomplete = 0,
    Complete = 1,
    Abandoned = 2,
}
