import { AddFilled, ChevronRight, Edit, TrashCan } from '@carbon/icons-react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import { Category } from '../../Reports/ReportModels';
import DataTable from '../../Shared/components/DataTable';
import DeleteCategoryModal from './DeleteCategoryModal';
import SaveCategoryModal from './SaveCategoryModal';
import { colorOptions } from './utils';
import { getContrastColor } from '../../Shared/SharedHelpers';
import { useMsal } from '@azure/msal-react';

const ReportCategories: FunctionComponent = () => {
    const navigate: NavigateFunction = useNavigate();
    const [categoriesList, setCategoriesList] = useState<Category[]>([]);
    const [categoryListLoading, setCategoryListLoading] = useState(false);

    const [showAddCategoryModal, setShowAddCategoryModal] = useState<boolean>(false);
    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState<boolean>(false);
    const [isEditingCategory, setIsEditingCategory] = useState<boolean>(false);
    const [categoryWasChangedOrAdded, setCategoryWasChangedOrAdded] = useState<boolean>(true);

    // Use this to represent the category being added or edited.
    // Pass it down to both modals: In the Edit modal, get info from datatable.
    // I put in initial values to get around the Category | undefined type
    const [category, setCategory] = useState<Category>(getDefaultCategory());

    const fetchIdRef = useRef(0);
    const categoriesListColumns: ColumnDef<Category>[] = useMemo(
        () => [
            { header: 'Category Name', accessorKey: 'categoryName' },
            {
                header: 'Color',
                accessorKey: 'colorHex',
                cell: ({ row }: CellContext<Category, unknown>) => (
                    <div className="flex-row">
                        <div
                            className="category-pill mx-1 mb-1 px-2"
                            style={{
                                color: getContrastColor(row.original.colorHex),
                                background: row.original.colorHex || undefined,
                            }}
                        >
                            {colorOptions.find((option) => option.value === row.original.colorHex)?.label ?? 'Default'}
                        </div>
                    </div>
                ),
            },
            { header: 'Created By', accessorKey: 'createdBy' },
            {
                header: 'Actions',
                id: 'actions',
                cell: ({ row }: CellContext<Category, unknown>) => (
                    <div className="d-flex">
                        <Button
                            variant="link"
                            onClick={() => {
                                setCategory(row.original);
                                setIsEditingCategory(true);
                                setShowAddCategoryModal(true);
                            }}
                        >
                            <Edit size={24} />
                        </Button>

                        <Button
                            variant="link-danger"
                            onClick={() => {
                                setCategory(row.original);
                                setShowDeleteCategoryModal(true);
                            }}
                        >
                            <TrashCan size={24} />
                        </Button>
                    </div>
                ),
            },
        ],
        []
    );

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    useEffect(() => {
        if (categoryWasChangedOrAdded) {
            const fetchId = ++fetchIdRef.current;

            setCategoryListLoading(true);
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/PbiReport/categories'
            ).then(async (result: Response) => {
                if (result)
                    if (fetchId === fetchIdRef.current) {
                        const categories: Category[] = await result.json();
                        // Filter out the categories that are deleted.
                        setCategoriesList(categories.filter((category) => !category.isDeleted));
                    }

                setCategoryListLoading(false);
            });
        }
    }, [api, instance, categoryWasChangedOrAdded]);

    useEffect(() => {
        if (categoryWasChangedOrAdded) {
            setCategoryWasChangedOrAdded(false);
        }
    }, [categoryWasChangedOrAdded]);

    return (
        <div className="content-column-container">
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        navigate('/admin');
                    }}
                >
                    Configuration
                </Breadcrumb.Item>
                <ChevronRight size={24} className="breadcrumb-chevron" />
                <Breadcrumb.Item active>Report Categories</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content-header">
                <h1 className="content-title">Categories</h1>
                <Button
                    variant="outline-primary"
                    onClick={() => {
                        setShowAddCategoryModal(true);
                        setCategory(getDefaultCategory());
                    }}
                >
                    <AddFilled size={24} className="button-icon-padding" /> Add Category
                </Button>
            </div>

            {!categoryWasChangedOrAdded && (
                <DataTable
                    label="reports"
                    data={categoriesList}
                    columns={categoriesListColumns}
                    loading={categoryListLoading}
                    refreshData={() => {
                        setCategoryWasChangedOrAdded(true);
                    }}
                />
            )}

            <SaveCategoryModal
                isEditingCategory={isEditingCategory}
                setIsEditingCategory={setIsEditingCategory}
                showAddCategoryModal={showAddCategoryModal}
                setShowAddCategoryModal={setShowAddCategoryModal}
                category={category}
                setCategory={setCategory}
                setCategoryWasChangedOrAdded={setCategoryWasChangedOrAdded}
            />

            <DeleteCategoryModal
                showDeleteCategoryModal={showDeleteCategoryModal}
                setShowDeleteCategoryModal={setShowDeleteCategoryModal}
                category={category}
                setCategory={setCategory}
                setCategoryWasChangedOrAdded={setCategoryWasChangedOrAdded}
            />
        </div>
    );
};

export default ReportCategories;

const getDefaultCategory = (): Category => ({
    categoryKey: 0,
    categoryName: '',
    colorHex: '',
    isDeleted: false,
    isCustom: false,
});
