import './TopNav.css';

import { Nav, Navbar } from 'react-bootstrap';
import React, { Dispatch, FunctionComponent, SetStateAction, useContext } from 'react';

import AlertsDropdown from './AlertsDropdown';
import EnvironmentDropdown from './EnvironmentDropdown';
import FavoritesDropdown from './FavoritesDropdown';
import HelpDropdown from './HelpDropdown';
import UserDropdown from './UserDropdown';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';

export interface TopNavProps {
    setShowLeftNav: Dispatch<SetStateAction<boolean>>;
}

const TopNav: FunctionComponent<TopNavProps> = (props: TopNavProps) => {
    const { setShowLeftNav } = props;
    const userProfile = useContext(UserProfileContext);

    return (
        <div>
            <Navbar className="navbar navbar-light bg-white topbar static-top shadow">
                <button
                    id="sidebarToggleTop"
                    title="sidebarToggleTop"
                    className="btn btn-link d-md-none rounded-circle mr-3"
                    onClick={() => {
                        setShowLeftNav((prevValue) => !prevValue);
                    }}
                >
                    <i className="fa fa-bars"></i>
                </button>
                <Nav className="navbar-nav ml-auto">
                    <FavoritesDropdown />
                    <HelpDropdown />
                    <AlertsDropdown />
                    {userProfile.isEccoviaUser && <EnvironmentDropdown />}
                    <UserDropdown />
                </Nav>
            </Navbar>
        </div>
    );
};

export default TopNav;
