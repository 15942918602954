import { Organization, UserRoles } from '../../Shared/SharedModels';
import { useContext, useState } from 'react';
import useForm, { UseFormReturn } from '../../Shared/hooks/useForm';

import ApiHelper from '../../Shared/ApiHelper';
import { User } from '../TeamMemberModels';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { createSchema } from '../../Shared/Validations';
import { useMsal } from '@azure/msal-react';

interface UseUserFormProps {
    isEccoviaUser?: boolean;
    user?: User;
}

export default function useUserForm({ isEccoviaUser = false, user = {} }: UseUserFormProps = {}): UseFormReturn<User> {
    const userProfile = useContext(UserProfileContext);
    const defaultUser = { ...getUserFormDefautValues(!!isEccoviaUser, userProfile.user) };
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    const defaultValues = {
        ...defaultUser,
        ...user,
    };

    if (!isEccoviaUser) {
        defaultValues.customerId = userProfile?.user?.defaultCustomerId ?? userProfile?.user?.customerId ?? '';
    }

    if (defaultValues.roleKey === UserRoles['Project User'] && defaultValues.projects?.length) {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/organizations'
        ).then(async (result: Response) => {
            const orgs: Organization[] = await result.json();

            defaultValues.organizations = orgs.filter((org) => {
                return !!defaultValues.projects?.find((proj) => proj.organizationKey === org.organizationKey);
            });
        });
    }
    //TODO: populate organizations from projects values here if possible

    const methods = useForm<User>({
        defaultValues,
        schema: getUserFormSchema(!!isEccoviaUser),
    });
    return methods;
}

const getUserFormDefautValues = (isEccoviaUser: boolean, userProfile?: User): User => ({
    upn: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    ...(isEccoviaUser
        ? { defaultCustomerId: '' }
        : {
              customerId: userProfile?.defaultCustomerId ?? userProfile?.customerId ?? '',
              role: undefined,
              organizations: [],
              sourceSystems: [],
              projects: [],
          }),
});

const getUserFormSchema = (isEccoviaUser: boolean): ReturnType<typeof createSchema> =>
    createSchema(({ array, string, object }) => ({
        upn: string().email().max(100).required(),
        firstName: string().max(50).required(),
        lastName: string().max(50).required(),
        jobTitle: string().max(30).nullable(),

        ...(isEccoviaUser
            ? {
                  defaultCustomerId: string().required(),
              }
            : {
                  customerId: string().required('Customer ID is required'),
                  role: object().required(),
                  organizations: array().when('role', (role, schema) =>
                      ['Org Admin', 'Member'].includes(role?.name) ? schema.min(1).required() : schema.nullable()
                  ),
                  sourceSystems: array().when('role', (role, schema) =>
                      role?.name === 'Source System Admin' ? schema.min(1).required() : schema.nullable()
                  ),
                  projects: array().when('role', (role, schema) =>
                      ['Project User'].includes(role?.name) ? schema.min(1).required() : schema.nullable()
                  ),
              }),
    }));
