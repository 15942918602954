import './Training.css';

import { Button, Col, Spinner } from 'react-bootstrap';
import { ClientDataField, TrainingData } from './TrainingDataTypes';
import { FaceNeutral, ThumbsDown, ThumbsUp } from '@carbon/icons-react';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../../../../Shared/ApiHelper';
import { Gender } from '../../../../Shared/SharedModels';
import { useMsal } from '@azure/msal-react';

export interface TrainingProps {
    setDisableContinue: (disableContinue: boolean) => void;
    initialTrainingData: TrainingData;
}

const Training: FunctionComponent<TrainingProps> = (props: TrainingProps) => {
    const { setDisableContinue, initialTrainingData } = props;

    const [trainingHasFinished, setTrainingHasFinished] = useState(false);
    const [unsureCount, setUnsureCount] = useState(0);
    const [trainingData, setTrainingData] = useState<TrainingData>(initialTrainingData);
    const [isLoading, setIsLoading] = useState(false);
    const [trainingFields, setTrainingFields] = useState<ClientDataField[]>();

    const { instance } = useMsal();
    const api = useMemo(() => new ApiHelper(), []);

    const decideRecordPair = useCallback(
        (userInput: string) => {
            if (!isLoading) {
                setIsLoading(true);
                api.callApi(
                    instance,
                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                    process.env.REACT_APP_PYTHON_API_URL + '/dedupenew',
                    'POST',
                    JSON.stringify({ user_input: userInput })
                ).then(async (result: Response) => {
                    const dedupeProject: TrainingData = await result.json();

                    setTrainingData(dedupeProject);
                    setIsLoading(false);
                });
            }
        },
        [api, instance, isLoading]
    );

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_PYTHON_API_URL + `${'/dedupe/params'}`,
            'GET'
        ).then(async (result: Response) => {
            const fields: ClientDataField[] = await result.json();
            setTrainingFields(fields);
        });
    }, [api, instance]);

    useEffect(() => {
        if (trainingData.n_match >= 10 && trainingData.n_distinct >= 10) {
            setDisableContinue(false);

            if (!trainingHasFinished) {
                toast.success(
                    "You've provided enough training to move to the next step. You can train the system more or move to the next step.",
                    {
                        position: 'top-right',
                        autoClose: 5000,
                        transition: Slide,
                        draggable: false,
                        closeOnClick: false,
                        theme: 'colored',
                    }
                );
                setTrainingHasFinished(true);
            }
        }
    }, [trainingData.n_match, trainingData.n_distinct, setDisableContinue, trainingHasFinished]);

    return (
        <div>
            <div className="training-result-counters flex-row">
                <div className="training-counter yes mx-2">{trainingData.n_match}/10 Yes</div>
                <div className="training-counter no mx-2">{trainingData.n_distinct}/10 No</div>
                <div className="training-counter unsure mx-2">{unsureCount} Unsure</div>
            </div>
            <div className="flex-column training-records-container my-3">
                <div className="training-records-header flex-row p-3">
                    {trainingFields?.map((field) => {
                        return <Col key={field.label + ' Label'}>{field.label}</Col>;
                    })}
                </div>
                {isLoading ? (
                    <Spinner className="centered-spinner" variant="primary" size="sm" animation="border" />
                ) : (
                    trainingData.record_pair.map((record, index) => {
                        return (
                            <div
                                className={
                                    index === trainingData.record_pair.length - 1
                                        ? 'flex-row p-3'
                                        : 'flex-row p-3 training-records'
                                }
                                key={'training-record-' + record.client_key}
                            >
                                {trainingFields?.map((field) => {
                                    return (
                                        <Col key={field.label + ' ' + record.client_key + ' Value'}>
                                            {field.field === 'gender' && record.gender != undefined
                                                ? Gender[record.gender as number]
                                                : (record[field.field] as string) ?? '-'}
                                        </Col>
                                    );
                                })}
                            </div>
                        );
                    })
                )}
            </div>
            <div className="flex-column">
                <h5>Are these two records the same person?</h5>
                <div className="flex-row">
                    <Button
                        className="mr-2"
                        onClick={() => {
                            decideRecordPair('y');
                        }}
                        variant="outline-secondary"
                    >
                        <ThumbsUp size={24} className="button-icon-padding" />
                        Yes
                    </Button>
                    <Button
                        className="mr-2"
                        onClick={() => {
                            decideRecordPair('n');
                        }}
                        variant="outline-danger"
                    >
                        <ThumbsDown size={24} className="button-icon-padding" />
                        No
                    </Button>
                    <Button
                        className="mr-2"
                        onClick={() => {
                            decideRecordPair('u');
                            setUnsureCount(unsureCount + 1);
                        }}
                        variant="outline-warning"
                    >
                        <FaceNeutral size={24} className="button-icon-padding" />
                        Unsure
                    </Button>
                </div>
                {trainingData.show_previous && (
                    <Button
                        className="mt-2 show-previous-records"
                        onClick={() => {
                            decideRecordPair('p');
                        }}
                        variant="link"
                    >
                        View Previous Records
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Training;
