import 'react-step-progress-bar/styles.css';
import './ProgressStepper.css';

import { ProgressBar, Step } from 'react-step-progress-bar';
import React, { FunctionComponent } from 'react';

import { Checkmark } from '@carbon/icons-react';
import { StepParameters } from './ProgressStepperModels';

interface ProgressStepperProps {
    activeStep: number;
    steps: string[];
}

const ProgressStepper: FunctionComponent<ProgressStepperProps> = (props: ProgressStepperProps) => {
    const { activeStep, steps } = props;

    return (
        <div className="progress-bar-padding">
            <ProgressBar
                percent={(100 / (steps.length - 1)) * (activeStep - 1)}
                height={4}
                unfilledBackground="#D5D7DB"
                filledBackground="#3166C4"
            >
                {steps.map((step) => {
                    return (
                        <Step key={`progress-step-${step}`}>
                            {({ accomplished, index }: StepParameters) => (
                                <div className="flex-column indexed-step-container">
                                    <div
                                        data-testid={`step-label-${index}`}
                                        className={`indexedStep ${
                                            activeStep === index + 1 ? 'active' : accomplished ? 'accomplished' : ''
                                        }`}
                                    >
                                        {accomplished && activeStep != index + 1 ? <Checkmark size={16} /> : index + 1}
                                    </div>
                                    <h6>{step}</h6>
                                </div>
                            )}
                        </Step>
                    );
                })}
            </ProgressBar>
        </div>
    );
};

export default ProgressStepper;
