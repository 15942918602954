import { DQAlertConfigData, DQAlertConfigViewModel, DQAlertThresholdPair } from './DataQualityModels';
import React, { FunctionComponent, useEffect, useState } from 'react';

import ThresholdPair from './ThresholdPair';

interface ThresholdInputsProps {
    elementConfig: DQAlertConfigData;
    setActiveConfigData: React.Dispatch<React.SetStateAction<DQAlertConfigViewModel | undefined>>;
}

const ThresholdInputs: FunctionComponent<ThresholdInputsProps> = (props: ThresholdInputsProps) => {
    const { elementConfig, setActiveConfigData } = props;

    const [thresholdPairs, setThresholdPairs] = useState<Map<string, DQAlertThresholdPair>>(
        new Map<string, DQAlertThresholdPair>()
    );

    useEffect(() => {
        const pairs = new Map<string, DQAlertThresholdPair>();

        Object.keys(elementConfig.thresholds).forEach((key) => {
            const projectBaseName = key.substring(0, key.indexOf('_'));
            const isMissing = key.includes('Missing');
            let existingPair = pairs.get(projectBaseName);

            if (!existingPair) {
                existingPair = {
                    projectBaseName: projectBaseName,
                    missingThreshold: 0,
                    unknownThreshold: 0,
                    missingThresholdName: '',
                    unknownThresholdName: '',
                };
            }

            if (isMissing) {
                existingPair = {
                    ...existingPair,
                    missingThreshold: elementConfig.thresholds[key],
                    missingThresholdName: key,
                };
            } else {
                existingPair = {
                    ...existingPair,
                    unknownThreshold: elementConfig.thresholds[key],
                    unknownThresholdName: key,
                };
            }

            pairs.set(projectBaseName, existingPair);
        });

        setThresholdPairs(pairs);
    }, [elementConfig.thresholds]);

    return (
        <div className="flex-row flex-wrap">
            {Array.from(thresholdPairs.values()).map((thresholdPair) => {
                return (
                    <div
                        className="threshold-form-group"
                        key={elementConfig.element + '-threshold-' + thresholdPair.projectBaseName}
                    >
                        <h5>{thresholdPair.projectBaseName}</h5>
                        <ThresholdPair
                            thresholdPair={thresholdPair}
                            elementConfig={elementConfig}
                            setActiveConfigData={setActiveConfigData}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ThresholdInputs;
