import { Organization, SelectOption, UserRoles } from '../../Shared/SharedModels';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import Select, { ThemeConfig } from 'react-select';

import ApiHelper from '../../Shared/ApiHelper';
import { Form } from 'react-bootstrap';
import { User } from '../TeamMemberModels';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { sortSelectOptions } from '../../Shared/SharedHelpers';
import { useFormContext } from '../../Shared/contexts/FormProvider';
import { useMsal } from '@azure/msal-react';

interface OrganizationsSelectProps {
    formTheme: ThemeConfig;
    editEnabledForAdmin: boolean;
}

const OrganizationsSelect: FunctionComponent<OrganizationsSelectProps> = (props) => {
    const { formTheme, editEnabledForAdmin } = props;
    const userProfile = useContext(UserProfileContext);

    const {
        errors,
        formState: { role, organizations },
        register,
        setValue,
    } = useFormContext<User>();

    const areOrganizationsInvalid = !!errors?.organizations;

    const [organizationOptions, setOrganizationOptions] = useState<SelectOption<Organization>[]>([]);

    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/organizations'
        ).then(async (result: Response) => {
            const orgs: Organization[] = await result.json();

            const orgNames = orgs.map((org) => org.organizationName);
            const orgNamesSet = new Set(orgNames);

            const hasDuplicateOrgs = orgNames.length > orgNamesSet.size;
            const mappedOrgs = orgs
                .map((org: Organization) => {
                    let optionLabel = org.organizationName;
                    if (hasDuplicateOrgs) {
                        const sourceSystemShortName = userProfile.sourceSystems.find(
                            (system) => system.sourceSystemKey === org.sourceSystemKey
                        )?.sourceSystemShortName;
                        if (sourceSystemShortName) optionLabel = `(${sourceSystemShortName}) ${optionLabel}`;
                    }

                    return {
                        label: optionLabel,
                        value: org,
                    } as SelectOption<Organization>;
                })
                .sort(sortSelectOptions);
            setOrganizationOptions(mappedOrgs);
        });
    }, [api, instance, userProfile.sourceSystems]);

    return (
        <Form.Group className="mb-4">
            <Form.Label>Organizations</Form.Label>

            <Select
                {...register('organizations')}
                isMulti
                isDisabled={
                    !editEnabledForAdmin ||
                    !role ||
                    role?.roleKey === UserRoles['Global Admin'] ||
                    role?.roleKey === UserRoles['Source System Admin']
                }
                closeMenuOnSelect={false}
                options={organizationOptions}
                placeholder="Select Organizations..."
                value={organizations?.map((org: Organization) => {
                    return {
                        label: org.organizationName,
                        value: org,
                    };
                })}
                theme={formTheme}
                onChange={(selectedOptions) => {
                    setValue(
                        'organizations',
                        selectedOptions.map((option) => option.value)
                    );
                }}
                className={areOrganizationsInvalid ? 'is-invalid' : ''}
                classNamePrefix="base-select"
                menuPlacement="auto"
            />
            <Form.Control.Feedback type="invalid">
                {typeof errors?.organizations === 'string' && errors?.organizations}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default OrganizationsSelect;
