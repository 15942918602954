/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { createContext, useContext } from 'react';

import { UseFormReturn } from '../hooks/useForm';

type FormState = UseFormReturn<any>;

export const FormContext = createContext({} as FormState);

export const FormProvider: React.FC<FormState> = ({ children, ...props }) => (
    <FormContext.Provider value={props}>{children}</FormContext.Provider>
);

export function useFormContext<T extends object = any>(): UseFormReturn<T> {
    return useContext(FormContext);
}
