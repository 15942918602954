import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Select, { SingleValue, Theme } from 'react-select';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../Shared/ApiHelper';
import { ReportMetadata } from './ReportModels';
import { SelectOption } from '../Shared/SharedModels';
import { User } from '../TeamMembers/TeamMemberModels';
import { useMsal } from '@azure/msal-react';

interface BulkReassignModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    selectedReports: ReportMetadata[];
    refreshData: () => void;
}

const BulkReassignModal: FunctionComponent<BulkReassignModalProps> = (props: BulkReassignModalProps) => {
    const { showModal, setShowModal, selectedReports, refreshData } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [userListLoading, setUserListLoading] = useState(true);

    const [userOptions, setUserOptions] = useState<SelectOption<string>[]>([]);
    const [selectedUser, setSelectedUser] = useState<SelectOption<string>>();

    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    const formSelectTheme = useCallback(
        (theme: Theme) => ({
            ...theme,
            borderRadius: 8,
        }),
        []
    );

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_NET_API_URL}/User`
        ).then(async (result: Response) => {
            const users = await result.json();

            const mappedUsers: SelectOption<string>[] = users.map((user: User) => {
                return {
                    label: user.upn,
                    value: user.upn,
                };
            });

            setUserOptions(mappedUsers);
            setUserListLoading(false);
        });
    }, [api, instance]);

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Change Report Owner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-4">
                    <Form.Label>Change the owner of the selected report(s) to:</Form.Label>
                    <Select
                        options={userOptions}
                        value={selectedUser}
                        isLoading={userListLoading}
                        placeholder="Select User..."
                        theme={formSelectTheme}
                        isClearable
                        onChange={(selectedOption: SingleValue<SelectOption<string>>) => {
                            setSelectedUser(selectedOption as SelectOption<string>);
                        }}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="link"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="outline-primary"
                    disabled={isLoading || !selectedReports.length || !selectedUser}
                    onClick={() => {
                        setIsLoading(true);

                        const payload = {
                            assignTo: selectedUser?.value,
                            reportIds: selectedReports.map((report) => report.reportId),
                        };
                        api.callApi(
                            instance,
                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                            process.env.REACT_APP_NET_API_URL + '/PbiReport/bulkreassign',
                            'PUT',
                            JSON.stringify(payload)
                        )
                            .then(() => {
                                setShowModal(false);
                                refreshData();

                                toast.success('Report Ownership Changed Successfully', {
                                    position: 'top-right',
                                    autoClose: 5000,
                                    transition: Slide,
                                    draggable: false,
                                    closeOnClick: false,
                                    theme: 'colored',
                                });
                            })
                            .finally(() => {
                                setIsLoading(false);
                            });
                    }}
                >
                    {isLoading ? (
                        <>
                            <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
                            Changing Owner
                        </>
                    ) : (
                        <>Change Owner</>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BulkReassignModal;
