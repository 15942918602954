import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ServiceCard, useCardsContext } from '../../Shared/contexts/CardsProvider';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../../Shared/ApiHelper';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import styles from './ServiceSummaryModal.module.css';
import useForm from '../../Shared/hooks/useForm';
import { useMsal } from '@azure/msal-react';

interface ServiceSummaryModalProps {
    cardIndex: number;
    handleClickCancel(): void;
}

export const EditServiceModal: React.FC<ServiceSummaryModalProps> = ({ cardIndex, handleClickCancel }) => {
    const { serviceCards, setServiceCardByIndex } = useCardsContext();

    const [serviceColumns, setServiceColumns] = useState<string[]>([]);

    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const { formState, handleSubmit, register, setValue } = useForm<ServiceCard>({
        defaultValues: !isEmpty(serviceCards[cardIndex])
            ? serviceCards[cardIndex]
            : {
                  service: 'hmis',
                  serviceColumns: [],
              },
    });

    const getServiceData = useCallback(
        async (service: string): Promise<void> => {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + `/swagger/v1/swagger-json`
            ).then(async (result: Response) => {
                const schemaData = await result.json();

                if (service === 'ehr') {
                    setServiceColumns(Object.keys(schemaData.components.schemas.Encounter.properties));
                } else if (service === 'hmis') {
                    setServiceColumns(Object.keys(schemaData.components.schemas.EnrollmentSummary.properties));
                }
            });
        },
        [api, instance]
    );

    const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;

        const newColumns = event.target.checked
            ? [...formState.serviceColumns, value]
            : formState.serviceColumns.filter((column) => column !== value);

        setValue('serviceColumns', newColumns);
    };

    const onSubmit = handleSubmit((values) => {
        setServiceCardByIndex(cardIndex, values);

        toast.success('Client card successfully updated.', {
            position: 'top-right',
            autoClose: 3000,
            transition: Slide,
            draggable: false,
            closeOnClick: false,
            theme: 'colored',
        });

        handleClickCancel();
    });

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValue('service', event.target.value);
        setValue('serviceColumns', []);
        getServiceData(event.target.value);
    };

    useEffect(() => {
        getServiceData(formState.service);
    }, [formState.service, getServiceData]);

    return (
        <Modal scrollable show centered onHide={() => handleClickCancel()}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Card Schema</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.body}>
                    <Form id="formSchema" onSubmit={onSubmit}>
                        <Row>
                            <Col>
                                <div className={styles.card}>
                                    <span>Services Available</span>
                                    <div>
                                        <input
                                            type="radio"
                                            {...register(`service`)}
                                            value="hmis"
                                            checked={formState.service === 'hmis'}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor="servHmis">HMIS</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            {...register(`service`)}
                                            value="ehr"
                                            checked={formState.service === 'ehr'}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor="servEhr">EHR</label>
                                    </div>
                                </div>
                            </Col>
                            {formState.service && (
                                <Col>
                                    <div className={styles.card}>
                                        <span>{`${formState.service.toUpperCase()} Data Columns`}</span>
                                        <div className={styles.cardBody}>
                                            {serviceColumns.map((column) => (
                                                <div key={column}>
                                                    <input
                                                        type="checkbox"
                                                        disabled={
                                                            formState.serviceColumns.length > 5 &&
                                                            !formState.serviceColumns.includes(column)
                                                        }
                                                        {...register('columnChecked')}
                                                        checked={formState.serviceColumns.includes(column)}
                                                        value={column}
                                                        onChange={handleChangeCheckbox}
                                                    />
                                                    <label htmlFor={column}>{startCase(column)}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="link"
                    onClick={() => {
                        handleClickCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button type="submit" variant="primary" form="formSchema">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
