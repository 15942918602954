import { ArrowDown, Edit } from '@carbon/icons-react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';

import { CarbonIconType } from '@carbon/icons-react/lib/CarbonIcon';
import { EditServiceModal } from './ServiceSummaryModal';
import styles from './ServiceSummaryCard.module.css';

interface Option {
    label: string;
    value: string;
}

export interface ServiceSummaryCardItem {
    Icon: CarbonIconType;
    title: string;
    options: Option[] | Option[][];
}

interface ServiceSummaryCardProps {
    cardIndex: number;
    className?: string;
    items: ServiceSummaryCardItem[];
    showNumbers?: boolean;
    title: string;
    isLoading: boolean;
}

const ServiceSummaryCard: React.FC<ServiceSummaryCardProps> = ({
    cardIndex,
    className,
    items,
    showNumbers = true,
    title,
    isLoading,
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const [isShowEditServiceModal, setIsShowEditServiceModal] = useState(false);

    const handleClickModalCancel = (): void => {
        setIsShowEditServiceModal(false);
    };

    const renderItems = (items: Option[] | Option[][]): JSX.Element[] =>
        items.map((item, index: number) => {
            const subItems = Array.isArray(item) ? item : [item];

            return (
                <div key={index} className="flex-row">
                    <div
                        className={styles.number}
                        style={{ visibility: showNumbers || Array.isArray(item) ? 'visible' : 'hidden' }}
                    >
                        {index + 1}
                    </div>
                    <div className={styles.list}>
                        {subItems.map((subItem, subItemIndex) => (
                            <div key={subItemIndex}>
                                <div className={styles.label}>{subItem.label}</div>
                                <div className={styles.text}>{subItem.value}</div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        });

    const viewAllButton = items.some((item) => item.options.length > 3) && (
        <button type="button" className={styles.viewAllButton} onClick={() => setIsVisible(!isVisible)}>
            <span>View All</span>

            <ArrowDown size={24} className="ml-2" />
        </button>
    );

    return (
        <>
            {isLoading ? (
                <div className="flex-column">
                    <Spinner className="centered-spinner" variant="primary" size="sm" animation="border" />
                </div>
            ) : (
                <>
                    <div className={`${styles.container} ${className}`}>
                        {isShowEditServiceModal && (
                            <EditServiceModal cardIndex={cardIndex} handleClickCancel={handleClickModalCancel} />
                        )}

                        <div className="flex-row align-items-center space-between">
                            <div className={styles.subTitle}>{title}</div>
                            <div className="flex-row">
                                <div className={styles.viewAllHeader}>{viewAllButton}</div>
                                <button className={styles.editButton} onClick={() => setIsShowEditServiceModal(true)}>
                                    <Edit size={24} />
                                </button>
                            </div>
                        </div>

                        {items.length > 0 ? (
                            <div className={styles.serviceContainer}>
                                {items.map(({ Icon, ...item }, index) => (
                                    <div key={index}>
                                        <div className="flex-row align-items-center">
                                            <Icon />
                                            <div className={styles.title}>{item?.title}</div>
                                        </div>
                                        <div className={styles.content}>
                                            {item?.options ? renderItems(item.options).slice(0, 3) : ''}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className={styles.emptyMessage}>
                                <span>No Results</span>
                            </div>
                        )}

                        <div className={styles.viewAllFooter}>{viewAllButton}</div>
                    </div>

                    <Modal
                        centered
                        scrollable
                        show={isVisible}
                        onExited={() => setIsVisible(false)}
                        onHide={() => setIsVisible(false)}
                    >
                        <Modal.Header>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body className={styles.serviceContainer}>
                            {items.map(({ Icon, ...item }, index) => (
                                <div key={index}>
                                    <div className="flex-row align-items-center">
                                        <Icon />
                                        <div className={styles.title}>{item.title}</div>
                                    </div>
                                    <div className={styles.modalContent}>{renderItems(item.options)}</div>
                                </div>
                            ))}
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="link" onClick={() => setIsVisible(false)}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </>
    );
};

export default ServiceSummaryCard;
