import { Filter, FilterRemove } from '@carbon/icons-react';
import React, { FunctionComponent } from 'react';

import { Button } from 'react-bootstrap';

interface FilterResetButtonProps {
    clickCallback: () => void;
    filtersApplied: boolean;
}

const FilterResetButton: FunctionComponent<FilterResetButtonProps> = (props: FilterResetButtonProps) => {
    const { filtersApplied, clickCallback } = props;

    return (
        <div className="filtersAppliedContainer">
            <Button variant="link" disabled={!filtersApplied} onClick={clickCallback}>
                {filtersApplied ? (
                    <>
                        <FilterRemove size={24} className="button-icon-padding" />
                        Reset All Filters
                    </>
                ) : (
                    <>
                        <Filter size={24} className="button-icon-padding" />
                        No Filters Applied
                    </>
                )}
            </Button>
        </div>
    );
};

export default FilterResetButton;
