import { Button, Spinner } from 'react-bootstrap';
import { Cluster, ClusterStatus } from '../../../DataToolsModels';
import { ColumnDef, Row, flexRender } from '@tanstack/react-table';
import React, { FunctionComponent, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../../../../Shared/ApiHelper';
import ExpandedCluster from './ExpandedCluster';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

interface ClusterReviewTableBodyProps {
    projectId: number;
    sourceSystemKey: number | undefined;
    loading: boolean;
    page: Row<Cluster>[];
    getTableCellClass: (value: string) => string;
    visibleColumns: ColumnDef<Cluster>[];
    updateClusterStatus: (row: number, status: ClusterStatus) => void;
    headers: ColumnDef<Cluster>[];
}

const ClusterReviewTableBody: FunctionComponent<ClusterReviewTableBodyProps> = (props: ClusterReviewTableBodyProps) => {
    const {
        projectId,
        sourceSystemKey,
        loading,
        page,
        getTableCellClass,
        visibleColumns,
        updateClusterStatus,
        headers,
    } = props;

    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());
    const navigate = useNavigate();

    return (
        <>
            {!loading && page.length ? (
                <tbody>
                    {page.map((row) => {
                        return (
                            <>
                                <tr key={'row-' + row.index}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td
                                                key={'cell-' + cell.column.id + '-' + row.index}
                                                className={getTableCellClass(cell.column.id)}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {row.getIsExpanded() ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>
                                            <ExpandedCluster
                                                row={row}
                                                projectId={projectId}
                                                updateClusterStatus={updateClusterStatus}
                                                clusterStatus={row.original.clusterStatus}
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </>
                        );
                    })}
                </tbody>
            ) : (
                <tbody>
                    <tr>
                        {loading ? (
                            <td colSpan={headers.length} className="spinner-container">
                                <Spinner className="table-spinner" variant="primary" size="sm" animation="border" />
                            </td>
                        ) : (
                            <td colSpan={headers.length} className="cluster-table-empty">
                                {sourceSystemKey === 0 ? (
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            api.callApi(
                                                instance,
                                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                                process.env.REACT_APP_PYTHON_API_URL + `/uci/process/${projectId}`,
                                                'PUT'
                                            ).then(async (_result: Response) => {
                                                toast.success(
                                                    'Approved matches have been merged into the Unique Client Index',
                                                    {
                                                        position: 'top-right',
                                                        autoClose: 5000,
                                                        transition: Slide,
                                                        draggable: false,
                                                        closeOnClick: false,
                                                        theme: 'colored',
                                                    }
                                                );
                                                navigate('/datatools');
                                            });
                                        }}
                                    >
                                        Merge to Unique Client Index
                                    </Button>
                                ) : (
                                    'No more clusters for review'
                                )}
                            </td>
                        )}
                    </tr>
                </tbody>
            )}{' '}
        </>
    );
};

export default ClusterReviewTableBody;
