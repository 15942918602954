import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { SelectOption, SourceSystem, UserRoles } from '../../Shared/SharedModels';
import { Slide, toast } from 'react-toastify';

import { AddFilled } from '@carbon/icons-react';
import ApiHelper from '../../Shared/ApiHelper';
import Select from 'react-select';
import { User } from '../../TeamMembers/TeamMemberModels';
import { createSchema } from '../../Shared/Validations';
import useForm from '../../Shared/hooks/useForm';
import { useMsal } from '@azure/msal-react';

const validationSchema = createSchema(({ string, boolean, object }) => ({
    sourceSystemName: string().max(50).required(),
    sourceSystemShortName: string().max(10).required(),
    externalShortName: string().max(20),
    isClientTrack: boolean().required(),
    schemaName: object().required(),
}));

const AddSourceSystem: FunctionComponent = () => {
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isAdminListLoading, setIsAdminListLoading] = useState(true);

    const [schemaOptions] = useState<SelectOption<string>[]>([{ label: 'HMIS', value: 'hmis' }]);
    const [adminOptions, setAdminOptions] = useState<SelectOption<User>[]>([]);

    const { errors, register, handleSubmit, resetForm, setValue, formState } = useForm({
        defaultValues: {
            sourceSystemName: '',
            sourceSystemShortName: '',
            externalShortName: undefined,
            isClientTrack: true,
            schemaName: { label: 'HMIS', value: 'hmis' },
            admins: [],
        },
        schema: validationSchema,
    });

    const onSubmit = handleSubmit(async (data) => {
        try {
            setIsLoading(true);

            const netPayload: string = JSON.stringify({
                ...data,
                schemaName: data.schemaName.value,
                admins: (data.admins as SelectOption<User>[]).map((admin) => admin.value.userKey),
            });

            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_NET_API_URL}/User/sourcesystems`,
                'POST',
                netPayload
            ).then(async (result: Response) => {
                const insertedSourceSystem: SourceSystem = await result.json();

                const pythonPayload: string = JSON.stringify({
                    source_system_key: insertedSourceSystem.sourceSystemKey,
                    name: data.sourceSystemName,
                    short_name: data.sourceSystemShortName,
                    is_clienttrack: data.isClientTrack,
                });

                api.callApi(
                    instance,
                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                    `${process.env.REACT_APP_NET_API_URL}/DQAlertConfig/${insertedSourceSystem.sourceSystemKey}`,
                    'POST'
                );

                api.callApi(
                    instance,
                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                    `${process.env.REACT_APP_PYTHON_API_URL}/sourcesystems`,
                    'POST',
                    pythonPayload
                ).then(() => {
                    toast.success('Source System has been added succesfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        transition: Slide,
                        draggable: false,
                        closeOnClick: false,
                        theme: 'colored',
                    });
                });
            });

            setShowModal(false);
        } finally {
            setIsLoading(false);
        }
    });

    useEffect(() => {
        resetForm();
    }, [showModal, resetForm]);

    useEffect(() => {
        api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], `${process.env.REACT_APP_NET_API_URL}/User`)
            .then(async (result: Response) => {
                const users: User[] = await result.json();

                const sourceSystemAdmins = users.filter(
                    (user) => user.role?.roleKey === UserRoles['Source System Admin']
                );
                const adminOptions = sourceSystemAdmins.map((user) => {
                    return { label: user.upn ?? '', value: user };
                });

                setAdminOptions(adminOptions);
            })
            .finally(() => {
                setIsAdminListLoading(false);
            });
    }, [api, instance]);

    return (
        <>
            <Button variant="outline-primary" onClick={() => setShowModal(!showModal)}>
                <AddFilled size={24} />
                <span className="ml-2">Add Source System</span>
            </Button>

            <Modal
                centered
                scrollable
                show={showModal}
                onExited={() => setShowModal(false)}
                onHide={() => setShowModal(false)}
            >
                <Form onSubmit={onSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Source System</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Group className="mb-4">
                            <Form.Label>Source System Name</Form.Label>
                            <Form.Control type="text" {...register('sourceSystemName')} />
                            <Form.Control.Feedback type="invalid">{errors?.sourceSystemName}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>Source System Short Name</Form.Label>
                            <Form.Control type="text" {...register('sourceSystemShortName')} />
                            <Form.Control.Feedback type="invalid">
                                {errors?.sourceSystemShortName}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>Source System External Short Name</Form.Label>
                            <Form.Control type="text" {...register('externalShortName')} />
                            <Form.Control.Feedback type="invalid">{errors?.externalShortName}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>Admins</Form.Label>
                            <Select
                                isClearable
                                isMulti
                                className="filterSelect"
                                closeMenuOnSelect={true}
                                placeholder="Select Admins..."
                                isLoading={isAdminListLoading}
                                {...register('admins')}
                                options={adminOptions}
                                onChange={(selectedOption) => {
                                    setValue('admins', selectedOption);
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>Client Track System</Form.Label>
                            <Form.Check
                                className="form-control-lg-centered"
                                type="switch"
                                label={formState.isClientTrack ? 'ClientTrack' : 'Non-ClientTrack'}
                                {...register('isClientTrack')}
                                onChange={() => {
                                    setValue('isClientTrack', !formState.isClientTrack);
                                }}
                                checked={formState.isClientTrack}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>Schema</Form.Label>
                            <Select
                                className="filterSelect"
                                closeMenuOnSelect={true}
                                placeholder="Select Schema..."
                                {...register('schemaName')}
                                options={schemaOptions}
                                onChange={(selectedOption) => {
                                    setValue('schemaName', selectedOption);
                                }}
                            />
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="link" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isLoading}>
                            {isLoading && <Spinner variant="light" animation="border" size="sm" />}
                            <span style={{ color: isLoading ? 'transparent' : '' }}>Add</span>
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default AddSourceSystem;
