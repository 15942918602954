import { Checkmark, Close } from '@carbon/icons-react';
import React, { useCallback } from 'react';

import { BatchStatus } from './BatchRunModels';
import { Button } from 'react-bootstrap';
import LoadingSpinnerIcon from '../../Shared/components/icons/LoadingSpinnerIcon';
import classNames from 'classnames';
import styles from './BatchRunsStages.module.css';

interface BatchStatusCountsProps {
    stage?: number;
    selectedStage?: number | null;
    onSelectedStageChange?(value: number): void;
    selectedStatus?: BatchStatus | null;
    onSelectedStatusChange?(value: BatchStatus | null): void;
    processedCount: number | undefined;
    processingCount: number | undefined;
    errorCount: number | undefined;
}

const BatchStatusCounts: React.FC<BatchStatusCountsProps> = ({
    stage,
    selectedStage,
    onSelectedStageChange,
    selectedStatus,
    onSelectedStatusChange,
    processedCount,
    processingCount,
    errorCount,
}) => {
    const getBadgeClasses = (status: BatchStatus, stage?: number): string =>
        classNames(styles.badge, {
            [styles.emptyBadge]: stage === undefined || status !== selectedStatus || stage !== selectedStage,
        });

    const setSelectedStatus = useCallback(
        (status: BatchStatus, stage: number) => {
            if (onSelectedStatusChange) {
                if (selectedStatus === status && stage === selectedStage) {
                    onSelectedStatusChange(null);
                } else {
                    onSelectedStatusChange(status);
                }
            }
        },
        [onSelectedStatusChange, selectedStage, selectedStatus]
    );

    return (
        <div className={classNames(styles.statuses, { [styles.summaryStatuses]: stage !== undefined })}>
            <Button
                title="Processing"
                variant="link"
                className={classNames(styles.statusButton, {
                    [styles.processingStatusSelected]:
                        selectedStage === stage && selectedStatus === BatchStatus.PROCESSING,
                })}
                onClick={(event) => {
                    if (stage !== undefined && onSelectedStageChange) {
                        event.stopPropagation();
                        onSelectedStageChange(stage);
                        setSelectedStatus(BatchStatus.PROCESSING, stage);
                    }
                }}
            >
                <div className={classNames(styles.statusColumn, styles.processing)}>
                    <LoadingSpinnerIcon className={styles.statusIcon} />
                    <div className={getBadgeClasses(BatchStatus.PROCESSING, stage)}>{processingCount || 0}</div>
                </div>
            </Button>

            <Button
                title="Error"
                variant="link"
                className={classNames(styles.statusButton, {
                    [styles.errorStatusSelected]: selectedStage === stage && selectedStatus === BatchStatus.ERROR,
                })}
                onClick={(event) => {
                    if (stage !== undefined && onSelectedStageChange) {
                        event.stopPropagation();
                        onSelectedStageChange(stage);
                        setSelectedStatus(BatchStatus.ERROR, stage);
                    }
                }}
            >
                <div className={classNames(styles.statusColumn, styles.error)}>
                    <Close size={20} className={styles.statusIcon} />
                    <div className={getBadgeClasses(BatchStatus.ERROR, stage)}>{errorCount || 0}</div>
                </div>
            </Button>

            <Button
                title="Success"
                variant="link"
                className={classNames(styles.statusButton, {
                    [styles.processedStatusSelected]:
                        selectedStage === stage && selectedStatus === BatchStatus.PROCESSED,
                })}
                onClick={(event) => {
                    if (stage !== undefined && onSelectedStageChange) {
                        event.stopPropagation();
                        onSelectedStageChange(stage);
                        setSelectedStatus(BatchStatus.PROCESSED, stage);
                    }
                }}
            >
                <div className={classNames(styles.statusColumn, styles.processed)}>
                    <Checkmark size={20} className={styles.statusIcon} />
                    <div className={getBadgeClasses(BatchStatus.PROCESSED, stage)}>{processedCount || 0}</div>
                </div>
            </Button>
        </div>
    );
};

export default BatchStatusCounts;
