import { Project, SelectOption, UserRoles } from '../../Shared/SharedModels';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Select, { ThemeConfig } from 'react-select';

import ApiHelper from '../../Shared/ApiHelper';
import { Form } from 'react-bootstrap';
import { User } from '../TeamMemberModels';
import { sortSelectOptions } from '../../Shared/SharedHelpers';
import { useFormContext } from '../../Shared/contexts/FormProvider';
import { useMsal } from '@azure/msal-react';

interface ProjectsSelectProps {
    formTheme: ThemeConfig;
    shouldAddBottomMargin: boolean;
    editEnabledForAdmin: boolean;
}

const ProjectsSelect: FunctionComponent<ProjectsSelectProps> = (props) => {
    const { formTheme, shouldAddBottomMargin, editEnabledForAdmin } = props;

    const {
        errors,
        formState: { role, organizations, projects },
        register,
        setValue,
    } = useFormContext<User>();

    const areOrganizationsInvalid = !!errors?.projects;

    const [projectOptions, setProjectOptions] = useState<SelectOption<Project>[]>([]);
    const [defaultProjects] = useState<Project[] | undefined>(projects);

    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    useEffect(() => {
        let orgKeys: number[] = [];

        if (organizations?.length) {
            orgKeys = organizations.map((org) => org.organizationKey);
        } else if (defaultProjects?.length) {
            orgKeys = defaultProjects.map((proj) => proj.organizationKey);
        }

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/projects',
            'POST',
            JSON.stringify(orgKeys)
        ).then(async (result: Response) => {
            const projects: Project[] = await result.json();
            const mappedProjects = projects
                .map((proj: Project) => {
                    return {
                        label: proj.projectName,
                        value: proj,
                    } as SelectOption<Project>;
                })
                .sort(sortSelectOptions);
            setProjectOptions(mappedProjects);
        });
    }, [api, defaultProjects, instance, organizations]);

    return (
        <Form.Group className={shouldAddBottomMargin ? 'mb-4' : ''}>
            <Form.Label>Projects</Form.Label>

            <Select
                {...register('projects')}
                isMulti
                isDisabled={!editEnabledForAdmin || !role || role?.roleKey !== UserRoles['Project User']}
                closeMenuOnSelect={false}
                options={projectOptions}
                placeholder="Select Projects..."
                value={projects?.map((proj: Project) => {
                    return {
                        label: proj.projectName,
                        value: proj,
                    };
                })}
                theme={formTheme}
                onChange={(selectedOptions) => {
                    setValue(
                        'projects',
                        selectedOptions.map((option) => option.value)
                    );
                }}
                className={areOrganizationsInvalid ? 'is-invalid' : ''}
                classNamePrefix="base-select"
                menuPlacement="top"
            />
            <Form.Control.Feedback type="invalid">
                {typeof errors?.projects === 'string' && errors?.projects}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default ProjectsSelect;
