import { Badge } from 'react-bootstrap';
import React from 'react';

interface BatchBadgeProps {
    status: boolean | null;
}

const BatchBadge: React.FC<BatchBadgeProps> = ({ status }) => {
    return (
        <Badge bg={status == null ? 'info' : (status ? 'success' : 'danger')} className="batch-runs__badge">
            {status == null ? 'Processing' : (status ? 'Success' : 'Failure')}
        </Badge>
    );
};

export default BatchBadge;
