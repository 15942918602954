import { Cluster, ClusterRecord, ClusterReviewCounts, ClusterStatus } from '../../../DataToolsModels';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import ApiHelper from '../../../../Shared/ApiHelper';
import ClusterReviewTable from './ClusterReviewTable';
import { useMsal } from '@azure/msal-react';

interface ClusterReviewProps {
    projectId: number;
    sourceSystemKey: number | undefined;
}

const ClusterReview: FunctionComponent<ClusterReviewProps> = (props: ClusterReviewProps) => {
    const { projectId, sourceSystemKey } = props;

    const { instance } = useMsal();
    const api = useMemo(() => new ApiHelper(), []);
    const [clusterCounts, setClusterCounts] = useState<ClusterReviewCounts | undefined>(undefined);
    const [clusters, setClusters] = useState<Cluster[]>([]);
    const [loadingClusters, setLoadingClusters] = useState(false);

    const getCounts = useCallback((): void => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_PYTHON_API_URL + `/clusters/${projectId}/counts`
        ).then(async (result: Response) => {
            const counts: ClusterReviewCounts[] = await result.json();

            setClusterCounts(counts[0]);
        });
    }, [api, instance, projectId]);

    const fetchClusters = useCallback(
        (batchSize = 10) => {
            setLoadingClusters(true);
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_PYTHON_API_URL + `/clusters/${projectId}/${batchSize}`
            ).then(async (result: Response) => {
                const responseRecords: ClusterRecord[] = await result.json();
                const clusterMap: Map<number, Cluster> = new Map<number, Cluster>();

                responseRecords.forEach((record) => {
                    if (clusterMap.has(record.canon_id)) {
                        clusterMap.get(record.canon_id)?.clusterRecords.push(record);
                    } else {
                        const newCluster: Cluster = {
                            fullName: `${record.firstname} ${record.lastname}`,
                            confidence: record.cluster_score ?? record.link_score ?? 0,
                            gender: record.gender,
                            ssn: record.ssn,
                            dob: record.dob,
                            clusterId: record.canon_id,
                            clusterStatus: record.confirmed_date
                                ? record.added
                                    ? ClusterStatus.APPROVED
                                    : ClusterStatus.REJECTED
                                : ClusterStatus.UNCONFIRMED,
                            clusterRecords: [record],
                        };
                        clusterMap.set(record.canon_id, newCluster);
                    }
                });

                setClusters(Array.from(clusterMap.values()));
                setLoadingClusters(false);
            });

            getCounts();
        },
        [api, getCounts, instance, projectId]
    );

    useEffect(() => {
        fetchClusters();
    }, [api, fetchClusters, instance, projectId]);

    return (
        <>
            <ClusterReviewTable
                data={clusters}
                loading={loadingClusters}
                fetchData={fetchClusters}
                projectId={projectId}
                updateClusterStatus={(row, status) => {
                    setClusters((prevState) =>
                        prevState.map((value, index) => {
                            if (index === row) {
                                return { ...value, clusterStatus: status };
                            }
                            return value;
                        })
                    );
                    getCounts();
                }}
                clusterCounts={clusterCounts}
                sourceSystemKey={sourceSystemKey}
            />
        </>
    );
};

export default ClusterReview;
