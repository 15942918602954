import './AlertsDropdown.css';

import { Dropdown, NavLink, Toast } from 'react-bootstrap';
import { InformationFilled, Notification } from '@carbon/icons-react';
import { NotificationType, UserNotification } from './TopNavModels';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { AlertUrls } from './AlertModels';
import ApiHelper from '../../Shared/ApiHelper';
import DropdownNavItem from './DropdownNavItem';
import { GetDisplayDate } from '../../Shared/SharedHelpers';
import useInterval from 'react-useinterval';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const AlertsDropdown: FunctionComponent = () => {
    const navigate = useNavigate();
    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    const [isVisible, setIsVisible] = useState(false);
    const [alerts, setAlerts] = useState<UserNotification[]>([]);

    const fetchNotifications = useCallback(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/UserNotification',
            'GET',
            undefined,
            true //suppressToast
        )
            .then(async (result: Response) => {
                const userNotifications: UserNotification[] = await result.json();
                setAlerts(userNotifications);
            })
            .catch((error) => {
                console.error('fetchNotifications has failed', error);
            });
    }, [api, instance]);

    const toggleDropdown = (show: boolean): void => {
        setIsVisible(show);
        if (!show) {
            const alertsToUpdate: UserNotification[] = alerts
                .filter((alert) => !alert.isRead)
                .map((unreadAlert) => {
                    return { ...unreadAlert, isRead: true };
                });

            if (alertsToUpdate.length > 0) {
                api.callApi(
                    instance,
                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                    process.env.REACT_APP_NET_API_URL + '/UserNotification',
                    'PUT',
                    JSON.stringify(alertsToUpdate)
                );
                setAlerts((prevAlerts) =>
                    prevAlerts.map((prevAlert) => {
                        return { ...prevAlert, isRead: true };
                    })
                );
            }
        }
    };

    const handleDismissClick = (alert: UserNotification): void => {
        const dismissedAlert: UserNotification = { ...alert, isDismissed: true };
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/UserNotification',
            'PUT',
            JSON.stringify([dismissedAlert])
        );
        setAlerts((prevAlerts) =>
            prevAlerts.filter((prevAlert) => prevAlert.userNotificationKey !== alert.userNotificationKey)
        );
    };

    const handleAlertClick = (alert: UserNotification): void => {
        if (alert.navigationUrl) {
            const urlSegments = alert.navigationUrl.split(' ');
            if (urlSegments[0] === 'Project') {
                navigate(AlertUrls.Project + urlSegments[1]);
            } else {
                navigate(alert.navigationUrl);
            }
            toggleDropdown(false);
        }
    };

    const getToastClass = (alert: UserNotification): string => {
        const baseClass = 'alerts-dropdown__toast';

        const seenClass = alert.isRead ? `${baseClass}--seen` : '';
        const typeClass = alert.notificationType
            ? `${baseClass}--${NotificationType[alert.notificationType].toLocaleLowerCase()}`
            : '';

        return `my-0 ${baseClass} ${seenClass} ${typeClass}`.trim();
    };

    useInterval(fetchNotifications, 1000 * 45);

    useEffect(() => {
        fetchNotifications();
    }, [fetchNotifications]);

    return (
        <Dropdown show={isVisible} onToggle={toggleDropdown}>
            <Dropdown.Toggle as={DropdownNavItem}>
                <NavLink id="alertsDropdown" data-testid="alertsDropdown">
                    {alerts.find((alert) => !alert.isRead) ? (
                        <img alt="New Alerts!" src="notification-badge.svg" className="nav-item-contents" />
                    ) : (
                        <Notification size={24} className="nav-item-contents top-nav-item-icon" />
                    )}
                    <div className="nav-item-contents top-nav-item-label d-md-none-inverted">Alerts</div>
                </NavLink>
            </Dropdown.Toggle>

            <Dropdown.Menu className="top-nav-dropdown-menu py-0" style={{ width: '275%' }}>
                {alerts.length > 0 ? (
                    alerts.map((alert) => (
                        <Toast
                            className={getToastClass(alert)}
                            key={'userNotification-' + alert.userNotificationKey}
                            onClose={() => handleDismissClick(alert)}
                        >
                            <Toast.Header className="space-between">
                                <div>
                                    <InformationFilled size={20} className="toast-icon" />
                                    <b>{GetDisplayDate(alert.dateCreated)}</b>
                                </div>
                            </Toast.Header>
                            <Toast.Body
                                className={`text-break${alert.navigationUrl ? ' clickable' : ''}`}
                                onClick={() => handleAlertClick(alert)}
                            >
                                {alert.message}
                            </Toast.Body>
                        </Toast>
                    ))
                ) : (
                    <Dropdown.Item disabled style={{ textAlign: 'center' }}>
                        You have no active notifications
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default AlertsDropdown;
