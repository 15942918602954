import { Button } from 'react-bootstrap';
import { ChevronRight } from '@carbon/icons-react';
import React from 'react';

interface ExpandedChevronIconProps {
    isOpen?: boolean;
    onClick?: () => void;
}

const ExpandedChevronIcon: React.FC<ExpandedChevronIconProps> = ({ isOpen = false, ...props }) => (
    <Button variant="link" {...props}>
        <ChevronRight
            size={20}
            className="expand-icon"
            style={{ transform: isOpen ? 'rotate(90deg)' : '', transition: 'transform .2s' }}
        />
    </Button>
);

export default ExpandedChevronIcon;
