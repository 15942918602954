import React, { FunctionComponent, useMemo, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../../Shared/ApiHelper';
import { DQAlertConfigViewModel } from './DataQualityModels';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import RecipientsSelection from '../../Shared/components/RecipientsSelection';
import SaveButton from '../../Shared/components/SaveButton';
import ThresholdInputs from './ThresholdInputs';
import { useMsal } from '@azure/msal-react';

interface DataQualityAlertFormProps {
    activeConfigData: DQAlertConfigViewModel;
    setActiveConfigData: React.Dispatch<React.SetStateAction<DQAlertConfigViewModel | undefined>>;
    setAlertConfigurations: React.Dispatch<React.SetStateAction<DQAlertConfigViewModel[]>>;
    label: string;
}

const DataQualityAlertForm: FunctionComponent<DataQualityAlertFormProps> = (props: DataQualityAlertFormProps) => {
    const { activeConfigData, setActiveConfigData, setAlertConfigurations, label } = props;

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    const [isSaving, setIsSaving] = useState(false);

    return (
        <div className="px-5 active-config">
            <div className="flex-row space-between pt-3">
                <h3>{label}</h3>
                <SaveButton
                    isSaving={isSaving}
                    onClick={() => {
                        setIsSaving(true);
                        api.callApi(
                            instance,
                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                            process.env.REACT_APP_NET_API_URL + '/DQAlertConfig',
                            'PUT',
                            JSON.stringify(activeConfigData)
                        ).then(async (_result: Response) => {
                            toast.success('Configuration Saved Successfully', {
                                position: 'top-right',
                                autoClose: 5000,
                                transition: Slide,
                                draggable: false,
                                closeOnClick: false,
                                theme: 'colored',
                            });
                            setIsSaving(false);

                            setAlertConfigurations((configs) =>
                                configs.map((config) => {
                                    if (config.alertConfigKey === activeConfigData.alertConfigKey) {
                                        return activeConfigData;
                                    }

                                    return config;
                                })
                            );
                        });
                    }}
                />
            </div>
            <Form>
                <div className="py-2 my-2">
                    <div className="flex-row flex-wrap">
                        <RecipientsSelection
                            label="Recipients"
                            value={
                                activeConfigData.recipients
                                    ? activeConfigData.recipients.split(',').map((recipient) => {
                                          return {
                                              label: recipient,
                                              value: recipient,
                                          };
                                      })
                                    : []
                            }
                            onChange={(selectedOptions) => {
                                setActiveConfigData((activeConfig) => {
                                    return activeConfig
                                        ? {
                                              ...activeConfig,
                                              recipients: selectedOptions.map((option) => option.value).join(','),
                                          }
                                        : undefined;
                                });
                            }}
                        />
                        <Form.Group className="pr-3">
                            <Form.Label>Alert Start Date</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="MMM dd, yyyy"
                                selected={new Date(activeConfigData.effectiveStartDate)}
                                onChange={(date: Date) => {
                                    setActiveConfigData((activeConfigData) => {
                                        return activeConfigData
                                            ? {
                                                  ...activeConfigData,
                                                  effectiveStartDate: date.toISOString(),
                                              }
                                            : undefined;
                                    });
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="pr-3">
                            <Form.Label>Alert End Date</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="MMM dd, yyyy"
                                selected={new Date(activeConfigData.effectiveEndDate)}
                                onChange={(date: Date) => {
                                    setActiveConfigData((activeConfigData) => {
                                        return activeConfigData
                                            ? {
                                                  ...activeConfigData,
                                                  effectiveEndDate: date.toISOString(),
                                              }
                                            : undefined;
                                    });
                                }}
                            />
                        </Form.Group>
                    </div>
                </div>
                {activeConfigData &&
                    activeConfigData.configData.map((elementConfig) => {
                        return (
                            <div key={elementConfig.element + '-row'} className="project-card px-3 py-2 my-2">
                                <h4>{elementConfig.element}</h4>

                                <ThresholdInputs
                                    elementConfig={elementConfig}
                                    setActiveConfigData={setActiveConfigData}
                                />
                            </div>
                        );
                    })}
            </Form>
        </div>
    );
};

export default DataQualityAlertForm;
