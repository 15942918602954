import './ProjectCard.css';

import { Button, Card } from 'react-bootstrap';
import React, { FunctionComponent, useEffect, useState } from 'react';

interface ProjectCardProps {
    title: string;
    body: string;
    projectType: 'Dedupe' | 'Link' | 'Alert' | 'Category' | 'Upload' | 'SourceSystems' | 'Project';
    closable?: boolean;
    hideButton?: boolean;
    onClose?: () => void;
    onClick: () => void;
    children?: React.ReactNode;
}

const ProjectCard: FunctionComponent<ProjectCardProps> = (props: ProjectCardProps) => {
    const { title, body, projectType, closable, hideButton, onClose, onClick, children } = props;

    const [imageSrc, setImageSrc] = useState('DeduplicateIcon.svg');

    useEffect(() => {
        switch (projectType) {
            case 'Dedupe':
                setImageSrc('DeduplicateIcon.svg');
                break;
            case 'Link':
                setImageSrc('LinkClientsIcon.svg');
                break;
            case 'Alert':
                setImageSrc('NotificationIcon2.svg');
                break;
            case 'Category':
                setImageSrc('CategoriesIcon.svg');
                break;
            case 'Upload':
                setImageSrc('file-upload.svg');
                break;
            case 'SourceSystems':
                setImageSrc('SourceSystems.svg');
                break;
            case 'Project':
                setImageSrc('Project.svg');
                break;
        }
    }, [projectType]);

    return (
        <Card className="text-center mx-3 my-1">
            {closable && <Button className="btn-close project-close-button" onClick={onClose} variant="link"></Button>}
            <Card.Img
                className={closable ? '' : 'card-top-padding'}
                alt="Project Card Icon"
                height="64"
                variant="top"
                src={imageSrc}
            />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{body}</Card.Text>
                {!hideButton && (
                    <Button className="auto-margin-top" variant="outline-primary" onClick={onClick}>
                        {children ?? title}
                    </Button>
                )}
            </Card.Body>
        </Card>
    );
};

export default ProjectCard;
