import React, { FunctionComponent, useEffect, useState } from 'react';
import Select, { ThemeConfig } from 'react-select';

import ApiHelper from '../../Shared/ApiHelper';
import { CustomerEnvironment } from '../../Navigation/TopNav/TopNavModels';
import { Form } from 'react-bootstrap';
import { SelectOption } from '../../Shared/SharedModels';
import { User } from '../TeamMemberModels';
import { useFormContext } from '../../Shared/contexts/FormProvider';
import { useMsal } from '@azure/msal-react';

interface DefaultCustomerSelectProps {
    editMode: boolean;
    formTheme: ThemeConfig;
}

const DefaultCustomerSelect: FunctionComponent<DefaultCustomerSelectProps> = (props) => {
    const { editMode, formTheme } = props;
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    const {
        errors,
        formState: { defaultCustomerId },
        register,
        setValue,
    } = useFormContext<User>();

    const hasError = !!errors?.defaultCustomerId;

    const [customerEnvironments, setCustomerEnvironments] = useState<SelectOption<string>[]>([]);
    const [loadingEnvironments, setLoadingEnvironments] = useState(true);

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/customers'
        ).then(async (result: Response) => {
            const customers: CustomerEnvironment[] = await result.json();
            setCustomerEnvironments(
                customers.map((env: CustomerEnvironment) => {
                    return { label: env.customerId, value: env.customerId };
                })
            );
            setLoadingEnvironments(false);
        });
    }, [api, instance]);

    return (
        <Form.Group className={editMode ? 'mb-4' : ''}>
            <Form.Label>Default Customer</Form.Label>

            <Select
                {...register('defaultCustomerId')}
                options={customerEnvironments}
                isLoading={loadingEnvironments}
                placeholder="Select Default Customer..."
                value={{ label: defaultCustomerId, value: defaultCustomerId }}
                theme={formTheme}
                onChange={(selectedOption) => setValue('defaultCustomerId', selectedOption?.value)}
                className={hasError ? 'is-invalid' : ''}
                classNamePrefix="base-select"
                menuPlacement="auto"
            />

            <Form.Control.Feedback type="invalid">{errors?.defaultCustomerId}</Form.Control.Feedback>
        </Form.Group>
    );
};

export default DefaultCustomerSelect;
