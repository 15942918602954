import { PipelineTriggerDetails, generateScheduleText } from '../../Shared/PipelineScheduleParser';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';

import { AddFilled } from '@carbon/icons-react';
import ApiHelper from '../../Shared/ApiHelper';
import { Button } from 'react-bootstrap';
import { CellContext } from '@tanstack/react-table';
import DataTable from '../../Shared/components/DataTable';
import { PipelineRun } from '../../ClientService/Models/CanonicalRunModels';
import { UserProfile } from '../../Shared/SharedModels';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { formatDateString } from '../../Shared/SharedHelpers';
import { useMsal } from '@azure/msal-react';

const Canonical: FunctionComponent = () => {
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());
    const [runHistoryData, setRunHistoryData] = useState<PipelineRun[]>([]);
    const [runScheduleData, setRunScheduleData] = useState<PipelineTriggerDetails>();
    const [runHistoryLoading, setRunHistoryLoading] = useState<boolean>(true);
    const [runDisabled, setRunDisabled] = useState<boolean>(false);
    const API_ENDPOINT = `${process.env.REACT_APP_NET_API_URL}/adfpipeline/canonicalsync`;
    const userProfile: UserProfile = useContext(UserProfileContext);

    const getRunHistory = useCallback(async (): Promise<void> => {
        setRunHistoryLoading(true);
        api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], API_ENDPOINT)
            .then(async (result: Response) => {
                const runHistory: PipelineRun[] = await result.json();
                setRunHistoryData(runHistory);
                setRunHistoryLoading(false);
                const hasCurrentRun = runHistory?.find((run) => run.status === 'InProgress');
                setRunDisabled(hasCurrentRun !== undefined);
            })
            .finally(() => {
                setRunHistoryLoading(false);
            });
    }, [API_ENDPOINT, api, instance]);

    const getRunSchedule = useCallback(async (): Promise<void> => {
        api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], `${API_ENDPOINT}/schedule`).then(
            async (result: Response) => {
                const runSchedule: PipelineTriggerDetails = await result.json();
                setRunScheduleData(runSchedule);
            }
        );
    }, [API_ENDPOINT, api, instance]);

    useEffect(() => {
        getRunHistory();
        getRunSchedule();
    }, [getRunHistory, getRunSchedule]);

    const startRun = (): void => {
        setRunDisabled(true);
        api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], API_ENDPOINT, 'POST')
            .then(async (result: Response) => {
                const updatedHistory: PipelineRun[] = await result.json();
                setRunHistoryData(updatedHistory);
            })
            .catch(() => setRunDisabled(false));
    };

    return (
        <div className="content-column-container">
            <div className="content-header">
                <h1>Sync To ClientTrack</h1>
                <Button variant="outline-primary" onClick={startRun} disabled={runDisabled}>
                    <AddFilled size={24} className="button-icon-padding" /> Start Run
                </Button>
            </div>
            <p className="data-tools-description">
                {`This page is to monitor the process that syncs your data from ClientInsight to ClientTrack`}
            </p>
            <p className="data-tools-description">{generateScheduleText(runScheduleData, userProfile)}</p>
            <div>
                <div className="project-card-body">
                    <DataTable
                        refreshData={getRunHistory}
                        label="Syncs"
                        data={runHistoryData}
                        recordsAreClickable={false}
                        columns={[
                            {
                                header: 'Run Id',
                                accessorKey: 'runId',
                            },
                            {
                                header: 'Run Start',
                                accessorKey: 'runStart',
                                cell: ({ row }: CellContext<PipelineRun, unknown>) =>
                                    formatDateString(row?.original?.runStart, userProfile.environmentConfig.timeZone),
                            },
                            {
                                header: 'Run End',
                                accessorKey: 'runEnd',
                                cell: ({ row }: CellContext<PipelineRun, unknown>) =>
                                    row.original.status !== 'Succeeded' && row.original.status !== 'Cancelled'
                                        ? '-'
                                        : formatDateString(
                                              row?.original?.runEnd,
                                              userProfile.environmentConfig.timeZone
                                          ),
                            },
                            {
                                header: 'Duration (mins)',
                                accessorKey: 'durationInMs',
                                cell: ({ row }: CellContext<PipelineRun, unknown>) =>
                                    Math.ceil(row.original.durationInMs / 100 / 60 / 60),
                            },
                            {
                                header: 'Status',
                                accessorKey: 'status',
                            },
                            {
                                header: 'Message',
                                accessorKey: 'message',
                            },
                        ]}
                        loading={runHistoryLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default Canonical;
