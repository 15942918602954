import React, { FunctionComponent } from 'react';

import { Breadcrumb } from 'react-bootstrap';
import { ChevronRight } from '@carbon/icons-react';
import ProjectContentFooter from '../Dedupe/ProjectSteps/ProjectContentFooter';
import ProjectContentHeader from '../Dedupe/ProjectSteps/ProjectContentHeader';
import ProjectProgressBar from '../Shared/ProjectProgressBar';
import { useNavigate } from 'react-router-dom';

const FileUploadSuccess: FunctionComponent = () => {
    const navigate = useNavigate();

    return (
        <div className="content-column-container">
            <div className="flex-row">
                <Breadcrumb className="breadcrumb-z-index">
                    <Breadcrumb.Item
                        onClick={() => {
                            navigate('/datatools');
                        }}
                    >
                        Projects
                    </Breadcrumb.Item>
                    <ChevronRight size={24} className="breadcrumb-chevron" />
                    <Breadcrumb.Item
                        onClick={() => {
                            navigate('/datatools/upload');
                        }}
                    >
                        Upload Files
                    </Breadcrumb.Item>
                    <ChevronRight size={24} className="breadcrumb-chevron" />
                    <Breadcrumb.Item active>Success</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="project-card">
                <ProjectContentHeader header="Upload Files from Selected Source System" />
                <div className="project-card-body">
                    <ProjectProgressBar
                        progress={100}
                        header={'File Upload Success'}
                        progressDescription={''}
                        finishedDescription={'Click continue to upload more files'}
                    />
                </div>
                <ProjectContentFooter
                    disableContinue={false}
                    continueCallback={() => {
                        navigate('/datatools/upload');
                    }}
                />
            </div>
        </div>
    );
};

export default FileUploadSuccess;
