import { Button, Modal, Spinner } from 'react-bootstrap';
import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../Shared/ApiHelper';
import { DedupeProject } from './DataToolsModels';
import { useMsal } from '@azure/msal-react';

interface AbandonProjectModalProps {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    setProjects: Dispatch<SetStateAction<DedupeProject[]>>;
    projectId: number;
    setProjectId: Dispatch<SetStateAction<number | undefined>>;
}

const AbandonProjectModal: FunctionComponent<AbandonProjectModalProps> = (props) => {
    const { show, setShow, setProjects, projectId, setProjectId } = props;

    const [loading, setLoading] = useState(false);

    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    return (
        <Modal scrollable show={show} onHide={() => setShow(false)} onExited={() => setProjectId(undefined)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to abandon this project?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button
                    variant="link"
                    onClick={() => {
                        setShow(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="outline-danger"
                    disabled={loading}
                    onClick={() => {
                        setLoading(true);
                        api.callApi(
                            instance,
                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                            process.env.REACT_APP_PYTHON_API_URL + `/project/abandon/${projectId}`,
                            'PUT'
                        ).then(async (_response) => {
                            setProjects((prevProjects) => prevProjects.filter((p) => p.project_key != projectId));
                            toast.success('Project abandoned succesfully', {
                                position: 'top-right',
                                autoClose: 5000,
                                transition: Slide,
                                draggable: false,
                                closeOnClick: false,
                                theme: 'colored',
                            });
                            setShow(false);
                        });
                    }}
                >
                    {loading ? (
                        <>
                            <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
                            Deleting
                        </>
                    ) : (
                        <>Delete</>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AbandonProjectModal;
