import { Md5 } from 'ts-md5';
import ReactGA4 from 'react-ga4';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID || '';
//const isProduction = process.env.NODE_ENV === 'production';

const simpleHash = (str: string): string => {
    return Md5.hashAsciiStr(str);
};

export const init = (): void => ReactGA4.initialize(TRACKING_ID);

export const GAInformation = (username: string): void =>
    ReactGA4.set({
        user_id: simpleHash(username),
    });

export const sendEvent = (category: string, action: string, label: string): void =>
    ReactGA4.event({
        label,
        action,
        category,
    });

export const sendPageview = (path: string): void =>
    ReactGA4.send({
        hitType: 'pageview',
        page: path,
    });
