import './ProjectHistory.css';

import { Breadcrumb, Button } from 'react-bootstrap';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { ChevronRight, Download } from '@carbon/icons-react';
import { DedupeProject, ProjectHistoryStatus } from '../DataToolsModels';
import { PipelineTriggerDetails, generateScheduleText } from '../../Shared/PipelineScheduleParser';
import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserProfileContext, isGlobalAdmin } from '../../Shared/contexts/UserProfile';

import ApiHelper from '../../Shared/ApiHelper';
import DataTable from '../../Shared/components/DataTable';
import ProjectHistoryFilters from './ProjectHistoryFilters';
import { formatDateString } from '../../Shared/SharedHelpers';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const ProjectHistory: FunctionComponent = () => {
    const [projects, setProjects] = useState<DedupeProject[]>([]);
    const [filteredProjects, setFilteredProjects] = useState<DedupeProject[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [runScheduleData, setRunScheduleData] = useState<PipelineTriggerDetails>();

    const API_ENDPOINT = `${process.env.REACT_APP_NET_API_URL}/adfpipeline/autodedupe`;

    const navigate = useNavigate();
    const { instance } = useMsal();
    const api = useMemo(() => new ApiHelper(), []);
    const userProfile = useContext(UserProfileContext);

    const getProjectData = useCallback(() => {
        setIsLoading(true);
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_PYTHON_API_URL + `/projects?includeHistory=True`
        ).then(async (projectResponse) => {
            const projects: DedupeProject[] = await projectResponse.json();

            setProjects(projects);
            setIsLoading(false);
        });
    }, [api, instance]);

    const getRunSchedule = useCallback(async (): Promise<void> => {
        api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], `${API_ENDPOINT}/schedule`).then(
            async (result: Response) => {
                const runSchedule: PipelineTriggerDetails = await result.json();
                setRunScheduleData(runSchedule);
            }
        );
    }, [API_ENDPOINT, api, instance]);

    useEffect(() => {
        getProjectData();
        getRunSchedule();
    }, [getProjectData, getRunSchedule]);

    const convertBooleanColumnToYesNo = ({ getValue }: CellContext<DedupeProject, unknown>): JSX.Element => (
        <>{getValue() ? 'Yes' : 'No'}</>
    );

    const tableColumns: ColumnDef<DedupeProject>[] = useMemo(
        () => [
            { header: 'Project Name', accessorKey: 'name' },
            {
                header: 'Source System',
                accessorKey: 'source_system_key',
                cell: ({ getValue }: CellContext<DedupeProject, unknown>) => (
                    <>
                        {getValue() == 0
                            ? 'All'
                            : userProfile.sourceSystems.find((system) => system.sourceSystemKey == getValue())
                                  ?.sourceSystemName}
                    </>
                ),
            },
            { header: 'Created By', accessorKey: 'created_by' },
            {
                header: 'Date',
                accessorKey: 'created_time',
                cell: ({ row }: CellContext<DedupeProject, unknown>) => {
                    return (
                        <>
                            {row.original.created_time !== undefined &&
                                formatDateString(row.original.created_time, userProfile.environmentConfig.timeZone)}
                        </>
                    );
                },
            },
            { header: 'Merged to UCI', accessorKey: 'used_for_mci', cell: convertBooleanColumnToYesNo },
            { header: 'Training Completed', accessorKey: 'full_dedupe', cell: convertBooleanColumnToYesNo },
            {
                header: 'Status',
                accessorKey: 'status',
                cell: ({ row }: CellContext<DedupeProject, unknown>) =>
                    row.original.status !== undefined && (
                        <div className={'status-badge ' + ProjectHistoryStatus[row.original.status].toLowerCase()}>
                            {ProjectHistoryStatus[row.original.status]}
                        </div>
                    ),
            },
            {
                id: 'Download',
                enableSorting: false,
                cell: ({ row }: CellContext<DedupeProject, unknown>) =>
                    row.original.status === ProjectHistoryStatus.Complete ? (
                        <Button
                            variant="primary"
                            href={process.env.REACT_APP_PYTHON_API_URL + `/download/${row.original.project_key}`}
                        >
                            <Download size={24} />
                        </Button>
                    ) : (
                        <></>
                    ),
            },
        ],
        [userProfile.environmentConfig.timeZone, userProfile.sourceSystems]
    );

    return (
        <div className="content-column-container">
            <div className="content-header">
                <Breadcrumb className="breadcrumb-z-index">
                    <Breadcrumb.Item
                        onClick={() => {
                            navigate('/datatools');
                        }}
                    >
                        Projects
                    </Breadcrumb.Item>
                    <ChevronRight size={24} className="breadcrumb-chevron" />
                    <Breadcrumb.Item active>Project History</Breadcrumb.Item>
                </Breadcrumb>
                {isGlobalAdmin(userProfile) && (
                    <Button
                        className="file-download-button"
                        variant="outline-primary"
                        href={process.env.REACT_APP_PYTHON_API_URL + `/uci/download`}
                    >
                        <Download size={24} className="button-icon-padding" />
                        Download UCI
                    </Button>
                )}
            </div>

            <h1>Project History</h1>
            <p className="data-tools-description">{generateScheduleText(runScheduleData, userProfile)}</p>
            <ProjectHistoryFilters masterProjectsList={projects} setFilteredProjectsList={setFilteredProjects} />
            <DataTable
                data-testid="projectHistoryTable"
                data={filteredProjects}
                columns={tableColumns}
                loading={isLoading}
                refreshData={getProjectData}
            />
        </div>
    );
};

export default ProjectHistory;
