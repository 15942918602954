import * as analytics from '../../Utils/googleAnalytics4Wrapper';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

export default function useAnalytics(): void {
    const {
        accounts: [user],
    } = useMsal();
    const location = useLocation();

    useEffect(() => {
        analytics.init();
    }, []);

    useEffect(() => {
        analytics.GAInformation(typeof user?.idTokenClaims?.email === 'string' ? user.idTokenClaims.email : '');
    }, [user]);

    useEffect(() => {
        const path = location.pathname + location.search;
        analytics.sendPageview(path);
    }, [location]);
}
