import React, { FunctionComponent, useEffect, useState } from 'react';
import { UserProfileContext, useUserProfile } from './Shared/contexts/UserProfile';

import { Container } from 'react-bootstrap';
import { InteractionType } from '@azure/msal-browser';
import LeftNav from './Navigation/LeftNav/LeftNav';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { RoutesApp } from './Routes/RoutesApp';
import SplashScreen from './Shared/components/SplashScreen';
import { ToastContainer } from 'react-toastify';
import TopNav from './Navigation/TopNav/TopNav';
import useAnalytics from './Shared/hooks/useAnalytics';
import useClarity from './Shared/hooks/useClarity';
import useWindowDimensions from './Shared/WindowDimensions';

const App: FunctionComponent = () => {
    const userProfile = useUserProfile();
    const [showLeftNav, setShowLeftNav] = useState(true);
    const windowDimensions = useWindowDimensions();

    useClarity();
    useAnalytics();

    useEffect(() => {
        if (windowDimensions.width > 768) {
            setShowLeftNav(true);
        } else if (windowDimensions.width <= 454) {
            setShowLeftNav(false);
        }
    }, [windowDimensions.width]);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <UserProfileContext.Provider value={userProfile}>
                {userProfile.user ? (
                    <div id="wrapper">
                        {showLeftNav && <LeftNav />}
                        <div id="content-wrapper" className="d-flex flex-column">
                            <TopNav setShowLeftNav={setShowLeftNav} />
                            {!userProfile.isLoading && (
                                <Container fluid>
                                    <RoutesApp />
                                    <ToastContainer position="top-right" autoClose={false} newestOnTop />
                                </Container>
                            )}
                        </div>
                    </div>
                ) : (
                    <SplashScreen failedToLoad={userProfile.failedToLoad} />
                )}
            </UserProfileContext.Provider>
        </MsalAuthenticationTemplate>
    );
};

export default App;
