import './ListField.css';

import React, { KeyboardEvent, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Form } from 'react-bootstrap';
import { OnChangeValue } from 'react-select';

interface ListFieldProps {
    className?: string;
    error?: string[] | string;
    label?: string;
    placeholder?: string;
    value?: string[];
    onBlur?(): void;
    onChange?(value: string[]): void;
}

interface Option {
    label: string;
    value: string;
}

const ListField: React.FC<ListFieldProps> = ({
    className,
    error,
    label,
    placeholder = '',
    value = [],
    onBlur,
    onChange,
}) => {
    const [text, setText] = useState('');

    const canAddText = !!text && !value?.includes(text);

    const handleAdd = (): void => {
        if (onChange) {
            onChange([...value, text]);
            setText('');
        }
    };

    const handleChange = (newValue: OnChangeValue<Option, true>): void => {
        if (onChange) {
            onChange(newValue.map((option) => option.value));
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleAdd();
        }
    };

    return (
        <Form.Group className={`list-field__container ${className}`}>
            {!!label && <Form.Label>{label}</Form.Label>}

            <CreatableSelect
                isMulti
                isClearable
                menuIsOpen={canAddText}
                components={{ DropdownIndicator: null }}
                classNamePrefix="base-select"
                className={error ? `is-invalid` : ''}
                value={value.map((value) => ({ label: value, value }))}
                inputValue={text}
                onBlur={onBlur}
                placeholder={placeholder}
                onChange={handleChange}
                onInputChange={setText}
                onKeyDown={handleKeyDown}
            />

            <Form.Control.Feedback type="invalid">
                {Array.isArray(error) ? error.find((message) => message) : error}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default ListField;
