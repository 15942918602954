import React, { FunctionComponent, useCallback, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../../Shared/ApiHelper';
import { Breadcrumb } from 'react-bootstrap';
import { ChevronRight } from '@carbon/icons-react';
import { DedupeSourceSystem } from '../../Shared/SharedModels';
import ProjectContentFooter from './ProjectSteps/ProjectContentFooter';
import ProjectContentHeader from './ProjectSteps/ProjectContentHeader';
import ProjectParametersForm from './ProjectSteps/ProjectParameters/ProjectParametersForm';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const UniqueIndexCreation: FunctionComponent = () => {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const [projectSourceSystem, setProjectSourceSystem] = useState<DedupeSourceSystem | undefined>(undefined);
    const [disableContinue, setDisableContinue] = useState(true);

    const createUniqueClientIndex = useCallback(() => {
        if (projectSourceSystem) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_PYTHON_API_URL + `/uci/create/${projectSourceSystem.key}`
            ).then(async (_result: Response) => {
                navigate('/datatools');
                toast.success('Unique Client Index Created Successfully', {
                    position: 'top-right',
                    autoClose: 5000,
                    transition: Slide,
                    draggable: false,
                    closeOnClick: false,
                    theme: 'colored',
                });
            });
        }
    }, [api, instance, navigate, projectSourceSystem]);

    return (
        <div className="content-column-container">
            <div className="flex-row">
                <Breadcrumb className="breadcrumb-z-index">
                    <Breadcrumb.Item
                        onClick={() => {
                            navigate('/datatools');
                        }}
                    >
                        Projects
                    </Breadcrumb.Item>
                    <ChevronRight size={24} className="breadcrumb-chevron" />
                    <Breadcrumb.Item active>Create Unique Client Index</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="project-card">
                <ProjectContentHeader header="Select Dataset for Unique Client Index Creation" />
                <div className="project-card-body">
                    <ProjectParametersForm
                        projectSourceSystem={projectSourceSystem}
                        setProjectSourceSystem={setProjectSourceSystem}
                        setDisableContinue={setDisableContinue}
                    />
                </div>
                <ProjectContentFooter disableContinue={disableContinue} continueCallback={createUniqueClientIndex} />
            </div>
        </div>
    );
};

export default UniqueIndexCreation;
