import './LeftNavItem.css';

import { Link, Location, useLocation } from 'react-router-dom';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { ChevronRight } from '@carbon/icons-react';
import { LeftNavSubItem } from './NavigationModels';
import { NavItem } from 'react-bootstrap';

interface LeftNavItemProps {
    children?: React.ReactNode;
    linkUrl: string;
    expandable?: boolean;
    subItems?: LeftNavSubItem[];
}

const LeftNavItem: FunctionComponent<LeftNavItemProps> = (props: LeftNavItemProps) => {
    const { children, linkUrl, expandable, subItems } = props;

    const location: Location = useLocation();
    const [isActive, setIsActive] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setIsActive((location.pathname.startsWith(linkUrl) && linkUrl != '/') || location.pathname === linkUrl);
    }, [location.pathname, linkUrl]);

    useEffect(() => {
        if (!isActive) {
            setIsExpanded(false);
        }
    }, [isActive]);

    return (
        <>
            <NavItem className={isActive ? 'active-nav-item-custom' : ''}>
                <Link
                    className="nav-link"
                    to={linkUrl}
                    onClick={(event) => {
                        if (expandable) {
                            event.preventDefault();
                            setIsExpanded((prevValue) => !prevValue);
                        }
                    }}
                >
                    {children}
                    {expandable ? (
                        <ChevronRight
                            size={24}
                            className={`expansion-icon d-md-none-inverted ${
                                isExpanded ? 'icon-to-down' : 'icon-to-right'
                            }`}
                        />
                    ) : (
                        <></>
                    )}
                </Link>
            </NavItem>
            <NavItem className="collapse-popover-nav-item">
                <div className={`collapse${isExpanded ? ' show' : ''}`}>
                    <div className="collapse-popover bg-white rounded">
                        {isExpanded &&
                            subItems?.map((subItem: LeftNavSubItem) => {
                                const subItemIsActive = location.pathname === subItem.link;

                                return (
                                    <NavItem
                                        className={`${subItemIsActive ? 'active ' : ''}`}
                                        key={`left-nav-${subItem.label}`}
                                    >
                                        <Link className="nav-link sub-nav-item sub-nav-item-padding" to={subItem.link}>
                                            {subItem.label}
                                        </Link>
                                    </NavItem>
                                );
                            })}
                    </div>
                </div>
            </NavItem>
        </>
    );
};

export default LeftNavItem;
