import React, { FunctionComponent, createContext, useContext, useEffect, useState } from 'react';

import ApiHelper from '../ApiHelper';
import { useMsal } from '@azure/msal-react';

export interface ServiceCard {
    service: 'hmis' | 'ehr';
    serviceColumns: string[];
}

interface CardContextState {
    serviceCards: ServiceCard[];
    setServiceCardByIndex(index: number, serviceCard: ServiceCard): void;
}

export interface ClientProfileCardContextProps {
    children?: React.ReactNode;
}

export const ClientProfileCardsContext = createContext<CardContextState>({} as CardContextState);

export const ClientProfileCardsProvider: FunctionComponent<ClientProfileCardContextProps> = ({ children }) => {
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const [serviceCards, setServiceCards] = useState<ServiceCard[]>(
        JSON.parse('[{"service":"hmis","serviceColumns":[]},{"service":"ehr","serviceColumns":[]}]')
    );

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + `/UserProfile/client360card`
        ).then(async (result: Response) => {
            setServiceCards(await result.json());
        });
    }, [api, instance]);

    const setServiceCardByIndex = (index: number, serviceCard: ServiceCard): void => {
        const newServiceCards = [...serviceCards];
        newServiceCards[index] = serviceCard;

        setServiceCards(newServiceCards);
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + `/UserProfile/client360card`,
            'PUT',
            JSON.stringify(newServiceCards)
        );
    };

    return (
        <ClientProfileCardsContext.Provider value={{ serviceCards, setServiceCardByIndex }}>
            {children}
        </ClientProfileCardsContext.Provider>
    );
};

export const withCardProvider = (Component: React.FC): React.FC => {
    const Wrapper: React.FC = (props) => (
        <ClientProfileCardsProvider>
            <Component {...props} />
        </ClientProfileCardsProvider>
    );

    return Wrapper;
};

export function useCardsContext(): CardContextState {
    return useContext(ClientProfileCardsContext);
}
