import React, { FunctionComponent } from 'react';

import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface ProjectContentFooterProps {
    disableContinue: boolean;
    continueCallback: () => void;
}

const ProjectContentFooter: FunctionComponent<ProjectContentFooterProps> = (props: ProjectContentFooterProps) => {
    const { disableContinue, continueCallback } = props;
    const navigate = useNavigate();

    return (
        <div className="project-card-footer">
            <Button
                variant="link"
                className="mr-2"
                onClick={() => {
                    navigate('/datatools');
                }}
            >
                Cancel
            </Button>
            <Button
                variant="primary"
                className="ml-2"
                disabled={disableContinue}
                onClick={() => {
                    continueCallback();
                }}
            >
                Continue
            </Button>
        </div>
    );
};

export default ProjectContentFooter;
