/* eslint-disable react-hooks/rules-of-hooks */

import './ClusterReviewTable.css';

import { Button, Table } from 'react-bootstrap';
import {
    CellContext,
    ColumnDef,
    RowData,
    getCoreRowModel,
    getExpandedRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import {
    CheckmarkFilled,
    CheckmarkOutline,
    ChevronDown,
    ChevronRight,
    ErrorFilled,
    ErrorOutline,
} from '@carbon/icons-react';
import { Cluster, ClusterReviewCounts, ClusterStatus } from '../../../DataToolsModels';
import { GetDisplayDate, GetDisplaySsn } from '../../../../Shared/SharedHelpers';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import ApiHelper from '../../../../Shared/ApiHelper';
import ClusterReviewTableBody from './ClusterReviewTableBody';
import ClusterReviewTableFooter from './ClusterReviewTableFooter';
import ClusterReviewTableHeader from './ClusterReviewTableHeader';
import { ColumnBreakpoints } from '../../../../Shared/components/DataTable';
import { Gender } from '../../../../Shared/SharedModels';
import MassApprovalModal from './MassApprovalModal';
import { useMsal } from '@azure/msal-react';

interface ClusterReviewTableProps {
    data: Cluster[];
    loading: boolean;
    fetchData: (pageSize: number) => void;
    projectId: number;
    updateClusterStatus: (row: number, status: ClusterStatus) => void;
    clusterCounts?: ClusterReviewCounts;
    sourceSystemKey: number | undefined;
}

declare module '@tanstack/table-core' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface TableMeta<TData extends RowData> {
        updateClusterStatus?: (row: number, status: ClusterStatus) => void;
    }
}

function ClusterReviewTable(props: ClusterReviewTableProps): ReactElement {
    const { data, loading, fetchData, projectId, updateClusterStatus, clusterCounts, sourceSystemKey } = props;
    const { instance } = useMsal();

    const [showMassApprovalModal, setShowMassApprovalModal] = useState(false);

    const api = useMemo(() => new ApiHelper(), []);
    const columns = useMemo<ColumnDef<Cluster>[]>(
        () => [
            {
                header: () => null,
                id: 'expander',
                cell: ({ row }: CellContext<Cluster, unknown>) => (
                    <Button
                        variant="link"
                        onClick={() => {
                            row.toggleExpanded();
                        }}
                        className="clickable"
                    >
                        {row.getIsExpanded() ? (
                            <ChevronDown size={20} className="expand-icon" />
                        ) : (
                            <ChevronRight size={20} className="expand-icon" />
                        )}
                    </Button>
                ),
            },
            { header: 'Full Name', accessorKey: 'fullName' },
            {
                header: 'Confidence',
                accessorKey: 'confidence',
                cell: ({ row }: CellContext<Cluster, unknown>) => {
                    return (
                        <div
                            className={`cluster-confidence ${
                                row.original.confidence > 0.75
                                    ? row.original.confidence > 0.9
                                        ? 'high'
                                        : 'medium'
                                    : 'low'
                            }`}
                        >
                            {row.original.confidence.toLocaleString(undefined, {
                                style: 'percent',
                                minimumFractionDigits: 2,
                            })}
                        </div>
                    );
                },
            },
            {
                header: 'Gender',
                accessorKey: 'gender',
                cell: ({ row }: CellContext<Cluster, unknown>) => (
                    <div>{row.original.gender !== undefined && Gender[row.original.gender as number]}</div>
                ),
            },
            {
                header: 'SSN',
                accessorKey: 'ssn',
                cell: ({ row }: CellContext<Cluster, unknown>) => {
                    return <div>{GetDisplaySsn(row.original.ssn as string)}</div>;
                },
            },
            {
                header: 'DOB',
                accessorKey: 'dob',
                cell: ({ row }: CellContext<Cluster, unknown>) => {
                    return <div>{GetDisplayDate(row.original.dob as string)}</div>;
                },
            },
            {
                header: 'Reviewed By',
                accessorKey: 'reviewedBy',
                cell: ({ row }: CellContext<Cluster, unknown>) => <div>{row.original.reviewedBy ?? '-'}</div>,
            },
            {
                header: 'Action',
                accessorKey: 'clusterStatus',
                cell: ({ getValue, row, table }: CellContext<Cluster, unknown>) => {
                    const [value, setValue] = useState(getValue());

                    useEffect(() => {
                        setValue(getValue());
                    }, [getValue]);
                    const nonCanonRecords = row.original.clusterRecords.filter((r) => !r.canon);

                    return (
                        <>
                            {value === ClusterStatus.APPROVED ? (
                                <CheckmarkFilled size={24} className="mx-2 clickable confirm-action-active" />
                            ) : (
                                <CheckmarkOutline
                                    size={24}
                                    className="mx-2 clickable cluster-action-inactive"
                                    onClick={() => {
                                        api.callApi(
                                            instance,
                                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                            process.env.REACT_APP_PYTHON_API_URL + `/clusters/confirm/${projectId}`,
                                            'POST',
                                            JSON.stringify(nonCanonRecords)
                                        ).then(async (_result: Response) => {
                                            setValue(ClusterStatus.APPROVED);

                                            if (table.options.meta?.updateClusterStatus) {
                                                table.options.meta.updateClusterStatus(
                                                    parseInt(row.id),
                                                    ClusterStatus.APPROVED
                                                );
                                            }
                                        });
                                    }}
                                />
                            )}
                            {value === ClusterStatus.REJECTED ? (
                                <ErrorFilled size={24} className="mx-2 clickable reject-action-active" />
                            ) : (
                                <ErrorOutline
                                    size={24}
                                    className="mx-2 clickable cluster-action-inactive"
                                    onClick={() => {
                                        api.callApi(
                                            instance,
                                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                            process.env.REACT_APP_PYTHON_API_URL + `/clusters/remove/${projectId}`,
                                            'POST',
                                            JSON.stringify(nonCanonRecords)
                                        ).then(async (_result: Response) => {
                                            setValue(ClusterStatus.REJECTED);
                                            updateClusterStatus(parseInt(row.id), ClusterStatus.REJECTED);
                                        });
                                    }}
                                />
                            )}
                        </>
                    );
                },
            },
        ],
        [api, instance, projectId, updateClusterStatus]
    );

    const table = useReactTable({
        columns: columns,
        data: data,
        pageCount: 1,
        autoResetExpanded: false,
        autoResetPageIndex: false,
        enableMultiSort: false,
        autoResetAll: false,
        enableExpanding: true,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        meta: {
            updateClusterStatus,
        },
    });

    const getTableCellClass = (columnName: string): string =>
        columnBreakpoints?.[columnName] ? `hidden ${columnBreakpoints[columnName]}:table-cell` : '';

    return (
        <div className="table-container">
            <Table>
                <ClusterReviewTableHeader
                    data={data}
                    updateClusterStatus={updateClusterStatus}
                    projectId={projectId}
                    isAllRowsExpanded={table.getIsAllRowsExpanded()}
                    toggleAllRowsExpanded={table.toggleAllRowsExpanded}
                    headers={table.getFlatHeaders()}
                    setShowMassApprovalModal={setShowMassApprovalModal}
                    getTableCellClass={getTableCellClass}
                />
                <ClusterReviewTableBody
                    loading={loading}
                    projectId={projectId}
                    sourceSystemKey={sourceSystemKey}
                    headers={table.getFlatHeaders()}
                    getTableCellClass={getTableCellClass}
                    updateClusterStatus={updateClusterStatus}
                    page={table.getRowModel().rows}
                    visibleColumns={columns}
                />
            </Table>
            <ClusterReviewTableFooter
                setPageSize={table.setPageSize}
                pageSize={table.getState().pagination.pageSize}
                fetchData={fetchData}
                clusterCounts={clusterCounts}
            />
            <MassApprovalModal
                projectKey={projectId}
                showModal={showMassApprovalModal}
                setShowModal={setShowMassApprovalModal}
                onSuccess={() => {
                    fetchData(table.getState().pagination.pageSize);
                }}
            />
        </div>
    );
}

export default ClusterReviewTable;

const columnBreakpoints: ColumnBreakpoints = {
    confidence: 'md',
    gender: 'lg',
    ssn: 'xl',
    dob: 'xl',
    reviewedBy: 'xl',
};
