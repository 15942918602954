import './DashboardStyles.css';

import {
    COLUMNS,
    DashboardFilterPreferences,
    DashboardLayout,
    DashboardPreferences,
    DashboardVisual,
    SPAN_TYPE,
} from './DashboardModels';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';

import ApiHelper from '../Shared/ApiHelper';
import CustomEmbededDashboard from './CustomEmbededDashboard';
import DashboardFilters from './DashboardFilters';
import { EmbededReportResponse } from '../Reports/ReportModels';
import FilterResetButton from '../Shared/components/FilterResetButton';
import LayoutDropdown from './LayoutDropdown';
import { Spinner } from 'react-bootstrap';
import { UserProfileContext } from '../Shared/contexts/UserProfile';
import VisualsDropdown from './VisualsDropdown';
import { useMsal } from '@azure/msal-react';

const Dashboard: FunctionComponent = () => {
    const userProfile = useContext(UserProfileContext);
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [embedUrl, setEmbedUrl] = useState<string | undefined>(undefined);
    const [filterQuery, setFilterQuery] = useState<string>('');

    const [layout, setLayout] = useState<DashboardLayout>({
        columns: COLUMNS.TWO,
        span: SPAN_TYPE.NONE,
        icon: 'DashboardLayouts/column2.svg',
    });
    const [layoutVisuals, setLayoutVisuals] = useState<DashboardVisual[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<DashboardFilterPreferences>();
    const [arePreferencesLoading, setArePreferencesLoading] = useState(true);
    const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

    const datasetId = userProfile.environmentConfig.pbiConfig?.datasets?.find(
        (x) => x.typeName == 'HMIS'
    )?.datasetGuid;

    useEffect(() => {
        if (selectedFilters === undefined)
            return;
        const hasFilters = Object.values(selectedFilters as object).some(x => x !== null);
        setFiltersApplied(hasFilters);
    }, [selectedFilters]);

    useEffect(() => {
        if (
            userProfile.environmentConfig.pbiConfig?.standardWorkspaceId &&
            userProfile.environmentConfig.pbiConfig.dashboardReportId &&
            userProfile.environmentConfig.pbiConfig?.datasets.length > 0
        ) {
            // const datasetId = userProfile.environmentConfig.pbiConfig?.datasets?.find(
            //     (x) => x.typeName == 'HMIS'
            // )?.datasetGuid;

            if (datasetId) {
                api.callApi(
                    instance,
                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                    process.env.REACT_APP_NET_API_URL +
                        `/PbiEmbed/view/${userProfile.environmentConfig.pbiConfig?.standardWorkspaceId}/${userProfile.environmentConfig.pbiConfig?.dashboardReportId}/${datasetId}`,
                    'GET'
                ).then(async (result: Response) => {
                    const embedDashboardResponse: EmbededReportResponse = await result.json();

                    setAccessToken(embedDashboardResponse.embedToken.token);
                    setEmbedUrl(embedDashboardResponse.embedReport[0].embedUrl);
                });
            }

            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + `/UserProfile/dashboard`,
                'GET'
            ).then(async (result: Response) => {
                const userPreferences: DashboardPreferences = await result.json();

                if (userPreferences.selectedVisuals) {
                    setLayoutVisuals((prevVisuals) => {
                        const mergedVisuals = prevVisuals.map((prevVisual) => {
                            // Check if the user has a preference for displaying the visual
                            const preferredVisual = userPreferences.selectedVisuals?.find((selectedVisual) => {
                                selectedVisual.name === prevVisual.name;
                            });

                            // Return user's preference for the visual if found
                            if (preferredVisual) {
                                return preferredVisual;
                            }

                            // Return the existing default for the visual otherwise
                            return prevVisual;
                        });

                        // If no visuals list exists to be merged with, load only the user's preferences
                        return mergedVisuals.length > 0 ? mergedVisuals : userPreferences.selectedVisuals ?? [];
                    });
                }
                setLayout((prevLayout) => (userPreferences.layout ? userPreferences.layout : prevLayout));
                setSelectedFilters(userPreferences?.filters);
                setArePreferencesLoading(false);
            });
        }
    }, [api, instance, userProfile.environmentConfig.pbiConfig?.dashboardReportId, userProfile.environmentConfig.pbiConfig?.datasets, userProfile.environmentConfig.pbiConfig?.standardWorkspaceId, datasetId]);

    const savePreferences = useCallback(
        (
            preferredLayout?: DashboardLayout,
            selectedVisuals?: DashboardVisual[],
            filters?: DashboardFilterPreferences
        ) => {
            const preferencesPayload: DashboardPreferences = {
                layout: preferredLayout ?? layout,
                selectedVisuals: selectedVisuals ?? layoutVisuals,
                filters: filters ?? selectedFilters,
            };
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + `/UserProfile/dashboard`,
                'PUT',
                JSON.stringify(preferencesPayload)
            );
        },
        [api, instance, layout, layoutVisuals, selectedFilters]
    );

    return (
        <div className="content-column-container">
            <div className="content-header">
                <h1>Dashboard</h1>
                <div className="flex-row ml-auto">
                    <LayoutDropdown layout={layout} setLayout={setLayout} savePreferences={savePreferences} />
                    <VisualsDropdown
                        visuals={layoutVisuals}
                        setVisuals={setLayoutVisuals}
                        savePreferences={savePreferences}
                    />
                </div>
            </div>
            {arePreferencesLoading ? (
                <div className="spinner-container">
                    <Spinner className="centered-spinner" variant="primary" size="sm" animation="border" />
                </div>
            ) : (
                <>
                    <FilterResetButton 
                        filtersApplied={filtersApplied} 
                        clickCallback={() => {
                            setSelectedFilters({});
                            savePreferences(layout, layoutVisuals, {}) 
                        }} />
                    <DashboardFilters
                        setFilterQuery={setFilterQuery}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        saveDashboardPreferences={savePreferences}
                    />
                    <CustomEmbededDashboard
                        accessToken={accessToken}
                        embedUrl={embedUrl ? embedUrl + filterQuery : undefined}
                        columns={layout.columns}
                        span={layout.span}
                        layoutVisuals={layoutVisuals}
                        setLayoutVisuals={setLayoutVisuals}
                        datasetId={datasetId}
                    />
                </>
            )}
        </div>
    );
};

export default Dashboard;
