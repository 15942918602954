import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import React, { FunctionComponent, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import { Category } from '../../Reports/ReportModels';
import Select from 'react-select';
import { colorOptions } from './utils';
import styles from './SaveCategoryModal.module.css';
import { useMsal } from '@azure/msal-react';

interface SaveCategoryModalProps {
    isEditingCategory: boolean;
    setIsEditingCategory: React.Dispatch<React.SetStateAction<boolean>>;
    showAddCategoryModal: boolean;
    setShowAddCategoryModal: React.Dispatch<React.SetStateAction<boolean>>;
    category: Category;
    setCategory: React.Dispatch<React.SetStateAction<Category>>;
    setCategoryWasChangedOrAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaveCategoryModal: FunctionComponent<SaveCategoryModalProps> = (props: SaveCategoryModalProps) => {
    const {
        isEditingCategory,
        setIsEditingCategory,
        showAddCategoryModal,
        setShowAddCategoryModal,
        category,
        setCategory,
        setCategoryWasChangedOrAdded,
    } = props;

    const [addingOrEditingCategoryInProgress, setAddingOrEditingCategoryInProgress] = useState<boolean>(false);

    const [api] = useState(() => new ApiHelper());
    const { instance } = useMsal();

    return (
        <Modal
            show={showAddCategoryModal}
            onHide={() => setShowAddCategoryModal(false)}
            onExited={() => {
                setIsEditingCategory(false);
                setAddingOrEditingCategoryInProgress(false);
            }}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{isEditingCategory ? 'Editing' : 'Adding'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="p-3">
                    <Form.Group controlId="CategoryName" className="mb-4">
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Add Name..."
                            onChange={(event) => {
                                setCategory({ ...category, categoryName: event.target.value });
                            }}
                            defaultValue={isEditingCategory ? category.categoryName : ''}
                        />
                    </Form.Group>
                    <Form.Group controlId="ColorHex" className="mb-4">
                        <Form.Label>Color</Form.Label>
                        <Select
                            isClearable
                            value={colorOptions.find((option) => option.value === category.colorHex)}
                            options={colorOptions}
                            onChange={(option) => {
                                setCategory({ ...category, colorHex: option?.value || '' });
                            }}
                            formatOptionLabel={({ label, value }) => (
                                <div className={styles.option}>
                                    <div className={styles.optionColor} style={{ background: value || undefined }} />
                                    <div>{label}</div>
                                </div>
                            )}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="link"
                    onClick={() => {
                        setShowAddCategoryModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    disabled={addingOrEditingCategoryInProgress}
                    onClick={() => {
                        setAddingOrEditingCategoryInProgress(true);
                        if (isEditingCategory) {
                            api.callApi(
                                instance,
                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                process.env.REACT_APP_NET_API_URL + '/PbiReport/categories',
                                'PUT',
                                JSON.stringify([category])
                            ).then(() => {
                                setShowAddCategoryModal(false);
                                setCategoryWasChangedOrAdded(true);
                                // Instead of putting setAddingOrEditingCategoryInProgress(false) here, it is put in the modal's onExited function
                                // Because there is a brief moment after the api call completes and the modal closes where the user is able to
                                // click the button again.
                            });
                        } else {
                            api.callApi(
                                instance,
                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                process.env.REACT_APP_NET_API_URL + '/PbiReport/categories',
                                'POST',
                                JSON.stringify([category])
                            ).then(() => {
                                setShowAddCategoryModal(false);
                                setCategoryWasChangedOrAdded(true);
                            });
                        }
                    }}
                >
                    {addingOrEditingCategoryInProgress ? (
                        <>
                            <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
                            {isEditingCategory ? 'Saving' : 'Adding'}
                        </>
                    ) : isEditingCategory ? (
                        'Save Category'
                    ) : (
                        'Add Category'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SaveCategoryModal;
