import { Button, Modal } from 'react-bootstrap'

import React from 'react';

interface NavigationWarningProps {
  showDialog: boolean
  cancelNavigation: boolean | (() => void)
  confirmNavigation: boolean | (() => void)
}

const NavigationWarningModal: React.FC<NavigationWarningProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  return (
    <Modal show={showDialog}>
      <Modal.Header>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>Navigating away may cause you to lose any unsaved changes.</b>
        <br /> Are you sure you want to leave without saving?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={cancelNavigation as (() => void)}>
          No
        </Button>
        <Button variant="primary" onClick={confirmNavigation as (() => void)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default NavigationWarningModal;