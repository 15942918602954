import { Button, Modal, Spinner } from 'react-bootstrap';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../Shared/ApiHelper';
import { ReportMetadata } from './ReportModels';
import { useMsal } from '@azure/msal-react';

interface BulkDeleteModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    selectedReports: ReportMetadata[];
    refreshData: () => void;
}

const BulkDeleteModal: FunctionComponent<BulkDeleteModalProps> = (props: BulkDeleteModalProps) => {
    const { showModal, setShowModal, selectedReports, refreshData } = props;

    const [isDeleting, setIsDeleting] = useState(false);

    const [deletableReports, setDeletableReports] = useState<ReportMetadata[]>([]);
    const [undeletableReports, setUndeletableReports] = useState<ReportMetadata[]>([]);

    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    useEffect(() => {
        setDeletableReports(selectedReports.filter((report) => !report.isSelectedByUser && report.type === 'Custom'));
        setUndeletableReports(selectedReports.filter((report) => report.isSelectedByUser || report.type !== 'Custom'));
    }, [selectedReports]);

    return (
        <Modal scrollable show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Delete Report(s)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Deleted reports are no longer accessible to any users and cannot be restored.</p>
                    <p>Are you sure you want to delete the selected report(s)?</p>
                    <ul>
                        {deletableReports.map((report) => {
                            return <li key={'report-delete-label-' + report.reportKey}>{report.name}</li>;
                        })}
                        {undeletableReports.map((report) => {
                            return <li key={'report-delete-label-' + report.reportKey}>{report.name + '*'}</li>;
                        })}
                    </ul>
                    <p>*Report will not be deleted because it is marked as a favorite and/or pinned.</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="link"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="outline-danger"
                    disabled={isDeleting || !deletableReports.length}
                    onClick={() => {
                        setIsDeleting(true);
                        api.callApi(
                            instance,
                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                            process.env.REACT_APP_NET_API_URL + '/PbiReport/bulkdelete',
                            'DELETE',
                            JSON.stringify(deletableReports.map((report) => report.reportId))
                        )
                            .then(() => {
                                setShowModal(false);
                                refreshData();

                                toast.success('Reports Deleted Successfully', {
                                    position: 'top-right',
                                    autoClose: 5000,
                                    transition: Slide,
                                    draggable: false,
                                    closeOnClick: false,
                                    theme: 'colored',
                                });
                            })
                            .finally(() => {
                                setIsDeleting(false);
                            });
                    }}
                >
                    {isDeleting ? (
                        <>
                            <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
                            Deleting
                        </>
                    ) : (
                        <>Delete</>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BulkDeleteModal;
