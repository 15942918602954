import { Cluster, ClusterRecord, ClusterStatus } from '../../../DataToolsModels';
import { GetDisplayDate, GetDisplaySsn } from '../../../../Shared/SharedHelpers';
import React, { FunctionComponent, useState } from 'react';

import ApiHelper from '../../../../Shared/ApiHelper';
import { Button } from 'react-bootstrap';
import { Gender } from '../../../../Shared/SharedModels';
import { Row } from '@tanstack/react-table';
import { useMsal } from '@azure/msal-react';

interface ExpandedClusterProps {
    row: Row<Cluster>;
    projectId: number;
    clusterStatus?: ClusterStatus;
    updateClusterStatus: (row: number, status: ClusterStatus) => void;
}

const ExpandedCluster: FunctionComponent<ExpandedClusterProps> = (props: ExpandedClusterProps) => {
    const { row, projectId, updateClusterStatus, clusterStatus } = props;

    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    return (
        <div className="expanded-clusters">
            <h5 className="cluster-confidenc-header">
                We are
                {' ' +
                    row.original.confidence?.toLocaleString(undefined, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                    }) +
                    ' '}
                sure that these records are a match.
            </h5>
            <div className="flex-row">
                <Button
                    variant="outline-secondary"
                    className={`cluster-action-buttons ${clusterStatus === ClusterStatus.APPROVED ? 'active' : ''}`}
                    onClick={() => {
                        if (clusterStatus !== ClusterStatus.APPROVED) {
                            const nonCanonRecords = row.original.clusterRecords.filter((r) => !r.canon);
                            api.callApi(
                                instance,
                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                process.env.REACT_APP_PYTHON_API_URL + `/clusters/confirm/${projectId}`,
                                'POST',
                                JSON.stringify(nonCanonRecords)
                            ).then(async (_result: Response) => {
                                updateClusterStatus(parseInt(row.id), ClusterStatus.APPROVED);
                            });
                        }
                    }}
                >
                    Approve Match
                </Button>
                <Button
                    variant="outline-danger"
                    className={`cluster-action-buttons ${clusterStatus === ClusterStatus.REJECTED ? 'active' : ''}`}
                    onClick={() => {
                        if (clusterStatus !== ClusterStatus.REJECTED) {
                            const nonCanonRecords = row.original.clusterRecords.filter((r) => !r.canon);
                            api.callApi(
                                instance,
                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                process.env.REACT_APP_PYTHON_API_URL + `/clusters/remove/${projectId}`,
                                'POST',
                                JSON.stringify(nonCanonRecords)
                            ).then(async (_result: Response) => {
                                updateClusterStatus(parseInt(row.id), ClusterStatus.REJECTED);
                            });
                        }
                    }}
                >
                    Reject Match
                </Button>
            </div>
            <div className="cluster-card-container">
                {row.original.clusterRecords.map((record: ClusterRecord) => {
                    return (
                        <div className={`cluster-record`} key={`cluster-record-${record.client_key}`}>
                            <h5>{record.canon ? 'Unique Client Index Record' : 'Source System Record'}</h5>
                            <div>
                                <b>Full Name: </b>
                                {`${record.firstname ?? ''} ${record.middlename ?? ''}  ${record.lastname ?? ''}  ${
                                    record.namesuffix ?? ''
                                }`}
                            </div>
                            <div>
                                <b>SSN: </b>
                                {GetDisplaySsn(record.ssn as string)}
                            </div>
                            <div>
                                <b>DOB: </b>
                                {GetDisplayDate(record.dob as string)}
                            </div>
                            <div>
                                <b>Gender: </b>
                                {record.gender !== undefined ? Gender[record.gender as number] : '-'}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ExpandedCluster;
