import React, { useRef } from 'react';

interface CollapseProps {
    isOpen?: boolean;
    children?: React.ReactNode;
}

const Collapse: React.FC<CollapseProps> = ({ children, isOpen = false }) => {
    const elementRef = useRef<HTMLDivElement | null>(null);

    return (
        <div
            style={{
                overflow: 'hidden',
                transition: 'max-height .3s ease-out',
                maxHeight: isOpen ? '1500px' : '0',
                height: 'auto',
            }}
        >
            <div ref={elementRef}>{children}</div>
        </div>
    );
};

export default Collapse;
