/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { ObjectSchema } from 'yup';

type Yup = typeof yup;

type Validate = Yup;

type GetSchema = (validate: Validate) => any;

yup.setLocale({
    mixed: {
        default: 'Invalid',
        required: 'Required',
    },
    array: {
        min: 'Must have at least ${min} items',
    },
    date: {
        max: 'Max ${max}',
        min: 'Min ${min}',
    },
    string: {
        email: 'Please enter a valid email address',
        max: 'Max ${max} characters',
        min: 'Min ${min} characters',
    },
    number: {
        max: 'Max ${max} value',
        min: 'Min ${min} value',
    },
});

export function createSchema(getSchema: GetSchema): ObjectSchema<any> {
    const schema = getSchema({ ...yup });

    return yup.object().shape(schema);
}
