import { UserProfile } from './SharedModels';
import { formatDateString } from './SharedHelpers';
import moment from 'moment';

export interface PipelineTriggerDetails {
    additionalProperties: object;
    frequency: string;
    interval: number;
    startTime: string;
    endTime: string;
    timeZone: string;
    schedule: Schedule;
    runtimeState: string;
}

export interface Schedule {
    additionalProperties: object;
    minutes: number[];
    hours: number[];
    weekDays: string[];
    monthDays: number[];
    monthlyOccurrences: MonthlyOccurrence;
}

export interface MonthlyOccurrence {
    additionalProperties: object;
    day: number;
    occurrence: number;
}

export const generateScheduleText = (
    triggerDetails: PipelineTriggerDetails | undefined,
    userProfile: UserProfile
): string => {
    if (!triggerDetails) {
        return '';
    }

    let text = '';
    switch (triggerDetails.frequency) {
        case 'Month':
            text = `Runs are scheduled for the ${triggerDetails.schedule.monthDays
                .map((md) => `${md}${monthDaySuffixAppender(md)}`)
                .join(' ')} at  ${scheduleHoursMinutesToTime(
                triggerDetails,
                userProfile.environmentConfig.timeZone
            )} every ${triggerDetails.interval} month(s)`;
            break;
        case 'Week':
            text = `Runs are scheduled for ${triggerDetails.schedule.weekDays.join(
                ' '
            )} at ${scheduleHoursMinutesToTime(triggerDetails, userProfile.environmentConfig.timeZone)} every ${
                triggerDetails.interval
            }} week(s)`;
            break;
        case 'Day':
            text = `Runs are scheduled at ${scheduleHoursMinutesToTime(
                triggerDetails,
                userProfile.environmentConfig.timeZone
            )} every ${triggerDetails.interval} day(s)`;
            break;
        case 'Hour':
            text = `Runs are scheduled ${triggerDetails.schedule.minutes} minutes after the hour every ${triggerDetails.interval} hour(s)`;
            break;
        case 'Minute':
            text = `Runs are scheduled every ${triggerDetails.interval} minutes`;
            break;
        default:
            break;
    }

    if (triggerDetails.runtimeState !== 'Started') {
        text += ' but the automated schedule is currently paused.';
    }

    return text;
};

const scheduleHoursMinutesToTime = (triggerDetails: PipelineTriggerDetails, targetTimeZone?: string): string => {
    const scheduleTimeZone = TimeZones[triggerDetails.timeZone as keyof typeof TimeZones];

    return triggerDetails.schedule.hours
        .map((hour) => {
            return triggerDetails.schedule.minutes.map((minute) => {
                const sourceTime = moment().tz(scheduleTimeZone);
                sourceTime.set({ hour, minute });

                if (targetTimeZone) {
                    const targetTime = sourceTime.clone().tz(targetTimeZone);
                    return targetTime.format('h:mm A z');
                }

                return `${toTimeString(hour)}:${toTimeString(minute)}`;
            });
        })
        .join(', ');
};

const toTimeString = (time: number): string => {
    if (time < 10) {
        return `0${time}`;
    }
    return time.toString();
};

const monthDaySuffixAppender = (monthDay: number): string => {
    if (monthDay === 11 || monthDay === 12 || monthDay === 13) {
        return 'th';
    }

    while (monthDay > 10) {
        monthDay /= 10;
    }
    switch (monthDay) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

enum TimeZones {
    'Dateline Standard Time' = 'Etc/GMT+12',
    'UTC-11' = 'Etc/GMT+11',
    'Hawaiian Standard Time' = 'Pacific/Honolulu',
    'Alaskan Standard Time' = 'America/Anchorage',
    'Pacific Standard Time' = 'America/Los_Angeles',
    'Mountain Standard Time' = 'America/Denver',
    'Central Standard Time' = 'America/Chicago',
    'Eastern Standard Time' = 'America/New_York',
    'Atlantic Standard Time' = 'America/Halifax',
    'Eastern Daylight Time' = 'America/New_York',
    'Central Daylight Time' = 'America/Chicago',
    'Mountain Daylight Time' = 'America/Denver',
    'Pacific Daylight Time' = 'America/Los_Angeles',
    'Yukon Standard Time' = 'America/Whitehorse',
    'Arizona Standard Time' = 'America/Phoenix',
    'Newfoundland Standard Time' = 'America/St_Johns',
    UTC = 'Etc/UTC',
}
