import {
    ChartRelationship,
    Currency,
    Document,
    DocumentExport,
    DocumentImport,
    Home,
    Password,
    Stethoscope,
    TemperatureFeelsLike,
    User,
} from '@carbon/icons-react';

import { CarbonIconType } from '@carbon/icons-react/lib/CarbonIcon';

export const ICONS: { [key: string]: CarbonIconType } = {
    EnrollmentKey: Password,
    ProjectName: User,
    ProjectType: ChartRelationship,
    OrganizationName: ChartRelationship,
    EntryDate: DocumentExport,
    ExitDate: DocumentImport,
    AgeAtEnrollment: User,
    LivingSituation: Home,
    Destination: Home,
    IncomeAndSources: Currency,
    BedNightDate: Currency,
    CoordinatedEntryEvent: Currency,
    EncounterStart: DocumentExport,
    EncounterEnd: DocumentImport,
    ProviderName: User,
    Payor: Currency,
    Procedure: Stethoscope,
    EncounterClass: Stethoscope,
    Description: Document,
    Reason: TemperatureFeelsLike,
    BaseEncounterCost: Currency,
    TotalClaimCost: Currency,
    PayorCoverage: Currency,
};

export const matchIconToColumn = (columnName: string): CarbonIconType => {
    return ICONS[columnName] || ICONS['Destination'];
};
