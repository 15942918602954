import { Button, Modal, Spinner } from 'react-bootstrap';
import React, { FunctionComponent, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import { Category } from '../../Reports/ReportModels';
import { useMsal } from '@azure/msal-react';

interface DeleteCategoryModalProps {
    showDeleteCategoryModal: boolean;
    setShowDeleteCategoryModal: React.Dispatch<React.SetStateAction<boolean>>;
    category: Category;
    setCategory: React.Dispatch<React.SetStateAction<Category>>;
    setCategoryWasChangedOrAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteCategoryModal: FunctionComponent<DeleteCategoryModalProps> = (props: DeleteCategoryModalProps) => {
    const { showDeleteCategoryModal, setShowDeleteCategoryModal, category, setCategory, setCategoryWasChangedOrAdded } =
        props;

    const [deletingCategoryInProgress, setDeletingCategoryInProgress] = useState<boolean>(false);

    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();

    return (
        <Modal
            scrollable
            show={showDeleteCategoryModal}
            onHide={() => setShowDeleteCategoryModal(false)}
            onShow={() => setCategory({ ...category, isDeleted: true })}
            onExited={() => setDeletingCategoryInProgress(false)}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to delete this Category?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button
                    variant="link"
                    onClick={() => {
                        setShowDeleteCategoryModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="outline-danger"
                    disabled={deletingCategoryInProgress}
                    onClick={() => {
                        setDeletingCategoryInProgress(true);
                        api.callApi(
                            instance,
                            [process.env.REACT_APP_B2C_SCOPE ?? ''],
                            process.env.REACT_APP_NET_API_URL + '/PbiReport/categories',
                            'PUT',
                            JSON.stringify([category])
                        ).then(() => {
                            setShowDeleteCategoryModal(false);
                            setCategoryWasChangedOrAdded(true);
                        });
                    }}
                >
                    {deletingCategoryInProgress ? (
                        <>
                            <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
                            Deleting
                        </>
                    ) : (
                        <>Delete</>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteCategoryModal;
