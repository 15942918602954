import { Button, Form } from 'react-bootstrap';
import { Filter, FilterRemove } from '@carbon/icons-react';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { SourceSystem } from '../../Shared/SharedModels';

interface SourceSystemFilterProps {
    masterSourceSystemList: SourceSystem[];
    setFilteredSourceSystems: (sourceSystems: SourceSystem[]) => void;
}

const SourceSystemFilters: FunctionComponent<SourceSystemFilterProps> = (props: SourceSystemFilterProps) => {
    const { masterSourceSystemList, setFilteredSourceSystems } = props;
    const [sourceSystemName, setSourceSystemName] = useState('');
    const [sourceSystemShortName, setSourceSystemShortName] = useState('');
    const [admins, setAdmins] = useState('');
    const [areFiltersApplied, setAreFiltersApplied] = useState(false);

    const clearFilters = useCallback(() => {
        setSourceSystemName('');
        setSourceSystemShortName('');
        setAdmins('');
    }, []);

    useEffect(() => {
        setAreFiltersApplied(!!sourceSystemName || !!sourceSystemShortName || !!admins);
    }, [sourceSystemName, sourceSystemShortName, admins]);

    useEffect(() => {
        let filteredSourceSystems = masterSourceSystemList;

        if (sourceSystemName) {
            filteredSourceSystems = filteredSourceSystems.filter((system) => {
                return system.sourceSystemName?.toLowerCase().includes(sourceSystemName.toLowerCase());
            });
        }

        if (sourceSystemShortName) {
            filteredSourceSystems = filteredSourceSystems.filter((system) => {
                return system.sourceSystemShortName?.toLowerCase().includes(sourceSystemShortName.toLowerCase());
            });
        }

        if (admins) {
            filteredSourceSystems = filteredSourceSystems.filter((system) => {
                return system.sourceSystemAdmins
                    .map((admin) => admin.upn)
                    .join(', ')
                    ?.toLowerCase()
                    .includes(admins.toLowerCase());
            });
        }

        setFilteredSourceSystems(filteredSourceSystems);
    }, [masterSourceSystemList, setFilteredSourceSystems, sourceSystemName, sourceSystemShortName, admins]);

    return (
        <>
            <div className="filtersAppliedContainer">
                <Button
                    data-testid="filterResetButton"
                    variant="link"
                    disabled={!areFiltersApplied}
                    onClick={clearFilters}
                >
                    {areFiltersApplied ? (
                        <>
                            <FilterRemove size={24} className="button-icon-padding" />
                            Reset All Filters
                        </>
                    ) : (
                        <>
                            <Filter size={24} className="button-icon-padding" />
                            No Filters Applied
                        </>
                    )}
                </Button>
            </div>
            <Form className="filterFormContainer">
                <Form.Group className="data-table-filter">
                    <Form.Label>Source System Name</Form.Label>
                    <Form.Control
                        data-testid="sourceSystemNameFilterInput"
                        type="text"
                        placeholder="Source System Name..."
                        value={sourceSystemName}
                        onChange={(event) => {
                            setSourceSystemName(event.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Group className="data-table-filter">
                    <Form.Label>Source System Short Name</Form.Label>
                    <Form.Control
                        data-testid="sourceSystemShortNameFilterInput"
                        type="text"
                        placeholder="Short Name..."
                        value={sourceSystemShortName}
                        onChange={(event) => {
                            setSourceSystemShortName(event.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Group className="data-table-filter">
                    <Form.Label>Admin(s)</Form.Label>
                    <Form.Control
                        data-testid="adminsFilterInput"
                        type="text"
                        placeholder="Admin(s)..."
                        value={admins}
                        onChange={(event) => {
                            setAdmins(event.target.value);
                        }}
                    />
                </Form.Group>
            </Form>
        </>
    );
};

export default SourceSystemFilters;
